import { GetOneParams, GetOneResult } from "react-admin";
import gql from "graphql-tag";
import mbaas from "../../provider/mbaas";
import jwt from "jsonwebtoken";

const GetOneFunction = async (resource: string, params: GetOneParams) => {
  console.log("getOne", { resource, params });
  let initTable = mbaas.client.getTable(resource);
  // dashboard
  const METABASE_SITE_URL = "https://metabase.master.telinhk.xyz";
  const METABASE_SECRET_KEY =
    "4e49e66927902752af635ff7464bf27034fe8f8ce81a4b0d76b7fb65c544be08";
  if (["overall-dashboard", "account-dashboard"].includes(resource)) {
    const payload = {
      resource: { dashboard: params.id },
      params: params.meta?.account ? { account: [params.meta.account] } : {},
      exp: Math.round(Date.now() / 1000) + 10 * 60, // 10 minute expiration
    };
    const token = jwt.sign(payload, METABASE_SECRET_KEY);

    const iframeUrl =
      METABASE_SITE_URL +
      "/embed/dashboard/" +
      token +
      "#bordered=false&titled=false";

    return { data: { id: params.id, metabaseUrl: iframeUrl } };
  }
  //
  if (resource === "configuration") {
    const { getConfigurationsById } = await mbaas.client.gql.query({
      query: gql`
        query getOneConfiguration($id: UUID) {
          getConfigurationsById(id: $id) {
            id
            created_at
            updated_at
            key
            description
            value
            author
            metadata
          }
        }
      `,
      variables: {
        id: params.id,
      },
    });
    return { data: getConfigurationsById } as GetOneResult;
  }
  //
  if (resource === "execution_logs") {
    const { getExecutionLogsById } = await mbaas.client.gql.query({
      query: gql`
        query getOneExecutionLog($id: UUID) {
          getExecutionLogsById(id: $id) {
            id
            created_at
            updated_at
            type
            reference_id
            stage
            status
            reference_detail
            normal_detail
            exception_detail
          }
        }
      `,
      variables: {
        id: params.id,
      },
    });

    return { data: getExecutionLogsById } as GetOneResult;
  }
  //
  if (resource === "accounts") {
    const { allAccountsList } = await mbaas.client.gql.query({
      query: gql`
        query Accounts($id: UUID!) {
          allAccountsList(where: { id: { _eq: $id } }) {
            data {
              id
              label
              contact
              public_key
              transaction_prefix
              private_key_signature
              users_account_id_list {
                data {
                  id
                  email
                  name
                }
              }
            }
          }
        }
      `,
      variables: {
        id: params.id,
      },
    });
    //
    return { data: allAccountsList.data[0] } as GetOneResult;
  }
  //
  if (resource === "suppliers") {
    const { allSuppliersList } = await mbaas.client.gql.query({
      query: gql`
        query Supplier($id: UUID!) {
          allSuppliersList(where: { id: { _eq: $id } }) {
            data {
              id
              created_at
              updated_at
              user_id
              name
              contact
              description
              is_available
            }
            count
          }
        }
      `,
      variables: {
        id: params.id,
      },
    });

    return {
      data: allSuppliersList.data[0],
    } as GetOneResult;
  }
  //
  if (resource === "product_items") {
    console.log("this");
    const {
      getProductItemsById,
      allSuppliersList,
      allSupplyItemsList,
      allAccountsList,
    } = await mbaas.client.gql.query({
      query: gql`
        query ProductItem($id: UUID!) {
          getProductItemsById(id: $id) {
            id
            item_key
            label
            description
            product_category_id
            type
            supply_mode
            inquiry_rules
            is_available
            execution_rules
            country
            product_pricings_product_item_id_list {
              data {
                id
                product_item_id
                account_id
                currency
                method
                value
                is_available
                account_id_data {
                  id
                  label
                }
              }
            }
            supply_routes_product_item_id_list {
              data {
                id
                supply_item_id_data {
                  id
                  label
                  origin_currency
                  origin_cost
                  hkd_cost
                  is_available
                  supplier_id_data {
                    id
                    name
                  }
                }
              }
            }
          }
          allAccountsList {
            data {
              id
              label
            }
          }
          allSuppliersList {
            data {
              id
              name
            }
          }
          allSupplyItemsList(limit: 99999999) {
            data {
              id
              supplier_id
              label
              description
              origin_currency
              origin_cost
              hkd_cost
              is_available
            }
          }
        }
      `,
      variables: {
        id: params?.id,
      },
    });

    console.log({ getProductItemsById });

    return {
      data: Object.assign({
        ...getProductItemsById,
        supplier_list: allSuppliersList.data || [],
        supplied_items: allSupplyItemsList.data || [],
        account_list: allAccountsList.data || [],
      }),
    } as GetOneResult;
  }

  if (resource === "transactions") {
    console.log({ method: "getOne", resource, params });
    const queryResult = await mbaas.client.gql.query({
      query: gql`
        query aTransactionEntry($id: UUID!) {
          getTransactionEntriesById(id: $id) {
            id
            created_at
            order_id
            reference_id
            account_id_data {
              label
            }
            selling_price
            selling_currency
            hkd_price
            target_detail
            status
            product_item_id
            product_snapshot
            pricing_snapshot
            supply_item_id
            supply_snapshot
            conversion_rate_snapshot
            extra_context
          }
        }
      `,
      variables: {
        id: params.id,
      },
    });

    return {
      data: queryResult?.getTransactionEntriesById,
    } as GetOneResult;
  }

  //
  const { data } = await initTable.lookup(params.id);
  console.log("getOne", { resource, params, data });
  return { data: data } as GetOneResult;
};

export default GetOneFunction;
