import React, { useState } from "react";
import {
  Toolbar,
  useNotify,
  useRedirect,
  useDataProvider,
  Button,
} from "react-admin";
import { Save as SaveIcon } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useFormState, useWatch } from "react-hook-form";

const SaveButton = ({ supplierId }: { supplierId: string }) => {
  const formState = useFormState();
  const formData = useWatch();
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const [isLoading, setIsLoading] = useState(false);

  let startIcon = <SaveIcon />;
  if (isLoading) {
    startIcon = <CircularProgress size={20} />;
  }

  const saveData = async () => {
    setIsLoading(true);
    await dataProvider
      .create("supply_items", {
        data: {
          ...formData,
          supplier_id: supplierId,
        },
      })
      .then(() => {
        redirect(`/suppliers/${supplierId}/supply_items_upserted`);
        notify("New Item Added");
      })
      .catch((error) => {
        notify(`supply_items:Add New Item error: ${error.message}`, {
          type: "warning",
        });
      });
    setIsLoading(false);
  };

  const { label, description, origin_currency, origin_cost, hkd_cost } =
    formState.dirtyFields;

  return (
    <Button
      startIcon={startIcon}
      label="Save"
      variant="contained"
      disabled={
        !label || !description || !origin_currency || !origin_cost || !hkd_cost
      }
      onClick={() => {
        saveData();
      }}
    />
  );
};

export const ToolbarCreate = ({ supplierId }: { supplierId: string }) => {
  return (
    <Toolbar>
      <SaveButton supplierId={supplierId} />
    </Toolbar>
  );
};
