import React, { useState } from "react";
import {
  Toolbar,
  useRefresh,
  Confirm,
  useNotify,
  useRedirect,
  useDataProvider,
  Button,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import { Save as SaveIcon } from "@mui/icons-material";
import { CircularProgress, Stack, TextField } from "@mui/material";
import { useWatch } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import mbaas from "../../provider/mbaas";
import Swal from "sweetalert2";

const SaveButton = ({ supplierId }: { supplierId: string }) => {
  const formData: any = useWatch();
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const [isLoading, setIsLoading] = useState(false);

  let startIcon = <SaveIcon />;
  if (isLoading) {
    startIcon = <CircularProgress size={20} />;
  }

  const saveData = async () => {
    setIsLoading(true);
    // const supplierId = formData.id;
    delete formData.id;
    delete formData.type;
    delete formData.created_at;
    delete formData.updated_at;
    await dataProvider
      .update("suppliers", {
        id: supplierId,
        data: {
          ...formData,
        },
        previousData: {},
      })
      .then(() => {
        redirect(`/suppliers/${supplierId}/supply_items_upserted`);
        notify("Item edited");
      })
      .catch((error) => {
        notify(`supply_items:Edit error: ${error.message}`, {
          type: "warning",
        });
      });
    setIsLoading(false);
  };

  const { user_id, name, is_available, description, contact } = formData;

  return (
    <Button
      startIcon={startIcon}
      label="Save"
      variant="contained"
      disabled={
        user_id === "" ||
        name === "" ||
        is_available === "" ||
        description === "" ||
        contact === ""
      }
      onClick={() => {
        saveData();
      }}
    />
  );
};

const DeleteButton = ({ supplierId }: { supplierId: string }) => {
  const formData: any = useWatch();
  const refresh = useRefresh();
  const navigate = useNavigate();
  const [otpCode, setOtpCode] = useState("");
  const [open, setOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  let startIcon = <DeleteIcon />;
  if (isLoading) {
    startIcon = <CircularProgress size={20} />;
  }

  const handleDialogClose = () => setOpen(false);

  const deleteData = async () => {
    setIsLoading(true);

    console.log(776312, "supplierId", supplierId);

    const { error, data } = await mbaas.client.invokeApi("delete-supplier", {
      supplierId: supplierId,
      token: otpCode,
    });

    if (error) {
      Swal.fire(`Request failed: ${error.error}`, "", "error");
    }
    if (data) {
      Swal.fire("Success!", "", "success");
      navigate("/suppliers");
      refresh();
    }
    setIsLoading(false);
    setOpen(false);
  };

  return (
    <>
      {/* Modal for OTP Code */}
      <Confirm
        isOpen={open}
        loading={otpCode.length < 6 ? true : isLoading}
        title="OTP code"
        content={
          <TextField
            type="number"
            fullWidth
            data-test-id="input-otp"
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
          />
        }
        onConfirm={deleteData}
        onClose={handleDialogClose}
      />
      <Button
        startIcon={startIcon}
        label="delete"
        variant="contained"
        style={{
          backgroundColor: "#d33730",
        }}
        onClick={() => setOpen(true)}
      />
    </>
  );
};

export const ToolbarEdit = ({ supplierId }: { supplierId: string }) => {
  return (
    <Toolbar>
      <Stack
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        spacing={2}
      >
        <SaveButton supplierId={supplierId} />
        {/* <DeleteButton supplierId={supplierId} /> */}
        {/* <DeleteWithConfirmButton
          redirect={`/suppliers`}
          sx={{ marginLeft: "400px" }}
          data-test-id="button-delete"
        /> */}
      </Stack>
    </Toolbar>
  );
};
