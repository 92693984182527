// @ts-nocheck
import * as React from "react";
import { Admin, CustomRoutes, Resource, defaultTheme } from "react-admin";
import accounts from "./component/Accounts";
import users from "./component/Users";
import productCategories from "./component/Products/Categories";
import productItems from "./component/Products/Items";
import ExecutionLogs from "./component/Internal/Execution_Logs";
import ConfigurationMenu from "./component/Internal/Configuration_Menu";
import ConvertionRates from "./component/Internal/Conversion_Rate";
import reports from "./component/Reports";
import batch from "./component/Batch";
import { Route } from "react-router";

import authProvider from "./provider/auth";
import { Layout, Login } from "./layout";
import Data from "./provider/dataProvider";
import ForgotPassword from "./component/forgot-password-page";
import ResetPassword from "./component/reset-password-page";
import AccountLink from "./component/AccountLink";
import Dashboard from "./component/Dashboard";
import EmailVerificationResultPage from "./component/email-verification-result";
import supplies from "./component/Supplies";
import { TransactionsList } from "./component/Transactions/List";
import { createTheme } from "@mui/material/styles";
import merge from "lodash/merge";

function validatePermission(permission: string, ref: string[]) {
  return ref.includes(permission);
}

const theme = createTheme(
  merge({}, defaultTheme, {
    palette: {
      secondary: {
        main: "#52796f",
      },
    },
  })
);

// "Administrator",
// "staff",
// "ext_admin",
// "ext_staff",
const App = () => {
  return (
    <Admin
      title=""
      dataProvider={Data}
      authProvider={authProvider}
      dashboard={Dashboard}
      loginPage={Login}
      theme={theme}
      layout={Layout}
    >
      <CustomRoutes noLayout>
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/email-verification-result"
          element={<EmailVerificationResultPage />}
        />
        <Route path="/account-link" element={<AccountLink />} noLayout />
      </CustomRoutes>
      {(permissions) => [
        validatePermission(permissions, [
          "Administrator",
          "ext_admin",
          "staff",
          "ext_staff",
        ]) ? (
          <Resource name="accounts" {...accounts} />
        ) : (
          <></>
        ),
        validatePermission(permissions, ["Administrator", "staff"]) ? (
          <Resource name="users" {...users} />
        ) : (
          <></>
        ),
        validatePermission(permissions, ["Administrator", "staff"]) ? (
          <Resource name="product_categories" {...productCategories} />
        ) : (
          <></>
        ),
        validatePermission(permissions, [
          "Administrator",
          "ext_admin",
          "staff",
          "ext_staff",
        ]) ? (
          <Resource name="product_items" {...productItems} />
        ) : (
          <></>
        ),
        // validatePermission(permissions, [
        //   "Administrator",
        //   "ext_admin",
        //   "staff",
        //   "ext_staff",
        // ]) ? (
        //   <Resource name="supplies" {...supplies} />
        // ) : (
        //   <></>
        // ),
        validatePermission(permissions, [
          "Administrator",
          "ext_admin",
          "staff",
          "ext_staff",
        ]) ? (
          <Resource name="suppliers" {...supplies} />
        ) : (
          <></>
        ),
        validatePermission(permissions, [
          "Administrator",
          "ext_admin",
          "staff",
          "ext_staff",
        ]) ? (
          <Resource name="reports" {...reports} />
        ) : (
          <></>
        ),
        validatePermission(permissions, [
          "Administrator",
          "ext_admin",
          "staff",
          "ext_staff",
        ]) ? (
          <Resource name="batch" {...batch} />
        ) : (
          <></>
        ),
        validatePermission(permissions, [
          "Administrator",
          "ext_admin",
          "staff",
          "ext_staff",
        ]) ? (
          <Resource name="execution_logs" {...ExecutionLogs} />
        ) : (
          <></>
        ),
        validatePermission(permissions, ["Administrator"]) ? (
          <Resource name="configuration" {...ConfigurationMenu} />
        ) : (
          <></>
        ),
        validatePermission(permissions, ["Administrator", "staff"]) ? (
          <Resource name="convertion_rates" {...ConvertionRates} />
        ) : (
          <></>
        ),
        validatePermission(permissions, [
          "Administrator",
          "ext_admin",
          "staff",
          "ext_staff",
        ]) ? (
          <CustomRoutes>
            <Route path="/transactions" element={<TransactionsList />} />
            <Route
              path="/transactions/:id/show/*"
              element={<TransactionsList />}
            />
          </CustomRoutes>
        ) : (
          <></>
        ),
      ]}
    </Admin>
  );
};

export default App;
