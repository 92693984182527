import * as React from "react";
import {
  Edit,
  TextInput,
  SimpleForm,
  EditProps,
  useShowController,
  ReferenceField,
  TextField,
  Toolbar,
  SaveButton,
  FunctionField,
  RaRecord,
  required,
} from "react-admin";
import { Grid, Box, Typography, Button, Stack } from "@mui/material";
import JSONInput from "../../JSONInput";
import {
  Edit as EditIcon2,
  ArrowBackIosNew as CancelIcon,
} from "@mui/icons-material";
import ReactJson from "react-json-view";

const EditPage = ({ ...props }: EditProps) => {
  const [isEdit, setIsEdit] = React.useState(false);

  console.log(99999, "props", props);

  const { record } = useShowController(props);

  const CustomToolbar = () => (
    <Toolbar>
      <Stack direction="row" justifyContent="space-between" width="100%">
        {isEdit && <SaveButton label="Save" />}
        {isEdit && (
          <Button
            onClick={() => setIsEdit(false)}
            variant="contained"
            color="error"
            startIcon={<CancelIcon />}
          >
            Cancel
          </Button>
        )}
        {!isEdit && (
          <Button
            onClick={() => setIsEdit(true)}
            variant="contained"
            startIcon={<EditIcon2 />}
          >
            Edit
          </Button>
        )}
      </Stack>
    </Toolbar>
  );

  if (!record) return null;

  return (
    <Edit title={<></>} {...props} mutationMode="pessimistic">
      <Box width="50vW" ml={2} mr={2}>
        <Stack direction="row" pr={1}>
          <Typography variant="h6" flex="1">
            Edit Configuration
          </Typography>
        </Stack>
        <SimpleForm toolbar={<CustomToolbar />}>
          <Grid container spacing={2}>
            <Box flex={2}>
              <div style={{ display: "none" }}>
                <TextInput
                  source="author"
                  // @ts-ignore
                  value={props.userData?.id}
                  // @ts-ignore
                  defaultValue={props.userData?.id}
                  validate={[required()]}
                  data-test-id="author"
                  fullWidth
                  disabled={true}
                />
              </div>
              <TextInput
                source="key"
                validate={[required()]}
                data-test-id="key"
                fullWidth
                disabled={!isEdit}
              />
              <TextInput
                source="description"
                validate={[required()]}
                data-test-id="description"
                disabled={!isEdit}
                fullWidth
              />
              {isEdit && <JSONInput source="value" />}
              {!isEdit && (
                <>
                  <Typography mb={1}>Value</Typography>
                  <FunctionField
                    label="Value"
                    render={(record: RaRecord) => (
                      <div>
                        <label>
                          <ReactJson
                            src={record.value || {}}
                            name={false}
                            enableClipboard={false}
                            displayDataTypes={false}
                            displayObjectSize={false}
                          />
                        </label>
                      </div>
                    )}
                  />
                </>
              )}
              {!isEdit && (
                <>
                  <Typography mb={1}>Metadata</Typography>
                  <FunctionField
                    label="Metadata"
                    render={(record: RaRecord) => (
                      <div>
                        <label>
                          <ReactJson
                            src={record.metadata || {}}
                            name={false}
                            enableClipboard={false}
                            displayDataTypes={false}
                            displayObjectSize={false}
                          />
                        </label>
                      </div>
                    )}
                  />
                </>
              )}
              {isEdit && (
                <TextInput
                  source="totp"
                  validate={[required()]}
                  data-test-id="totp"
                  fullWidth
                />
              )}
            </Box>
          </Grid>
        </SimpleForm>
      </Box>
    </Edit>
  );
};

export default EditPage;
