// @ts-nocheck
import React from "react";
import { useGetIdentity, Loading, Authenticated } from "react-admin";
import { Box } from "@mui/material";
import InternalDashboard from "../component/Dashboard/Internal";
import CommonDashboard from "../component/Dashboard/Common";

export default () => {
  const { identity, isLoading: identityLoading } = useGetIdentity();
  if (identityLoading) {
    return <Loading />;
  }
  console.log({ identity });
  const isInternalUser =
    ["Administrator", "staff"].filter((x) => identity.roles.includes(x))
      .length > 0;

  return (
    <Authenticated>
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          typography: "body1",
          padding: "15px",
        }}
      >
        {isInternalUser ? (
          <InternalDashboard />
        ) : (
          <CommonDashboard account={identity.account_id} />
        )}
      </Box>
    </Authenticated>
  );
};
