// @ts-nocheck
import React, { useState, cloneElement } from "react";
import {
  List,
  Filter,
  Datagrid,
  TextField,
  EmailField,
  TopToolbar,
  BooleanField,
  EditButton,
  useListContext,
  Button,
  FunctionField,
  sanitizeListRestProps,
  useUpdate,
  Confirm,
  ReferenceField,
  useRecordContext,
  useRefresh,
  SearchInput,
  usePermissions,
} from "react-admin";
import Grid from "@mui/material/Grid";
import { format } from "date-fns";
import { Chip } from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockResetIcon from "@mui/icons-material/LockReset";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";

import InviteUserModal from "../../component/invite-user-modal";
import ResetPasswordModal from "../../component/reset-password-modal";

const UserFilter = (props) => (
  <Filter {...props}>
    <SearchInput data-test-id="search-by-email" source="email" />
    <SearchInput data-test-id="search-by-name" source="name" />
  </Filter>
);

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { total, resource, displayedFilters, filterValues, showFilter } =
    useListContext();

  const { isLoading: isLoadingPermission, permissions } = usePermissions();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {/* {["Administrator"].includes(permissions) && <InviteUserButton />} */}
      {!isLoadingPermission &&
      (["Administrator"].includes(permissions) ||
        ["ext_admin"].includes(permissions)) ? (
        <InviteUserButton />
      ) : null}
    </TopToolbar>
  );
};

const InviteUserButton = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button onClick={handleOpen}>
        <PersonAddAltIcon style={{ marginRight: "8px" }} />
        Invite User
      </Button>
      <InviteUserModal
        modalType="inviteUser"
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};

const ResetPasswordBtn = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button label="Reset" onClick={handleOpen} color="secondary">
        <LockResetIcon />
      </Button>
      <ResetPasswordModal
        modalType="resetPassword"
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};

const BlockUserBtn = () => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();

  const [update, { isLoading, error: updatError }] = useUpdate();

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    updateBlockUser();
    setOpen(false);
  };

  const updateBlockUser = async () => {
    const diff = {
      is_blocked: !record.is_blocked,
      deactivated_at: record.is_blocked ? null : new Date().toISOString(),
    };
    update(
      "users",
      { id: record.id, data: diff, previousData: record },
      {
        onSuccess: () => {
          refresh();
        },
      }
    );
    //
    if (updatError) {
      alert(updatError);
    }
    //
    // if (!isLoading && !updatError) {
    //   console.log(765, "hai");
    //   refresh();
    // }
  };

  return (
    <>
      <Button
        label={record.is_blocked ? "Unblock" : "Block"}
        onClick={handleClick}
        color="primary"
        disabled={isLoading}
        variant="text"
        style={{ color: record.is_blocked ? "green" : "red" }}
      >
        {record.is_blocked ? <LockOpenIcon /> : <BlockIcon />}
      </Button>
      <Confirm
        isOpen={open}
        loading={isLoading}
        title={`${record.is_blocked ? "Unblock" : "Block"} user ${
          record.email
        }`}
        content={`Are you sure you want to ${
          record.is_blocked ? "unblock" : "block"
        } ${record.email}?`}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const ListPage = (props) => {
  const { total, isLoading } = useListContext();

  console.log(987, total);

  const { isLoading: isLoadingPermission, permissions } = usePermissions();

  return (
    <List {...props} actions={<ListActions />} filters={<UserFilter />}>
      <Datagrid bulkActionButtons={false}>
        <EmailField source="email" label="Email" />
        <TextField source="name" label="Name" />

        <FunctionField
          label="Roles"
          render={(record) =>
            record?.roles?.map((data, index) => (
              <Chip key={index} style={{ margin: "5px" }} label={data} />
            ))
          }
        />
        <BooleanField source="is_blocked" label="Blocked" />
        <ReferenceField
          label="Account ID"
          source="account_id"
          reference="accounts"
        >
          <TextField source="label" />
        </ReferenceField>
        <FunctionField
          label="Registration Time"
          render={(record) => (
            <div>
              <label>
                {format(new Date(record.created_at), "dd/MM/yyyy HH:mm:ss")}
              </label>
            </div>
          )}
        />
        {!isLoadingPermission &&
        (["Administrator"].includes(permissions) ||
          ["ext_admin"].includes(permissions)) ? (
          <>
            <Grid container width={250}>
              <Grid item>
                <EditButton label="Edit" />
              </Grid>
              <Grid item>
                <BlockUserBtn />
              </Grid>
              <Grid item>
                <ResetPasswordBtn />
              </Grid>
            </Grid>
          </>
        ) : null}
      </Datagrid>
    </List>
  );
};

export default ListPage;
