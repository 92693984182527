// @ts-nocheck
import * as React from "react";
import { useState, useEffect } from "react";
import { useLogin, useNotify, Notification, useRedirect } from "react-admin";
import { Button, TextField, Box, Grid } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";

import mbaas from "../provider/mbaas";

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState({ email: false, password: false });
  const [searchParams] = useSearchParams();
  const navigate = useRedirect();
  const login = useLogin();
  const notify = useNotify();

  useEffect(() => {
    const isLogedin = mbaas.client.user.isLoggedIn;
    const checkAuth = async () => {
      console.log({ isLoggedIn: mbaas.client.user.isLoggedIn });
      const { data: userData } = await mbaas.client.user.get();
      isLogedin && userData?.roles.includes("Administrator")
        ? navigate("/users")
        : navigate("/accounts");
    };

    if (isLogedin) checkAuth();
  });

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (!data.get("email")) {
      setIsError((old) => ({ ...old, email: true }));
    }
    if (!data.get("password")) {
      setIsError((old) => ({ ...old, password: true }));
    }
    login({
      username: data.get("email"),
      password: data.get("password"),
    }).catch((e) => {
      console.log(123, "e", e);
      setLoading(false);
      notify("Invalid email or password");
    });
  };

  const google = async (e) => {
    e.preventDefault();
    await mbaas.client.auth.oauthRedirect(
      "google",
      window.location.origin + window.location.pathname
    );
  };

  const thisUrl = new URL(window.location.href);
  const googleCode = thisUrl.searchParams.get("code");

  console.log(999, "googleCode", googleCode);

  window.history.pushState(null, null, window.location.pathname);
  if (googleCode) {
    mbaas.client.auth
      .login("google", {
        callback: window.location.origin + window.location.pathname,
        code: googleCode,
      })
      .then((res) => {
        console.log(999, "res", res);
        if (res.error) {
          console.log(999, "res.error", res.error);
          return notify("Invalid OAuth2 authorization", "warning");
        }
        if (res.data.type === "LoginExisting" && res.data.verified) {
          console.log(999, "res.data", res.data);
          localStorage.setItem("username", res.data.email);
          // navigate("/", { replace: true });
          navigate("/");
        }
      });
  }

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundImage: "url(/login_bg.jpg)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "300px",
          background: "white",
          padding: "8px",
          borderRadius: "5px",
          marginTop: "-150px",
        }}
      >
        <Box
          sx={{
            marginTop: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box>
            <img src="/telinhk_logo.png" alt="Telin HK" />
          </Box>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              error={isError.email}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => {
                isError.email &&
                  setIsError((old) => ({ ...old, email: false }));
                setEmail(e.target.value);
              }}
              helperText={isError.email && "Required"}
            />
            <TextField
              error={isError.password}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => {
                isError.password &&
                  setIsError((old) => ({ ...old, password: false }));
                setPassword(e.target.value);
              }}
              helperText={isError.password && "Required"}
            />
            <Grid item>
              <Button
                data-test-id="login-btn"
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{ marginTop: "8px" }}
                disabled={false}
                // disabled={!token}
              >
                {loading ? (
                  <SpinnerCircular speed={100} enabled={loading} size={25} />
                ) : (
                  "Sign In"
                )}
              </Button>
            </Grid>

            <Grid item style={{ marginTop: 12 }}>
              <Button
                data-test-id="signin-google-btn"
                variant="contained"
                fullWidth
                color="error"
                onClick={google}
              >
                Google Sign-In
              </Button>
            </Grid>

            <Grid item style={{ marginTop: 12 }}>
              <Button
                data-test-id="forgot-pass-btn"
                size="small"
                fullWidth
                color="primary"
              >
                <Link to="/forgot-password" style={{ textDecoration: "none" }}>
                  Forgot your password?
                </Link>
              </Button>
            </Grid>
          </Box>
        </Box>
      </div>
      <Notification />
    </div>
  );
}
