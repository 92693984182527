import * as React from "react";
import { useRecordContext, useUpdate, Confirm } from "react-admin";
import { Button } from "@mui/material";
import mbaas from "../../provider/mbaas";
import { SyncLock as GenerateIcon } from "@mui/icons-material";

const GenerateKeys = ({ refresh }: any) => {
  const record = useRecordContext();
  const [open, setOpen] = React.useState(false);
  const [openPriv, setOpenPriv] = React.useState(false);
  const [keysData, setKeysData] = React.useState({
    privateKey: "",
    publicKey: "",
  });
  const [update, { isLoading }] = useUpdate();

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async () => {
    const { data } = await mbaas.client.invokeApi("generate-credential-key");
    setKeysData(data);
    setOpen(false);
    setOpenPriv(true);
  };

  const handleDialogClosePriv = () => setOpenPriv(false);
  const handleConfirmPriv = async () => {
    update(
      "generate_account_keys",
      {
        id: record.id,
        data: keysData,
        previousData: record,
      },
      {
        onSettled() {
          refresh();
        },
      }
    );

    setOpenPriv(false);
  };

  return (
    <>
      <Button
        data-test-id="generate-key-btn"
        startIcon={<GenerateIcon />}
        variant="contained"
        color="secondary"
        disabled={isLoading}
        onClick={handleClick}
      >
        Generate
      </Button>
      <Confirm
        isOpen={open}
        title="Generate Keys"
        content="Are you sure you want to generate a new key pair? this action cannot be reverted"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
      <Confirm
        cancel="close"
        isOpen={openPriv}
        loading={isLoading}
        title="Generated Private Key"
        content={<label>{keysData.privateKey}</label>}
        onConfirm={handleConfirmPriv}
        onClose={handleDialogClosePriv}
      />
    </>
  );
};

export default GenerateKeys;
