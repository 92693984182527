// @ts-nocheck
import { useEffect, useState } from "react";
import { composeFilter } from "@mocobaas/client-js";
import MaterialTable, { Column } from "@material-table/core";
import { Paper, Link } from "@mui/material";
import mbaas from "../../provider/mbaas";
import { format } from "date-fns";
import AddBalaceDialog from "./AddBalanceDialog";

const AccountLedger = ({ account_id, label }) => {
  //
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  //
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offsetPage, setOffsetPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [order, setOrder] = useState("DESC");
  const [sort, setSort] = useState("created_at");
  //
  const columnList = ["created_at", "reference", "credit", "debit", "balance"];
  //

  useEffect(() => {
    fetchList();
  }, []);

  useEffect(() => {
    fetchList(true);
  }, [rowsPerPage, offsetPage, order, sort]);

  const fetchList = async (loadingMode: boolean = true) => {
    if (loadingMode) {
      setLoading(true);
    }
    try {
      const req = await mbaas.client
        .getTable("account_ledgers")
        .include(["deposits", "transaction_entries"])
        .filter(composeFilter().eq("account_id", account_id).toString())
        .sort(order === "DESC" ? `-${sort}` : sort)
        .limit(rowsPerPage)
        .offset(offsetPage)
        .read();

      console.log(req);

      setdata(req.data);
      setCount(req.total);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };
  //

  const columns: Array<Column<IProductPricingItem>> = [
    {
      title: "Date",
      field: "created_at",
      editable: "never",
      render: (rowData) =>
        format(new Date(rowData.created_at), "dd/MM/yyyy HH:mm:ss"),
    },
    {
      title: "Reference",
      field: "reference",
      render: (rowData) =>
        rowData.transaction_entry_id ? (
          rowData.transaction_entries.order_id
        ) : (
          <Link
            href={rowData.deposits.proof_url}
            underline="none"
            target="_blank"
          >
            Proof
          </Link>
        ),
    },
    {
      title: "Credit",
      field: "credit",
      render: (rowData) =>
        rowData.credit
          ? parseFloat(rowData.credit).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "",
    },
    {
      title: "Debit",
      field: "debit",
      render: (rowData) =>
        rowData.debit
          ? parseFloat(rowData.debit).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "",
    },
    {
      title: "Balance",
      field: "balance",
      render: (rowData) =>
        rowData.balance
          ? parseFloat(rowData.balance).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "",
    },
  ];

  // show editabled when super admin or admin
  return (
    <Paper style={{ width: "100%" }} elevation={0}>
      <AddBalaceDialog refresh={fetchList} account_id={account_id} />
      <MaterialTable
        onPageChange={(page: number, pageSize: number) => {
          setCurrentPage(page);
          setRowsPerPage(pageSize);
          setOffsetPage(page * rowsPerPage);
        }}
        onOrderChange={(orderBy: number, orderDirection: "desc" | "asc") => {
          if (orderBy === -1) {
            setOrder("DESC");
            setSort("created_at");
          } else {
            setOrder(orderDirection.toUpperCase());
            setSort(columnList[orderBy]);
          }
        }}
        isLoading={loading}
        totalCount={count}
        page={currentPage}
        title={`${label} Ledgers`}
        columns={columns}
        options={{
          pageSize: rowsPerPage,
          pageSizeOptions: [1, 5, 10, 15],
          actionsColumnIndex: -1,
          emptyRowsWhenPaging: false,
          columnsButton: false,
          search: false,
          thirdSortClick: false,
        }}
        data={data}
      />
    </Paper>
  );
};

export default AccountLedger;
