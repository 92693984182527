import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import {
  People as UserIcon,
  ManageAccounts as AccountIcon,
  FactCheck as SuppliesIcon,
  ReceiptLong as TransactionIcon,
  ListAlt as ProductIcon,
  Assessment as ReportIcon,
  MiscellaneousServices as InternalIcon,
  ExpandCircleDown as ExpandMoreIcon,
  UploadFile as UploadFileIcon,
  Category as CategoryIcon,
  CurrencyExchange as ExchangeRateIcon,
  ManageSearch as LogIcon,
} from "@mui/icons-material";

import {
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  usePermissions,
  useSidebarState,
} from "react-admin";

import SubMenu from "./SubMenu";

type MenuName =
  | "menuProducts"
  | "menuSupplies"
  | "menuInternal"
  | "menuDocuments";

const Menu = ({ dense = false }: MenuProps) => {
  const { isLoading, permissions } = usePermissions();
  const [state, setState] = useState({
    menuProducts: false,
    menuSupplies: false,
    menuInternal: false,
    menuDocuments: false,
  });
  const [open] = useSidebarState();

  const handleToggle = (menu: MenuName) => {
    setState(() => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 3,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <DashboardMenuItem />
      {!isLoading && ["Administrator", "staff"].includes(permissions) && (
        <MenuItemLink
          to="/users"
          state={{ _scrollToTop: true }}
          primaryText="Users"
          leftIcon={<UserIcon />}
          dense={dense}
          data-test-id="menu-users"
        />
      )}
      {!isLoading &&
        ["Administrator", "ext_admin", "staff", "ext_staff"].includes(
          permissions
        ) && (
          <MenuItemLink
            to="/accounts"
            state={{ _scrollToTop: true }}
            primaryText="Accounts"
            leftIcon={<AccountIcon />}
            dense={dense}
            data-test-id="menu-accounts"
          />
        )}
      {!isLoading &&
        ["Administrator", "ext_admin", "staff", "ext_staff"].includes(
          permissions
        ) && (
          <MenuItemLink
            to="/transactions"
            state={{ _scrollToTop: true }}
            primaryText="Transactions"
            leftIcon={<TransactionIcon />}
            dense={dense}
            data-test-id="menu-transactions"
          />
        )}
      {!isLoading &&
        ["Administrator", "ext_admin", "staff", "ext_staff"].includes(
          permissions
        ) && (
          <SubMenu
            handleToggle={() => handleToggle("menuProducts")}
            isOpen={state.menuProducts}
            name="Products"
            icon={<ExpandMoreIcon />}
            dense={dense}
            data-test-id="submenu-products"
          >
            {!isLoading && ["Administrator", "staff"].includes(permissions) && (
              <MenuItemLink
                to="/product_categories"
                resource="product_categories"
                state={{ _scrollToTop: true }}
                primaryText="Categories"
                leftIcon={<CategoryIcon />}
                dense={dense}
                data-test-id="menu-categories"
              />
            )}
            {!isLoading &&
              ["Administrator", "ext_admin", "staff", "ext_staff"].includes(
                permissions
              ) && (
                <MenuItemLink
                  to="/product_items"
                  state={{ _scrollToTop: true }}
                  primaryText="Items"
                  leftIcon={<ProductIcon />}
                  dense={dense}
                  data-test-id="menu-items"
                />
              )}
          </SubMenu>
        )}
      {!isLoading && ["Administrator", "staff"].includes(permissions) && (
        <MenuItemLink
          to="/suppliers"
          state={{ _scrollToTop: true }}
          primaryText="Suppliers"
          leftIcon={<SuppliesIcon />}
          dense={dense}
          data-test-id="menu-suppliers"
        />
      )}
      {!isLoading &&
        ["Administrator", "ext_admin", "staff", "ext_staff"].includes(
          permissions
        ) && (
          <SubMenu
            handleToggle={() => handleToggle("menuDocuments")}
            isOpen={state.menuDocuments}
            name="Documents"
            icon={<ExpandMoreIcon />}
            dense={dense}
            data-test-id="submenu-documents"
          >
            {!isLoading &&
              ["Administrator", "ext_admin", "staff", "ext_staff"].includes(
                permissions
              ) && (
                <MenuItemLink
                  to="/reports"
                  state={{ _scrollToTop: true }}
                  primaryText="Reports"
                  leftIcon={<ReportIcon />}
                  dense={dense}
                  data-test-id="menu-reports"
                />
              )}
            {!isLoading &&
              ["Administrator", "ext_admin", "staff", "ext_staff"].includes(
                permissions
              ) && (
                <MenuItemLink
                  to="/batch"
                  state={{ _scrollToTop: true }}
                  primaryText="Batch"
                  leftIcon={<UploadFileIcon />}
                  dense={dense}
                  data-test-id="menu-batch"
                />
              )}
          </SubMenu>
        )}
      {!isLoading &&
        ["Administrator", "ext_admin", "staff", "ext_staff"].includes(
          permissions
        ) && (
          <SubMenu
            handleToggle={() => handleToggle("menuInternal")}
            isOpen={state.menuInternal}
            name="Internal"
            icon={<ExpandMoreIcon />}
            dense={dense}
            data-test-id="submenu-internal"
          >
            {!isLoading && ["Administrator", "staff"].includes(permissions) && (
              <MenuItemLink
                to="/convertion_rates"
                state={{ _scrollToTop: true }}
                primaryText="Conversion Rate"
                leftIcon={<ExchangeRateIcon />}
                dense={dense}
                data-test-id="menu-conversion-rates"
              />
            )}
            {!isLoading &&
              ["Administrator", "ext_admin", "staff", "ext_staff"].includes(
                permissions
              ) && (
                <MenuItemLink
                  to="/configuration"
                  state={{ _scrollToTop: true }}
                  primaryText="Configuration"
                  leftIcon={<InternalIcon />}
                  dense={dense}
                  data-test-id="configuration"
                />
              )}
            {!isLoading &&
              ["Administrator", "ext_admin", "staff", "ext_staff"].includes(
                permissions
              ) && (
                <MenuItemLink
                  to="/execution_logs"
                  state={{ _scrollToTop: true }}
                  primaryText="Execution Logs"
                  leftIcon={<LogIcon />}
                  dense={dense}
                  data-test-id="execution_logs"
                />
              )}
          </SubMenu>
        )}
    </Box>
  );
};

export default Menu;
