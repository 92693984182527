import mbaas from "./mbaas";
import { AuthProvider, UserIdentity } from "react-admin";

const Auth: AuthProvider = {
  login: async ({ username, password }) => {
    const { data, error } = await mbaas.client.auth.login("local", {
      email: username,
      password: password,
    });
    //
    if (!error) {
      localStorage.setItem("username", username);
      // @ts-ignore
      localStorage.setItem("permissions", data.roles);
      return Promise.resolve();
    } else {
      console.log({ error });
      return Promise.reject();
    }
  },
  logout: async () => {
    mbaas.client.user.logout();
    localStorage.removeItem("username");
    localStorage.removeItem("MBaaS.auth.expiry");
    localStorage.removeItem("MBaaS.auth.accessToken");
    localStorage.removeItem("MBaaS.auth.refreshToken");
    localStorage.removeItem("permissions");
    localStorage.removeItem("reactQueryDevtoolsSortFn");
    return Promise.resolve();
  },
  checkError: async () => {
    const { error } = await mbaas.client.user.get();
    if (error && ["401", "403"].includes(error.status as string)) {
      localStorage.removeItem("username");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: async () => {
    const token = localStorage.getItem("MBaaS.auth.accessToken");
    const isLoggedIn = mbaas.client.user.isLoggedIn;
    const url = new URL(window.location.href);
    //
    if (
      [
        "/forgot-password",
        "/reset-password",
        "/email-verification-result",
        "/account-link",
      ].includes(url.pathname)
    ) {
      return !isLoggedIn && token === null
        ? Promise.resolve()
        : Promise.reject();
    }
    //
    return isLoggedIn ? Promise.resolve() : Promise.reject();
  },
  getPermissions: async () => {
    let role: string[] | null | string = localStorage.getItem("permissions");
    if (!role) {
      const { data: userData } = await mbaas.client.user.get();

      role = userData?.roles || "";
      // @ts-ignore
      localStorage.setItem("permissions", userData.roles);
      // @ts-ignore
      localStorage.setItem("username", userData.email);
    }
    return role ? Promise.resolve(role) : Promise.reject();
  },
  getIdentity: async () => {
    try {
      const userData = await mbaas.client.user.get();
      if (userData.error) throw new Error(userData.error.code);
      return Promise.resolve(userData.data as UserIdentity);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  data: () => mbaas.client.user.get().then((result) => result.data || null),
};

export default Auth;
