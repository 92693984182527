import { CreateParams, CreateResult } from "react-admin";
import mbaas from "../../provider/mbaas";

const CreateFunction = async (resource: string, params: CreateParams) => {
  console.log(777, { resource: resource, params: params });
  let data = {};
  if (resource === "users") {
    const {
      email,
      password,
      name,
      facebook,
      twitter,
      google,
      verified,
      is_blocked,
      mfa,
      status,
      roles,
    } = params.data;

    const { data: dataResult } = await mbaas.client.auth.register("local", {
      email: email,
      password: password,
      extras: {
        name: name,
        social_ids: Object.assign({
          facebook: facebook,
          google: google,
          twitter: twitter,
        }),
        verified: verified,
        is_blocked: is_blocked,
        mfa: mfa,
        // account_id: account_id,
        status: status,
        roles: roles,
      },
    });
    data = dataResult || {};
  }
  //
  if (resource === "suppliers") {
    const { contact, description, user_id, is_available, name, totp } =
      params.data;

    const { data, error } = await mbaas.client.invokeApi("add-supplier", {
      dataSupplier: {
        user_id: user_id,
        name: name,
        contact: contact,
        description: description,
        is_available: is_available,
      },
      token: totp,
    });

    if (data) {
      const { token, ...newData } = data;

      return { data: newData.dataNewSupplier } as CreateResult;
    }
    if (error) {
      console.log(error);
      throw new Error(error.error);
    }
  }
  //
  // if (resource === "reports") {
  // }
  //
  if (resource === "configuration") {
    const { key, description, value, author, metadata, totp } = params.data;

    const { data, error } = await mbaas.client.invokeApi("add-configurations", {
      token: totp,
      author: author,
      key: key,
      description: description,
      value: value,
    });

    if (data) {
      const { token, ...newData } = data;

      return { data: newData } as CreateResult;
    }
    if (error) {
      console.log(error);
      throw new Error(error.error);
    }
  }
  //
  if (resource === "override") {
    const res = await mbaas.client
      .invokeApi("override-transaction", {
        trxId: params.data.id,
        token: params.data.totp,
        target: params.data.target,
        remark: params.data.remark,
        newStatus: params.data.newStatus,
        newProductId: params.data.newProductId,
        additionalData: params.data.additional_data,
      })
      .then((response) => {
        console.log(response);
        if (response.data.error) {
          alert(response.data.error);
        } else {
          alert("data has been override");
        }
      });

    return { data: { id: Math.random(), data: res } } as CreateResult;
  }
  //
  if (resource === "convertion_rates") {
    const { origin, destination, rate, author, totp } = params.data;

    const { data, error } = await mbaas.client.invokeApi(
      "add-convertion-rate",
      {
        dataConvertionRate: {
          origin: origin,
          destination: destination,
          rate: rate,
          author: author,
        },
        token: totp,
      }
    );

    if (data) {
      const { token, ...newData } = data;

      return { data: newData.dataNewConvertionRate } as CreateResult;
    }
    if (error) {
      console.log(error);
      throw new Error(error.error);
    }
  }
  //
  if (resource === "accounts") {
    data = await mbaas.client.getTable(resource).insert({
      label: params.data.label,
      contact: params.data.contact,
      transaction_prefix: params.data.transaction_prefix,
    });
    // update user data
    const { data: userData } = await mbaas.client.user.get();
    // @ts-ignore
    console.log({ data: data.data.id, userData });
    if (userData && data) {
      const isAdmin = userData.roles.includes("Administrator");
      const user_id = isAdmin ? params.data.id : userData.id;
      if (user_id) {
        await mbaas.client.getTable("users").update({
          // @ts-ignore
          id: user_id,
          // @ts-ignore
          data: { account_id: data.data?.id },
        });
      }
    }
    return data as CreateResult;
  }
  data = await mbaas.client.getTable(resource).insert(params.data);
  return data as CreateResult;
};

export default CreateFunction;
