import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ShowButton,
  TopToolbar,
  CreateButton,
  useListContext,
  usePermissions,
  NumberField,
} from "react-admin";

const ListActions = () => {
  const { total, isLoading } = useListContext();
  const { isLoading: isLoadingPermission, permissions } = usePermissions();
  if (
    !isLoading &&
    !isLoadingPermission &&
    (["Administrator"].includes(permissions) ||
      (["ext_admin"].includes(permissions) && total === 0))
  ) {
    return (
      <TopToolbar>
        <CreateButton data-test-id="create-btn" />
      </TopToolbar>
    );
  }
  return null;
};

const ListPage = () => {
  return (
    <List
      perPage={25}
      sort={{ field: "created_at", order: "desc" }}
      actions={<ListActions />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="label" />
        <TextField source="contact" />
        <TextField source="transaction_prefix" />
        <NumberField source="wallets.0.balance" />
        <DateField source="created_at" showTime />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default ListPage;
