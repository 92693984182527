import { GetListParams, GetListResult } from "react-admin";
import gql from "graphql-tag";
import mbaas from "../../provider/mbaas";
import { composeFilter } from "@mocobaas/client-js";
import moment from "moment";

const GetListFunction = async (resource: string, params: GetListParams) => {
  console.log("getList", { resource, params });
  const { page, perPage } = params.pagination;
  let { field, order } = params.sort;
  const { nb_commands_lte, last_seen_gte, last_seen_lte, q, ...resKeys } =
    params.filter;
  let includedTables: string[] = [];
  let initTable = mbaas.client.getTable(resource);
  //
  let filterObject = composeFilter();
  let filterToString: string = "";
  //
  if (resource === "users_without_account") {
    const result = await mbaas.client
      .getTable("users")
      .filter(composeFilter().eq("account_id", null).toString())
      .limit(perPage)
      .offset((page - 1) * perPage)
      .read();
    console.log({ resource, result });
    //
    return { data: result.data, total: result.total } as GetListResult;
  }
  //
  if (resource === "users_ext_admin") {
    const { allUsersList } = await mbaas.client.gql.query({
      query: gql`
        query Users($role: String!, $limit: Int!, $offset: Int!) {
          allUsersList(
            limit: $limit
            offset: $offset
            where: {
              roles: { _has_key: $role }
              account_id: { _is_null: true }
            }
          ) {
            data {
              id
              name
            }
            count
          }
        }
      `,
      variables: {
        role: "ext_admin",
        limit: perPage,
        offset: (page - 1) * perPage,
      },
    });
    //
    return {
      data: allUsersList.data || [],
      total: allUsersList.count || 0,
    } as GetListResult;
  }
  //
  switch (resource) {
    case "users":
      includedTables = ["accounts"];
      break;
    case "suppliers":
      includedTables = ["users"];
      break;
      case "accounts":
        includedTables = ["wallets"];
        break;
  }

  if (resource === "supply_items") {
    const where: Record<string, any> = {};
    const filterArray: any[] = [];
    if (params.filter.supplier_id) {
      filterArray.push({ supplier_id: { _eq: params.filter.supplier_id } });
    }
    if (params.filter.label) {
      filterArray.push({ label: { _ilike: `%${params.filter.label}%` } });
    }
    where._and = filterArray;
    const args = buildArgs({
      // @ts-ignore
      order: order || "ASC",
      size: perPage,
      pageNumber: page,
      sort: field || "label",
    });
    const queryResult = await mbaas.client.gql.query({
      query: gql`
          query suppliedItemsList($where:SupplyItemsWhereInput) {
            allSupplyItemsList(where: $where, ${args}) {
              data {
                id
                created_at
                updated_at
                supplier_id
                label
                description
                origin_currency
                origin_cost
                hkd_cost
                is_available
                inquiry_rules
                execution_rules
                convertion_detail
              }
              count
            }
          }
        `,
      variables: {
        where,
      },
    });

    return {
      data: queryResult?.allSupplyItemsList?.data || [],
      total: queryResult?.allSupplyItemsList?.count || 0,
    } as GetListResult;
  }

  if (resource === "convertion_rates") {
    const { allConvertionRatesList } = await mbaas.client.gql.query({
      query: gql`
        query convertionRateList($limit: Int!, $offset: Int!) {
          allConvertionRatesList(limit: $limit, offset: $offset) {
            data {
              id
              created_at
              updated_at
              origin
              destination
              rate
              author
            }
            count
          }
        }
      `,
      variables: {
        limit: perPage,
        offset: (page - 1) * perPage,
      },
    });
    return {
      data: allConvertionRatesList.data || [],
      total: allConvertionRatesList.count || 0,
    } as GetListResult;
  }

  if (resource === "execution_logs" && params.sort.field === "type") {
    const { reference_id, type } = params.filter;
    //
    const where: Record<string, any> = {};
    const filterArray: any[] = [];
    if (reference_id) {
      filterArray.push({ reference_id: { _ilike: `%${reference_id}%` } });
    }
    if (type) {
      filterArray.push({ type: { _ilike: `%${type}%` } });
    }
    if (filterArray.length > 0) where._and = filterArray;
    //
    const { allExecutionLogsList } = await mbaas.client.gql.query({
      query: gql`
        query executionLogsList(
          $limit: Int!
          $offset: Int!
          $order: PaginationOrderEnum
          $where: ExecutionLogsWhereInput
        ) {
          allExecutionLogsList(
            orderBy: { type: $order }
            limit: $limit
            offset: $offset
            where: $where
          ) {
            data {
              id
              created_at
              updated_at
              type
              reference_id
              stage
              status
              reference_detail
              normal_detail
              exception_detail
            }
            count
          }
        }
      `,
      variables: {
        limit: perPage,
        offset: (page - 1) * perPage,
        order: params.sort.order.toUpperCase(),
        where,
      },
    });
    return {
      data: allExecutionLogsList.data || [],
      total: allExecutionLogsList.count || 0,
    } as GetListResult;
  }

  if (resource === "configuration") {
    const { allConfigurationsList } = await mbaas.client.gql.query({
      query: gql`
        query configurationList($limit: Int!, $offset: Int!) {
          allConfigurationsList(limit: $limit, offset: $offset) {
            data {
              id
              created_at
              updated_at
              key
              description
              value
              author
              metadata
            }
            count
          }
        }
      `,
      variables: {
        limit: perPage,
        offset: (page - 1) * perPage,
      },
    });
    return {
      data: allConfigurationsList.data || [],
      total: allConfigurationsList.count || 0,
    } as GetListResult;
  }

  if (resource === "batch") {
    console.log({ method: "getList", resource, params });
    const where: Record<string, any> = {};
    const filterArray: any[] = [];

    filterArray.push({
      type: {
        _ilike: `%_batch%`,
      },
    });

    if (params.filter.type) {
      filterArray.push({
        type: {
          _ilike: `%${params.filter.type}%`,
        },
      });
    }

    if (filterArray.length > 0) where._and = filterArray;

    const args = buildArgs({
      // @ts-ignore
      order: order || "ASC",
      size: perPage,
      pageNumber: page,
      sort: field || "created_at",
    });

    const { allReportsList } = await mbaas.client.gql.query({
      query: gql`
        query getAllReports($where: ReportsWhereInput) {
          allReportsList(where:$where, ${args}) {
            data {
              id
              created_at
              updated_at
              author_id
              type
              status
              url
              metadata
              filename
              remark
            }
            count
          }
        }
      `,
      variables: {
        where,
      },
    });

    console.log(661231, "allReportsList", allReportsList);
    return {
      data: allReportsList.data || [],
      total: allReportsList.count || 0,
    } as GetListResult;
  }

  if (resource === "reports") {
    console.log({ method: "getList", resource, params });
    const where: Record<string, any> = {};
    const filterArray: any[] = [];

    filterArray.push({
      type: {
        _nilike: `%_batch%`,
      },
    });

    if (params.filter.type) {
      filterArray.push({
        type: {
          _ilike: `%${params.filter.type}%`,
        },
      });
    }

    if (filterArray.length > 0) where._and = filterArray;

    const args = buildArgs({
      // @ts-ignore
      order: order || "ASC",
      size: perPage,
      pageNumber: page,
      sort: field || "created_at",
    });

    const { allReportsList } = await mbaas.client.gql.query({
      query: gql`
        query getAllReports($where: ReportsWhereInput) {
          allReportsList(where:$where, ${args}) {
            data {
              id
              created_at
              updated_at
              author_id
              type
              status
              url
              metadata
              filename
              remark
            }
            count
          }
        }
      `,
      variables: {
        where,
      },
    });

    console.log(661231, "allReportsList", allReportsList);
    return {
      data: allReportsList.data || [],
      total: allReportsList.count || 0,
    } as GetListResult;
  }

  if (resource === "suppliers") {
    //
    const { name } = params.filter;
    //
    const where = `{
        ${name ? `name: { _ilike: "%${name}%" }` : ""}
      }`;
    //
    const { allSuppliersList } = await mbaas.client.gql.query({
      query: gql`
          query Supplied_items($limit: Int!, $offset: Int!) {
            allSuppliersList(
              limit: $limit
              offset: $offset
              where: ${where}
            ) {
              data {
                id
                created_at
                updated_at
                user_id
                name
                contact
                description
                is_available
                supply_items_supplier_id_list {
                  data {
                    id
                    created_at
                    updated_at
                    supplier_id
                    label
                    description
                    origin_currency
                    origin_cost
                    hkd_cost
                    is_available
                    inquiry_rules
                    execution_rules
                    convertion_detail
                  }
                  count
                }
              }
              count
            }
          }
        `,
      variables: {
        limit: perPage,
        offset: (page - 1) * perPage,
      },
    });

    return {
      data: allSuppliersList.data || [],
      total: allSuppliersList.count || 0,
    } as GetListResult;
  }

  if (
    resource === "product_items" &&
    params.sort.field === "product_category_id"
  ) {
    const { label: labelFilter, product_category_id: categoryFilter } =
      params.filter;
    const where: Record<string, any> = {};
    const filterArray: any[] = [];
    if (categoryFilter) {
      filterArray.push({ product_category_id: { _eq: categoryFilter } });
    }
    if (labelFilter) {
      filterArray.push({ label: { _ilike: `%${labelFilter}%` } });
    }
    if (filterArray.length > 0) where._and = filterArray;
    const results = await mbaas.client.gql.query({
      query: gql`
        query ProductItems(
          $limit: Int!
          $offset: Int!
          $order: PaginationOrderEnum
          $where: ProductItemsWhereInput
        ) {
          allProductItemsList(
            limit: $limit
            offset: $offset
            orderBy: { product_category_id_data: { label: $order } }
            where: $where
          ) {
            data {
              id
              item_key
              label
              description
              product_category_id
              type
              supply_mode
              is_available
              country
            }
            count
          }
        }
      `,
      variables: {
        limit: perPage,
        offset: (page - 1) * perPage,
        order: params.sort.order.toUpperCase(),
        where,
      },
    });
    return {
      data: results.allProductItemsList.data || [],
      total: results.allProductItemsList.count || 0,
    } as GetListResult;
  }

  if (resource === "product_categories") {
    console.log({ method: "getList", resource, params });
    const where: Record<string, any> = {};
    const filterArray: any[] = [];

    if (params.filter.q) {
      filterArray.push({
        label: {
          _ilike: `%${params.filter.q}%`,
        },
      });
    }

    if (filterArray.length > 0) where._and = filterArray;

    const args = buildArgs({
      // @ts-ignore
      order: order || "ASC",
      size: perPage,
      pageNumber: page,
      sort: field || "created_at",
    });
    const queryResult = await mbaas.client.gql.query({
      query: gql`
        query getAllCategories($where: ProductCategoriesWhereInput) {
          allProductCategoriesList(where: $where, ${args}) {
            data {
              id
              created_at
              updated_at
              label
              is_available
              json_metadata
            }
            count
          }
        }
      `,
      variables: {
        where,
      },
    });

    console.log(77821, "data", queryResult?.allProductCategoriesList);

    return {
      data: queryResult?.allProductCategoriesList?.data || [],
      total: queryResult?.allProductCategoriesList?.count || 0,
    } as GetListResult;
  }

  if (resource === "transactions") {
    console.log({ method: "getList", resource, params });
    const where: Record<string, any> = {};
    const filterArray: any[] = [];
    if (params.filter.dateGte || params.filter.dateLte) {
      filterArray.push({
        created_at: {
          _gte: moment(params.filter.dateGte).startOf("day").toISOString(),
        },
      });
      filterArray.push({
        created_at: {
          _lte: moment(params.filter.dateLte).endOf("day").toISOString(),
        },
      });
    }
    if (params.filter.order_id) {
      filterArray.push({
        order_id: {
          _ilike: `%${params.filter.order_id}%`,
        },
      });
    }
    if (params.filter.reference_id) {
      filterArray.push({
        reference_id: {
          _ilike: `%${params.filter.reference_id}%`,
        },
      });
    }
    if (params.filter.status) {
      filterArray.push({
        status: {
          _eq: params.filter.status,
        },
      });
    }
    if (filterArray.length > 0) where._and = filterArray;

    const args = buildArgs({
      // @ts-ignore
      order: order || "ASC",
      size: perPage,
      pageNumber: page,
      sort: field || "created_at",
    });
    const queryResult = await mbaas.client.gql.query({
      query: gql`
          query TransactionEntriesList($where:TransactionEntriesWhereInput) {
            allTransactionEntriesList(where: $where, ${args}) {
              data {
                id
                created_at
                order_id
                reference_id
                account_id_data{
                  label
                }
                selling_price
                selling_currency
                hkd_price
                target_detail
                status
                product_item_id
                product_snapshot
                pricing_snapshot
                supply_item_id
                supply_snapshot
                conversion_rate_snapshot
                extra_context
              }
              count
            }
          }
        `,
      variables: {
        where,
      },
    });

    return {
      data: queryResult?.allTransactionEntriesList?.data || [],
      total: queryResult?.allTransactionEntriesList?.count || 0,
    } as GetListResult;
  }

  if (resource === "transaction_histories") {
    console.log({ method: "getList", resource, params });
    const where: Record<string, any> = {};
    const filterArray: any[] = [];
    if (params.filter.transaction_log_id) {
      filterArray.push({
        transaction_log_id: {
          _eq: params.filter.transaction_log_id,
        },
      });
    }
    if (filterArray.length > 0) where._and = filterArray;

    const args = buildArgs({
      // @ts-ignore
      order: order || "DESC",
      size: perPage,
      pageNumber: page,
      sort: field || "created_at",
    });
    const queryResult = await mbaas.client.gql.query({
      query: gql`
          query TransactionHistoriesList($where:TransactionHistoriesWhereInput) {
              allTransactionHistoriesList(where: $where, ${args}){
                data {
                  id
                  created_at
                  transaction_log_id
                  status
                  remark
                  data
                  override_by_data {
                    name
                  }
                  previous_supply_snapshot
                }
                count
              }
          }
        `,
      variables: {
        where,
      },
    });

    return {
      data: queryResult?.allTransactionHistoriesList?.data || [],
      total: queryResult?.allTransactionHistoriesList?.count || 0,
    } as GetListResult;
  }

  if (resKeys) {
    Object.keys(resKeys).forEach((x) => {
      filterObject = x.includes("id")
        ? filterObject.eq(x, resKeys[x])
        : filterObject.contains((y) => y.toLower(x), resKeys[x].toLowerCase());
    });
    filterToString = filterObject.toString();
  }
  //
  if (includedTables.length > 0) {
    initTable = initTable.include(includedTables);
  }
  //
  const result = await initTable
    .filter(filterToString)
    .sort(order === "DESC" ? `-${field}` : field)
    .limit(perPage)
    .offset((page - 1) * perPage)
    .read();
  console.log({ result });
  //
  return { data: result.data, total: result.total } as GetListResult;
};

interface GqlArgs {
  size?: number;
  pageNumber?: number;
  sort?: string;
  order?: "ASC" | "DESC";
}

function buildArgs(args: GqlArgs): string {
  let argsArray: string[] = [];
  if (args.size) {
    argsArray.push(`limit:${args.size || 25}`);
  }
  if (args.pageNumber) {
    argsArray.push(`offset:${(args.pageNumber - 1) * (args.size || 25)}`);
  }
  if (args.sort) {
    argsArray.push(`sort:${args.sort}`);
  }
  if (args.order) {
    argsArray.push(`order:${args.order}`);
  }

  return argsArray.join(",");
}

export default GetListFunction;
