// @ts-nocheck
import React, { useCallback } from "react";
import {
  List,
  Datagrid,
  TopToolbar,
  sanitizeListRestProps,
  TextField,
  Pagination,
  DateField,
  FilterButton,
  Button,
  FunctionField,
  usePermissions,
  useListContext,
  useCreate,
  SelectInput,
  Confirm,
  TextInput,
} from "react-admin";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { DateRange } from "../DateRange";
import { Detail } from "./Detail";
import { Drawer, Paper, Typography, Backdrop } from "@mui/material";
import { endOfToday, startOfToday, subMonths, format } from "date-fns";
import GetAppIcon from "@mui/icons-material/GetApp";
import mbaas from "../../provider/mbaas";

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;

  const [userData, setUserData] = React.useState({});
  const navigate = useNavigate();
  const { permissions } = usePermissions();
  const [open, setOpen] = React.useState(false);

  console.log(788, "permissions", permissions);

  const getUserData = async () => {
    const res = await mbaas.client.user.get();
    if (res.data) {
      setUserData(res.data);
    }
    if (res.error) {
      // @ts-ignore
      notify(res.error);
    }
  };

  React.useEffect(() => {
    getUserData();
  }, []);

  const ProductItemsExportButton = () => {
    const { filterValues, filter } = useListContext();
    //
    const type_name = "transaction_entries";
    const gen_file_name = `${type_name}_${format(
      new Date(endOfToday()),
      "yyyy-MM-dd"
    )}`;
    //
    const [exportData, { data, isLoading, error }] = useCreate("reports", {
      data: {
        type: type_name,
        author_id: userData?.id,
        metadata: {
          begin: filterValues.dateGte,
          end: filterValues.dateLte,
          generated_filename: gen_file_name,
          order_id: filterValues.order_id,
          status: filterValues.status,
        },
      },
    });

    if (data && !error) {
      //
      console.log(767, "haiii");
    }

    const handleOnClick = () => {
      exportData();
      setOpen(true);
    };

    const navigateToReportPage = () => {
      navigate(encodeURI(`/reports?filter={"type":"transaction_entries"}`));
    };
    const ConfirmDialogContent = () => {
      return (
        <Paper elevation={0}>
          <Typography variant="body1" gutterBottom>
            Your file is exported as "{gen_file_name}.csv", to view the progress
            please visit report page below
          </Typography>
        </Paper>
      );
    };

    return (
      <Button label={`Export transction data`} onClick={() => handleOnClick()}>
        <GetAppIcon />
        <Confirm
          isOpen={open}
          title="Dear User,"
          content={<ConfirmDialogContent />}
          onClose={() => setOpen(false)}
          confirm="Go To Report"
          onConfirm={navigateToReportPage}
        />
      </Button>
    );
  };

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <FilterButton filters={TransactionsFilter} />
      <ProductItemsExportButton permissions={permissions} />
    </TopToolbar>
  );
};

const TransactionsPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
);

const TransactionsFilter = [
  <DateRange alwaysOn />,
  <TextInput
    source="order_id"
    label="Order ID"
    alwaysOn
    data-testid="search-order-id"
  />,
  <SelectInput
    source="status"
    label="Status"
    choices={[
      { id: "UNPAID", name: "UNPAID" },
      { id: "PAID", name: "PAID" },
      { id: "QUOTE", name: "QUOTE" },
      { id: "PENDING", name: "PENDING" },
      { id: "SUCCESS", name: "SUCCESS" },
      { id: "FAILED", name: "FAILED" },
      { id: "CANCELED", name: "CANCELED" },
    ]}
    alwaysOn
    data-testid="search-status"
  />,
  <TextInput
    source="reference_id"
    label="Ref ID"
    data-testid="search-reference-id"
  />,
];

const rowStyleByStatus = (record) => {
  const bgColorMap = {
    success: "#dcf8c6",
    failed: "#ffe4e4",
  };
  let bgColor = bgColorMap[record.status.toLowerCase()];
  if (!bgColor) bgColor = "transaparent";
  return {
    backgroundColor: bgColor,
  };
};

export const TransactionsList = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleCancelDrawer = useCallback(() => {
    navigate(`/transactions`);
  }, [navigate]);

  const match = matchPath("/transactions/:id/show/*", location.pathname);

  return (
    <>
      <List
        resource="transactions"
        title="Transactions"
        filters={TransactionsFilter}
        filterDefaultValues={{
          dateGte: subMonths(startOfToday(), 1),
          dateLte: endOfToday(),
        }}
        sort={{ field: "created_at", order: "DESC" }}
        actions={<ListActions />}
        perPage={50}
        pagination={<TransactionsPagination />}
        sx={{
          flexGrow: 1,
          transition: (theme: any) =>
            theme.transitions.create(["all"], {
              duration: theme.transitions.duration.enteringScreen,
            }),
          marginRight: !!match ? "600px" : 0,
        }}
      >
        <Datagrid
          rowStyle={rowStyleByStatus}
          rowClick={(id) => {
            navigate(`/transactions/${id}/show`);
          }}
          key={(id) => id}
          bulkActionButtons={false}
        >
          <DateField source="created_at" label="Date" showTime />
          <TextField source="order_id" label="Order ID" />
          <TextField source="reference_id" label="Ref ID" />
          <TextField
            source="account_id_data.label"
            label="Account"
            sortable={false}
          />
          <TextField
            source="product_snapshot.description"
            label="Product"
            sortable={false}
          />
          <TextField source="target_detail" label="Target" />
          <TextField
            sortable={false}
            source="supply_snapshot.supplierCode"
            label="Supplier"
          />
          <TextField
            sortable={false}
            source="supply_snapshot.data.origin_currency"
            label="Currency"
          />
          <TextField
            sortable={false}
            source="supply_snapshot.data.origin_cost"
            label="Price"
          />
          <FunctionField
            label="Status"
            source="status"
            render={(record) => <label>{record.status.toUpperCase()}</label>}
          />
        </Datagrid>
      </List>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!!match}
      >
        <Drawer
          // variant="persistent"
          open={!!match}
          anchor="right"
          onClose={handleCancelDrawer}
          sx={{ zIndex: 100 }}
        >
          {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
          {!!match && (
            <Detail
              id={(match as any).params.id}
              onCancel={handleCancelDrawer}
              match={match}
            />
          )}
        </Drawer>
      </Backdrop>
    </>
  );
};
