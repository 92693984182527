import React from "react";
import { Box, Grid, Stack, Typography, TextField } from "@mui/material";
import {
  Create,
  SimpleForm,
  ReferenceInput,
  TextInput,
  BooleanInput,
  SelectInput,
  CreateProps,
  AutocompleteInput,
  useRefresh,
  required,
  Confirm,
  FormDataConsumer,
  Toolbar,
  SaveButton,
} from "react-admin";
import JSONInput from "../../JSONInput";
import mbaas from "../../../provider/mbaas";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const CreatePage = ({ ...props }: CreateProps) => {
  const refresh = useRefresh();
  const navigate = useNavigate();
  const onSettled = () => {
    refresh();
  };
  //
  const [open, setOpen] = React.useState(false);
  const [otpCode, setOtpCode] = React.useState("");
  const [formData, setFormData] = React.useState({
    product_category_id: "",
    label: "",
    description: "",
    type: "",
    is_available: false,
    supply_mode: "",
    execution_rules: {},
    inquiry_rules: {},
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const handleDialogClose = () => setOpen(false);
  //
  const handleConfirm = async () => {
    setIsLoading(true);
    const { data, error } = await mbaas.client.invokeApi("add-product-item", {
      dataProductItem: {
        product_category_id: formData.product_category_id,
        label: formData.label,
        description: formData.description,
        type: formData.type,
        is_available: formData.is_available,
        supply_mode: formData.supply_mode,
        execution_rules: formData.execution_rules,
        inquiry_rules: formData.inquiry_rules,
      },
      token: otpCode,
    });

    if (error) {
      Swal.fire(`Request failed: ${error.error}`, "", "error");
    }
    if (data) {
      Swal.fire("Success!", "", "success");
      navigate("/product_items");
      refresh();
    }
    setIsLoading(false);
    setOpen(false);
  };
  //
  const CustomToolbar = () => {
    return (
      <Toolbar>
        <Stack direction="row" justifyContent="space-between" width="100%">
          <SaveButton onClick={() => setOpen(true)} />
        </Stack>
      </Toolbar>
    );
  };
  //
  return (
    <>
      <Confirm
        isOpen={open}
        loading={otpCode.length < 6 ? true : isLoading}
        title="OTP code"
        content={
          <TextField
            type="number"
            fullWidth
            data-test-id="input-otp"
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
          />
        }
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
      <>
        <Box
          display="block"
          ml={2}
          mr={2}
          justifyContent="center"
          alignItems="center"
          width="50vW"
        >
          <Stack direction="row" pr={1}>
            <Typography variant="h6" flex="1">
              Create New Product
            </Typography>
          </Stack>
          <SimpleForm toolbar={<CustomToolbar />}>
            <Grid container spacing={2}>
              <Box flex={2}>
                <ReferenceInput
                  source="product_category_id"
                  reference="product_categories"
                  validate={[required()]}
                >
                  <AutocompleteInput
                    source="label"
                    label="Category"
                    fullWidth
                    data-test-id="category"
                    optionText={(choice?: any) =>
                      choice?.id ? choice.label : ""
                    }
                  />
                </ReferenceInput>
                {/* <TextInput
                source="item_key"
                validate={[required()]}
                label="Code"
                data-test-id="code"
                fullWidth
              /> */}
                <TextInput
                  source="label"
                  validate={[required()]}
                  fullWidth
                  data-test-id="label"
                />
                <TextInput
                  source="description"
                  data-test-id="description"
                  validate={[required()]}
                  fullWidth
                />
                <SelectInput
                  source="type"
                  data-test-id="type"
                  validate={[required()]}
                  fullWidth
                  choices={[
                    { id: "ABSOLUTE", name: "ABSOLUTE" },
                    { id: "BILL", name: "BILL" },
                  ]}
                />
                <SelectInput
                  source="country"
                  data-test-id="country"
                  validate={[required()]}
                  fullWidth
                  choices={[
                    { id: "ID", name: "ID (Indonesia)" },
                    { id: "HK", name: "HK (Hongkong)" },
                    { id: "TW", name: "TW (Taiwan)" },
                  ]}
                />
                <JSONInput
                  source="inquiry_rules"
                  data-test-id="inquiry_rules"
                />
                <JSONInput source="execution_rules" />
                <BooleanInput
                  fullWidth
                  source="is_available"
                  label="Status"
                  data-test-id="status"
                />
                <SelectInput
                  source="supply_mode"
                  data-test-id="supply_mode"
                  validate={[required()]}
                  fullWidth
                  choices={[
                    { id: "LCR", name: "Least Cost Routing (LCR)" },
                    { id: "LB", name: "Load Balancing (LB)" },
                  ]}
                />
              </Box>
            </Grid>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                setFormData(formData);
                return <></>;
              }}
            </FormDataConsumer>
          </SimpleForm>
        </Box>
      </>
    </>
  );
};

export default CreatePage;
