import * as React from "react";
import {
  FormTab,
  TabbedForm,
  Edit,
  useRecordContext,
  useShowController,
  EditProps,
} from "react-admin";
import Detail from "./AccountDetail";
import Ledger from "./AccountLedger";

const Title = () => {
  const record = useRecordContext();
  return (
    <span data-test-id="edit-title">Edit {record ? record.label : ""}</span>
  );
};

const EditPage = ({ ...props }: EditProps) => {
  const { record } = useShowController(props);
  return (
    <Edit title={<Title />} sx={{ marginTop: 5 }}>
      <TabbedForm toolbar={false} syncWithLocation={false}>
        <FormTab label="Detail" path="detail">
          <Detail account_id={record.id} />
        </FormTab>
        <FormTab label="Ledgers" path="ledgers">
          <Ledger account_id={record.id} label={record.label} />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default EditPage;
