// @ts-nocheck
import React, { useEffect } from "react";
import {
  TabbedShowLayout,
  Tab,
  TextField,
  Show,
  FunctionField,
  WithRecord,
  required,
  usePermissions,
  ReferenceManyField,
  SaveButton,
  Button,
  useNotify,
  SelectInput,
  TextInput,
  Datagrid,
  DateField,
  Pagination,
  useRecordContext,
  useRedirect,
  SimpleForm,
  Create,
  Toolbar,
} from "react-admin";
import ReactJson from "react-json-view";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Button as MaterialButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import WrapTextIcon from "@mui/icons-material/WrapText";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";
import JSONInput from "../JSONInput";
import SyncIcon from "@mui/icons-material/Sync";
import Mbaas from "../../provider/mbaas";
import { useRefresh } from "react-admin";
import Swal from "sweetalert2";

const HistoriesPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
);

const useStyles = makeStyles({
  detailsBox: {
    borderBottom: "solid thin rgba(0, 0, 0, 0.12)",
    paddingBottom: "20px",
  },
  topGrid: {
    borderBottom: "solid thin rgba(0, 0, 0, 0.12)",
    paddingBottom: "20px",
    paddingTop: "40px !important",
  },
  bottomGrid: {
    paddingBottom: "20px",
  },
  histExpTopGrid: {
    borderBottom: "solid thin rgba(0, 0, 0, 0.12)",
  },
  histExpDetailsBox: {
    paddingBottom: "5px",
    paddingTop: "5px !important",
  },
});

const ViewExecLogButton = ({ recordId }) => {
  const navigate = useNavigate();

  return (
    <MaterialButton
      startIcon={<WrapTextIcon />}
      color="primary"
      variant="contained"
      size="small"
      onClick={() =>
        navigate(`/execution_logs?filter={"reference_id":"${recordId}"}`)
      }
    >
      View Execution Logs
    </MaterialButton>
  );
};

const HistoryExpand = ({ record }) => {
  const expandObj = {
    overriden_by: record.override_by_data ? record.override_by_data.name : null,
    previous_supply_snapshot: record.previous_supply_snapshot,
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={1}>
        &nbsp;
      </Grid>
      <Grid item xs={11}>
        <ReactJson
          src={expandObj || []}
          shouldCollapse={false}
          enableClipboard={false}
          displayDataTypes={false}
          displayObjectSize={false}
          name={false}
        />
      </Grid>
    </Grid>
  );
};

const SyncSupplierButton = ({ trxId }) => {
  const refresh = useRefresh();
  const onClickHandler = async () => {
    const { error, data } = await Mbaas.client.invokeApi(
      "sync-transaction-status-on-supplier",
      { trxId }
    );
    console.log({ error, data });
    if (error || data !== "OK")
      Swal.fire(`Request failed: ${error.error}`, "", "error");
    if (data === "OK") {
      refresh();
      Swal.fire("Synchronizing status!", "", "success");
    }
  };
  return (
    <MaterialButton
      startIcon={<SyncIcon />}
      color="primary"
      variant="contained"
      size="small"
      onClick={onClickHandler}
    >
      Sync Status
    </MaterialButton>
  );
};

export const Detail = ({ onCancel, match, ...props }) => {
  console.log({ props });
  const classes = useStyles();

  const { isLoading, permissions } = usePermissions();

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onCancel();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [onCancel]);

  const CustomToolbar = ({ onCancel }) => (
    <Toolbar>
      <Grid item xs={12}>
        <SaveButton data-test-id="Override" label="Override" />
        <Button
          variant="contained"
          color="error"
          style={{
            minWidth: "64px",
            padding: "6px 16px",
            borderRadius: "4px",
            lineHeight: "1.75",
            marginLeft: "14px",
          }}
          label="ra.action.cancel"
          onClick={onCancel}
        >
          <CancelIcon />
        </Button>
      </Grid>
    </Toolbar>
  );

  const Override = ({ onCancel }) => {
    const record = useRecordContext();

    const notify = useNotify();
    const redirect = useRedirect();
    const classes = useStyles();
    const navigate = useNavigate();

    const onSuccess = () => {
      notify("Transaction Has Been Overridden");
      redirect("/transactions");
    };

    return (
      <Create
        resource="override"
        mutationOptions={{
          onSuccess: onSuccess,
        }}
        mutationMode="pessimistic"
      >
        <SimpleForm toolbar={<CustomToolbar onCancel={onCancel} />}>
          <div className={classes.root}>
            <Grid container>
              <Grid item xs={12}>
                <TextInput
                  source="id"
                  fullWidth
                  validate={required()}
                  defaultValue={record.id}
                  disabled
                  className={classes.input}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  data-test-id="totp"
                  source="totp"
                  validate={required()}
                  fullWidth
                  className={classes.input}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  data-test-id="remark"
                  source="remark"
                  validate={required()}
                  fullWidth
                  className={classes.input}
                />
              </Grid>
              <Grid item xs={12}>
                <JSONInput
                  data-test-id="additional_data"
                  source="additional_data"
                />
              </Grid>
              <Grid item xs={12}>
                <SelectInput
                  data-test-id="newStatus"
                  source="newStatus"
                  fullWidth
                  validate={required()}
                  choices={[
                    { id: "SUCCESS", name: "SUCCESS" },
                    { id: "FAILED", name: "FAILED" },
                    { id: "PENDING", name: "PENDING" },
                  ]}
                />
              </Grid>
            </Grid>
          </div>
        </SimpleForm>
      </Create>
    );
  };

  return (
    <Show resource="transactions" title=" &rsaquo; Detail" {...props}>
      <Box width="50vW" ml={2} mr={2}>
        <Stack
          direction="row"
          p={0}
          sx={{ position: "absolute", right: 0, zIndex: 100 }}
        >
          <IconButton onClick={onCancel} size="small">
            <CloseIcon />
          </IconButton>
        </Stack>
        <TabbedShowLayout>
          <Tab label="detail">
            <Grid container spacing={3}>
              <Grid className={classes.topGrid} item xs={1}>
                &nbsp;
              </Grid>
              <Grid className={classes.topGrid} item xs={3}>
                <strong>Order ID</strong>
              </Grid>
              <Grid className={classes.topGrid} item xs={8}>
                <TextField data-test-id="order_id" source="order_id" />
              </Grid>
              <Grid className={classes.detailsBox} item xs={1}>
                &nbsp;
              </Grid>
              <Grid className={classes.detailsBox} item xs={3}>
                <strong>Ref ID</strong>
              </Grid>
              <Grid className={classes.detailsBox} item xs={8}>
                <TextField source="reference_id" />
              </Grid>
              <Grid className={classes.detailsBox} item xs={1}>
                &nbsp;
              </Grid>
              <Grid className={classes.detailsBox} item xs={3}>
                <strong>Account Name</strong>
              </Grid>
              <Grid className={classes.detailsBox} item xs={8}>
                <TextField source="account_id_data.label" />
              </Grid>
              <Grid className={classes.detailsBox} item xs={1}>
                &nbsp;
              </Grid>
              <Grid className={classes.detailsBox} item xs={3}>
                <strong>Product</strong>
              </Grid>
              <Grid className={classes.detailsBox} item xs={8}>
                <TextField source="product_snapshot.description" />
              </Grid>
              <Grid className={classes.detailsBox} item xs={1}>
                &nbsp;
              </Grid>
              <Grid className={classes.detailsBox} item xs={3}>
                <strong>Target Detail</strong>
              </Grid>
              <Grid className={classes.detailsBox} item xs={8}>
                <TextField source="target_detail" />
              </Grid>
              <Grid className={classes.detailsBox} item xs={1}>
                &nbsp;
              </Grid>
              <Grid className={classes.detailsBox} item xs={3}>
                <strong>Selling Price</strong>
              </Grid>
              <Grid className={classes.detailsBox} item xs={8}>
                <WithRecord
                  render={(record) =>
                    `${record.selling_currency} ${record.selling_price}`
                  }
                />
              </Grid>
              <Grid className={classes.detailsBox} item xs={1}>
                &nbsp;
              </Grid>
              <Grid className={classes.detailsBox} item xs={3}>
                <strong>HKD Price</strong>
              </Grid>
              <Grid className={classes.detailsBox} item xs={8}>
                <WithRecord render={(record) => `HKD ${record.hkd_price}`} />
              </Grid>
              <Grid className={classes.detailsBox} item xs={1}>
                &nbsp;
              </Grid>
              <Grid className={classes.detailsBox} item xs={3}>
                <strong>Status</strong>
              </Grid>
              <Grid className={classes.detailsBox} item xs={8}>
                <FunctionField
                  render={(record) => {
                    return record.status.toUpperCase();
                  }}
                />
              </Grid>
              {!isLoading &&
                ["Administrator", "staff"].includes(permissions) && (
                  <>
                    <Grid className={classes.detailsBox} item xs={1}>
                      &nbsp;
                    </Grid>
                    <Grid className={classes.detailsBox} item xs={3}>
                      <strong>Product Snapshot</strong>
                    </Grid>
                    <Grid className={classes.detailsBox} item xs={8}>
                      <FunctionField
                        data-testid="product-snapshot-json"
                        render={(record) => {
                          if (record.product_snapshot) {
                            return (
                              <ReactJson
                                src={record.product_snapshot || []}
                                shouldCollapse={() => {
                                  /* */
                                }}
                                enableClipboard={false}
                                displayDataTypes={false}
                                displayObjectSize={false}
                                name={false}
                              />
                            );
                          } else {
                            return "-";
                          }
                        }}
                      />
                    </Grid>
                    <Grid className={classes.detailsBox} item xs={1}>
                      &nbsp;
                    </Grid>
                    <Grid className={classes.detailsBox} item xs={3}>
                      <strong>Pricing Snapshot</strong>
                    </Grid>
                    <Grid className={classes.detailsBox} item xs={8}>
                      <FunctionField
                        data-testid="pricing-snapshot-json"
                        render={(record) => {
                          return (
                            <ReactJson
                              src={record.pricing_snapshot || []}
                              shouldCollapse={() => {
                                /* */
                              }}
                              enableClipboard={false}
                              displayDataTypes={false}
                              displayObjectSize={false}
                              name={false}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid className={classes.detailsBox} item xs={1}>
                      &nbsp;
                    </Grid>
                    <Grid className={classes.detailsBox} item xs={3}>
                      <strong>Supply Snapshot</strong>
                    </Grid>
                    <Grid className={classes.detailsBox} item xs={8}>
                      <FunctionField
                        data-testid="supply-snapshot-json"
                        render={(record) => {
                          return (
                            <ReactJson
                              src={record.supply_snapshot || []}
                              shouldCollapse={() => {
                                /** */
                              }}
                              enableClipboard={false}
                              displayDataTypes={false}
                              displayObjectSize={false}
                              name={false}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid className={classes.bottomGrid} item xs={1}>
                      &nbsp;
                    </Grid>
                    <Grid className={classes.bottomGrid} item xs={3}>
                      <strong>Conversion Rate Snapshot</strong>
                    </Grid>
                    <Grid className={classes.bottomGrid} item xs={8}>
                      <FunctionField
                        data-testid="conversion-rate-snapshot-json"
                        render={(record) => {
                          return (
                            <ReactJson
                              src={record.conversion_rate_snapshot || []}
                              shouldCollapse={() => {
                                /** */
                              }}
                              enableClipboard={false}
                              displayDataTypes={false}
                              displayObjectSize={false}
                              name={false}
                            />
                          );
                        }}
                      />
                    </Grid>
                  </>
                )}
            </Grid>
          </Tab>
          {!isLoading && ["Administrator", "staff"].includes(permissions) && (
            <Tab label="history" path="history">
              <Box
                display="flex"
                justifyContent="flex-end"
                pt={2}
                style={{ gap: "0.5rem" }}
              >
                <SyncSupplierButton trxId={props.id} />
              </Box>
              <ReferenceManyField
                reference="transaction_histories"
                target="transaction_log_id"
                label=""
                pagination={<HistoriesPagination />}
                perPage={25}
                sort={{ field: "created_at", order: "DESC" }}
              >
                <Datagrid
                  bulkActionButtons={false}
                  expand={HistoryExpand}
                  sx={{
                    ".column-created_at": { width: "20% !important" },
                    ".column-data": { width: "50% !important" },
                  }}
                >
                  <DateField
                    source="created_at"
                    label="Date"
                    showTime
                    locales="en-HK"
                  />
                  <FunctionField
                    source="status"
                    render={(record) => record.status.toUpperCase()}
                  />
                  <FunctionField
                    source="remark"
                    render={(record) => (record.remark ? record.remark : "-")}
                  />
                  <FunctionField
                    source="data"
                    data-testid="history-data-json"
                    render={(record) => {
                      return (
                        <ReactJson
                          src={record.data || []}
                          shouldCollapse={() => {
                            /** */
                          }}
                          enableClipboard={false}
                          displayDataTypes={false}
                          displayObjectSize={false}
                          name={false}
                        />
                      );
                    }}
                  />
                </Datagrid>
              </ReferenceManyField>
            </Tab>
          )}
          {!isLoading && ["Administrator", "staff"].includes(permissions) && (
            <Tab data-test-id="action" label="action">
              <Box
                display="flex"
                justifyContent="flex-end"
                pt={2}
                style={{ gap: "0.5rem" }}
              >
                <ViewExecLogButton recordId={match.params.id} />
              </Box>
              <Override onCancel={onCancel} />
            </Tab>
          )}
        </TabbedShowLayout>
      </Box>
    </Show>
  );
};
