import React from "react";
import { useGetOne, Loading, GetOneParams } from "react-admin";
import mbaas from "../../provider/mbaas";

export default ({ account }: { account: string }) => {
  console.log({ account });
  const {
    data: dataAccount,
    isLoading: loadingAccount,
    error: errorAccount,
  } = useGetOne("account-dashboard", {
    id: mbaas.isProd ? 40 : 3,
    meta: { account },
  } as GetOneParams);
  if (loadingAccount) {
    return <Loading />;
  }
  if (errorAccount) {
    console.log({ error: errorAccount });
  }

  return (
    <>
      {dataAccount && dataAccount.metabaseUrl && (
        <iframe
          title="Overall"
          frameBorder={0}
          src={dataAccount.metabaseUrl}
          width="100%"
          height="100%"
          allowTransparency
        />
      )}
    </>
  );
};
