// @ts-nocheck
import React from "react";
import {
  TextInput,
  SimpleForm,
  required,
  useNotify,
  email,
  Create,
  Button,
  PasswordInput,
  BooleanInput,
  useRedirect,
} from "react-admin";

import Grid from "@mui/material/Grid";
import AceEditor from "react-ace";
import { makeStyles } from "@mui/styles";

const RolesInput = (props) => {
  const [rolesValue, setRolesValue] = useState("");
  const [isChanged, setIsChanged] = useState(false);
  const roles = useInput(props);

  useEffect(() => {
    setRolesValue(JSON.stringify(roles.field.value, null, "\t"));
    setIsChanged(false);
  }, [roles.field.value]);

  function onChange(newValue) {
    setRolesValue(newValue);
    setIsChanged(true);
  }

  function saveToForm() {
    const parsed = JSON.parse(rolesValue);
    roles.field.onChange(parsed);
  }

  return (
    <>
      <AceEditor
        name="roles"
        style={{ height: "200px", width: "100%" }}
        mode="json"
        theme="textmate"
        onChange={onChange}
        value={rolesValue}
      />
      {isChanged && (
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={saveToForm}
        >
          <>Commit Changes</>
        </Button>
      )}
    </>
  );
};

const CreatePage = (props) => {
  const classes = useStyles();
  const validateEmail = [required(), email()];

  // alert for password length
  const minLength =
    (
      min,
      message = "Your password is to short, password must be longer than 7"
    ) =>
    (value) =>
      value && value.length <= min ? message : undefined;

  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify("ra.notification.created", "info", { smart_count: 1 });
    redirect("/users");
  };

  return (
    <Create
      {...props}
      mutationOptions={{
        onSuccess: onSuccess,
      }}
    >
      <SimpleForm>
        <div className={classes.root}>
          <div className={classes.form}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextInput
                  className={classes.input}
                  fullWidth
                  source="email"
                  type="email"
                  validate={validateEmail}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  className={classes.input}
                  fullWidth
                  source="name"
                  validate={[required()]}
                />
              </Grid>
              <Grid item xs={6}>
                <PasswordInput
                  className={classes.input}
                  fullWidth
                  source="password"
                  validate={[required(), minLength(7)]}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  className={classes.input}
                  fullWidth
                  source="twitter"
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  className={classes.input}
                  fullWidth
                  source="facebook"
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  className={classes.input}
                  fullWidth
                  source="google"
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  className={classes.input}
                  fullWidth
                  source="status"
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput className={classes.input} fullWidth source="mfa" />
              </Grid>
              {/* <Grid item xs={6}>
                <TextInput
                  className={classes.input}
                  fullWidth
                  source="account_id"
                />
              </Grid> */}
              <Grid item xs={6}>
                <BooleanInput
                  className={classes.input}
                  fullWidth
                  source="verified"
                />
              </Grid>
              <Grid item xs={6}>
                <BooleanInput
                  className={classes.input}
                  fullWidth
                  source="is_blocked"
                />
              </Grid>
              {/* <Grid item xs={12}>
                <p className={classes.text}>FCM Tokens</p>
                <FcmTokensInput
                  className={classes.input}
                  fullWidth
                  source="fcm_tokens"
                />
              </Grid> */}
              <Grid item xs={12}>
                <p className={classes.text}>Roles</p>
                <RolesInput fullWidth source="roles" />
              </Grid>
            </Grid>
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
  },
  form: {
    flexGrow: 1,
  },
  input: {
    marginBottom: "-20px ",
  },
  text: {
    color: "#0000008A",
  },
  gridResponsive: {},
}));

export default CreatePage;
