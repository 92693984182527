import { DeleteParams, DeleteResult } from "react-admin";
import gql from "graphql-tag";
import mbaas from "../../provider/mbaas";

const DeleteFunction = async (
  resource: string,
  params: DeleteParams<any>
): Promise<DeleteResult<any>> => {
  if (resource === "supply_items") {
    console.log({ resource, params });
    const queryResult = await mbaas.client.gql.mutation({
      mutation: gql`
        mutation ($ids: [UUID!]!) {
          deleteSupplyItemsById(ids: $ids)
        }
      `,
      variables: {
        ids: [`${params.id}`],
      },
    });
    console.log({ data: queryResult?.deleteSupplyItemsById });
    return { data: queryResult?.deleteSupplyItemsById } as DeleteResult;
  }

  if (resource === "suppliers") {
    console.log(77612, { resource, params });
    const queryResult = await mbaas.client.gql.mutation({
      mutation: gql`
        mutation ($ids: [UUID!]!) {
          deleteSuppliersById(ids: $ids)
        }
      `,
      variables: {
        ids: [`${params.id}`],
      },
    });
    console.log({ data: queryResult?.deleteSuppliersById });
    return { data: queryResult?.deleteSuppliersById } as DeleteResult;
  }

  return { data: "resource undefined" };
};

export default DeleteFunction;
