import {
  DataProvider,
  CreateParams,
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
  UpdateParams,
  DeleteParams,
  DeleteResult,
  DeleteManyParams,
  DeleteManyResult,
} from "react-admin";
//
import CreateFunction from "./create-function";
import DeleteFunction from "./delete-function";
import DeleteManyFunction from "./delete-many-function";
import GetListFunction from "./get-list-function";
import GetManyFunction from "./get-many-function";
import GetManyReferenceFunction from "./get-many-reference-function";
import GetOneFunction from "./get-one-function";
import UpdateFunction from "./update-function";

const Data: DataProvider = {
  create: async (resource: string, params: CreateParams) =>
    await CreateFunction(resource, params),
  delete: async (
    resource: string,
    params: DeleteParams<any>
  ): Promise<DeleteResult<any>> => await DeleteFunction(resource, params),
  deleteMany: async (
    resource: string,
    params: DeleteManyParams<any>
  ): Promise<DeleteManyResult<any>> =>
    await DeleteManyFunction(resource, params),
  getList: async (resource: string, params: GetListParams) =>
    await GetListFunction(resource, params),
  getMany: async (resource: string, params: GetManyParams) =>
    await GetManyFunction(resource, params),
  getManyReference: async (resource: string, params: GetManyReferenceParams) =>
    await GetManyReferenceFunction(resource, params),
  getOne: async (resource: string, params: GetOneParams) =>
    await GetOneFunction(resource, params),
  update: async (resource: string, params: UpdateParams) =>
    await UpdateFunction(resource, params),
  updateMany: () => Promise.resolve({}),
};

export default Data;
