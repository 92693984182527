import List from "./ListPage";
import Create from "./CreatePage";
import Edit from "./EditPage";

const productCategories = {
  list: List,
  create: Create,
  edit: Edit,
};

export default productCategories;
