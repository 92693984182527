import { GetManyReferenceParams, GetManyReferenceResult } from "react-admin";
import gql from "graphql-tag";
import mbaas from "../../provider/mbaas";
import { composeFilter } from "@mocobaas/client-js";

const GetManyReferenceFunction = async (
  resource: string,
  params: GetManyReferenceParams
) => {
  console.log("getManyReference", { resource, params });
  const { page, perPage } = params.pagination;
  let { field, order } = params.sort;
  if (resource === "transaction_histories") {
    const where: Record<string, any> = {};
    const filterArray: any[] = [];
    if (params?.target === "transaction_log_id") {
      filterArray.push({
        transaction_log_id: {
          _eq: params.id,
        },
      });
    }
    if (filterArray.length > 0) where._and = filterArray;

    const args = buildArgs({
      // @ts-ignore
      order: order || "DESC",
      size: perPage,
      pageNumber: page,
      sort: field || "created_at",
    });
    const queryResult = await mbaas.client.gql.query({
      query: gql`
          query TransactionHistoriesList($where:TransactionHistoriesWhereInput) {
              allTransactionHistoriesList(where: $where, ${args}){
                data {
                  id
                  created_at
                  transaction_log_id
                  status
                  remark
                  data
                  override_by_data {
                    name
                  }
                  previous_supply_snapshot
                }
                count
              }
          }
        `,
      variables: {
        where,
      },
    });

    return {
      data: queryResult?.allTransactionHistoriesList?.data || [],
      total: queryResult?.allTransactionHistoriesList?.count || 0,
    } as GetManyReferenceResult;
  }
  const result = await mbaas.client
    .getTable(resource)
    .filter(composeFilter().eq(params.target, params.id).toString())
    .read();
  return { data: result.data, total: result.total } as GetManyReferenceResult;
};

interface GqlArgs {
  size?: number;
  pageNumber?: number;
  sort?: string;
  order?: "ASC" | "DESC";
}

function buildArgs(args: GqlArgs): string {
  let argsArray: string[] = [];
  if (args.size) {
    argsArray.push(`limit:${args.size || 25}`);
  }
  if (args.pageNumber) {
    argsArray.push(`offset:${(args.pageNumber - 1) * (args.size || 25)}`);
  }
  if (args.sort) {
    argsArray.push(`sort:${args.sort}`);
  }
  if (args.order) {
    argsArray.push(`order:${args.order}`);
  }

  return argsArray.join(",");
}

export default GetManyReferenceFunction;
