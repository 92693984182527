// @ts-nocheck
import { Drawer, Stack } from "@mui/material";
import * as React from "react";
import {
  Datagrid,
  TextField,
  CreateButton,
  ReferenceField,
  List,
  usePermissions,
  FunctionField,
  useNotify,
} from "react-admin";
//
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import CreatePage from "./CreatePage";
import EditPage from "./EditPage";
import { format } from "date-fns";
import { Empty } from "../../EmptyPage";
//
import mbaas from "../../../provider/mbaas";
//
const ListPage = () => {
  const notify = useNotify();
  const [userData, setUserData] = React.useState({});
  const { isLoading, permissions } = usePermissions();
  const location = useLocation();
  const navigate = useNavigate();

  const getUserData = async () => {
    const res = await mbaas.client.user.get();

    if (res.data) {
      setUserData(res.data);
    }
    if (res.error) {
      // @ts-ignore
      notify(res.error);
    }
  };
  React.useEffect(() => {
    getUserData();
  }, []);

  const ListToolbar = () => (
    <Stack direction="row" justifyContent="flex-end" width="100%">
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {!isLoading && ["Administrator"].includes(permissions) && (
          <CreateButton data-test-id="create-btn" />
        )}
      </div>
    </Stack>
  );

  //

  const handleClose = React.useCallback(() => {
    console.log({ cancel: navigate });
    navigate("/convertion_rates");
  }, [navigate]);

  const matchEdit = matchPath("/convertion_rates/:id", location.pathname);
  const matchCreate = matchPath("/convertion_rates/create", location.pathname);
  //
  return (
    <React.Fragment>
      <List perPage={25} actions={<ListToolbar />} empty={<Empty />}>
        <Datagrid
          bulkActionButtons={false}
          rowClick={() =>
            !isLoading && ["Administrator"].includes(permissions) ? "edit" : ""
          }
        >
          <FunctionField
            label="Created at"
            render={(record: RaRecord) => (
              <div>
                <label>
                  {format(new Date(record.created_at), "dd/MM/yyyy HH:mm:ss")}
                </label>
              </div>
            )}
          />
          <FunctionField
            label="Updated at"
            render={(record: RaRecord) => (
              <div>
                <label>
                  {format(new Date(record.updated_at), "dd/MM/yyyy HH:mm:ss")}
                </label>
              </div>
            )}
          />
          <TextField source="origin" label="Origin" />
          <TextField source="destination" label="Destination" />
          <TextField source="rate" label="rate" />
          <ReferenceField
            source="author"
            reference="users"
            label="Author"
            link={() =>
              !isLoading && ["Administrator"].includes(permissions)
                ? "show"
                : false
            }
          >
            <TextField source="name" />
          </ReferenceField>
        </Datagrid>
      </List>
      <Drawer
        open={
          !!matchCreate || (!!matchEdit && matchEdit?.params?.id !== "create")
        }
        anchor="right"
        onClose={handleClose}
        sx={{ zIndex: 100 }}
      >
        {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
        {!!matchEdit && matchEdit?.params?.id !== "create" && (
          <EditPage id={(matchEdit as any).params.id} userData={userData} />
        )}
        {!!matchCreate && <CreatePage userData={userData} />}
      </Drawer>
    </React.Fragment>
  );
};

export default ListPage;
