import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { PersonAdd as AddUserIcon } from "@mui/icons-material";
import DialogTitle from "@mui/material/DialogTitle";
import { AutocompleteInput, ReferenceInput } from "react-admin";

interface DialogParams {
  open: boolean;
  otpCode: string;
  setOtpCode: React.Dispatch<React.SetStateAction<string>>;
  setUserId: React.Dispatch<React.SetStateAction<string>>;
  handleClickOpen: () => void;
  handleClose: () => void;
  handleAdd: () => void;
}

export default function AddUserDialog({
  open,
  otpCode,
  setUserId,
  setOtpCode,
  handleClickOpen,
  handleClose,
  handleAdd,
}: DialogParams) {
  return (
    <div>
      <Button
        size="small"
        variant="contained"
        color="secondary"
        startIcon={<AddUserIcon />}
        onClick={handleClickOpen}
      >
        New User
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Add New User</DialogTitle>
        <DialogContent>
          <ReferenceInput
            source="email"
            reference="users_without_account"
            label="User"
          >
            <AutocompleteInput
              fullWidth
              size="small"
              source="email"
              optionText="email"
              label="E-mail"
              onChange={(x) => setUserId(x)}
            />
          </ReferenceInput>
          <TextField
            type="number"
            label="OTP 2FA"
            fullWidth
            data-test-id="input-otp"
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ margin: 2 }}
            variant="contained"
            color="error"
            size="small"
            onClick={handleAdd}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
