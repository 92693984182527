import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  ReferenceField,
  AutocompleteInput,
} from "react-admin";

const ReportFilter = (props: any) => {
  const choices = [
    { id: "price_list_batch", name: "price" },
    { id: "product_items_batch", name: "product" },
  ];
  return (
    <Filter {...props}>
      <AutocompleteInput
        source="type"
        optionValue="id"
        optionText="name"
        choices={choices}
        alwaysOn
      />
    </Filter>
  );
};

const ListPage = (props: any) => {
  return (
    <List
      {...props}
      filters={<ReportFilter />}
      sort={{ field: "created_at", order: "DESC" }}
    >
      <Datagrid bulkActionButtons={false}>
        <DateField source="created_at" label="Created" showTime />
        <ReferenceField label="Author" source="author_id" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="filename" />
        <TextField source="status" />
        <TextField source="remark" />
      </Datagrid>
    </List>
  );
};

export default ListPage;
