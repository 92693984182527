// @ts-nocheck
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import mbaas from "../provider/mbaas";
import { useNavigate } from "react-router-dom";

import { useNotify, Notification, useRedirect } from "react-admin";

import google from "../assets/google.png";

export default () => {
  const [showLoading, setShowLoading] = useState(true);
  const [status, setStatus] = useState("Linking your Google account");
  const [executeRedirect, setExecuteRedirect] = useState(false);
  const notify = useNotify();
  const history = useRedirect();

  useEffect(() => {
    history("/");
  }, [executeRedirect]);

  const thisUrl = new URL(window.location.href);
  const googleCode = thisUrl.searchParams.get("code");
  window.history.pushState(null, null, window.location.pathname);
  if (googleCode) {
    (async () => {
      const loginRes = await mbaas.client.auth.login("google", {
        callback: window.location.origin + window.location.pathname,
        code: googleCode,
      });
      // console.log(567, "loginRes", loginRes);
      if (loginRes.error) {
        notify(loginRes.error.title || loginRes.message);
        history("/");
      } else {
        const linkRes = await mbaas.client.user.update("link", {
          provider: "google",
          oauthKey: loginRes.data.oauthKey,
        });
        // console.log(linkRes);
        if (linkRes.error) {
          notify(loginRes.error.title || loginRes.message);
          history("/");
        } else {
          setShowLoading(false);
          setStatus("Google account linked succesfuly");
          // setTimeout(() => history("/"), 3000);
          setTimeout(() => setExecuteRedirect(true), 3000);
        }
      }
    })();
  }

  return (
    <Card style={{ padding: "64px", marginTop: "16px" }}>
      <CardContent>
        <Grid container direction="column" justify="center" alignItems="center">
          <img src={google} alt="account link"></img>
          <h1>{status}</h1>
          {showLoading && <CircularProgress color="secondary" />}
        </Grid>
      </CardContent>
      <Notification />
    </Card>
  );
};
