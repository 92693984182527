import * as React from "react";
import {
  Edit,
  TextInput,
  SimpleForm,
  useRecordContext,
  BooleanInput,
} from "react-admin";
import { Grid, Box } from "@mui/material";
import JSONInput from "../../JSONInput";

const Title = () => {
  const record = useRecordContext();
  return (
    <span data-test-id="edit-title">Edit {record ? record.label : ""}</span>
  );
};

const EditPage = () => {
  return (
    <Edit title={<Title />}>
      <Box display="block" m={2} justifyContent="center" alignItems="center">
        <SimpleForm>
          <Grid container spacing={2}>
            <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
              <TextInput
                data-test-id="input-label"
                source="label"
                label="Name"
                isRequired
                fullWidth
              />
              <JSONInput source="json_metadata" />
              <BooleanInput
                fullWidth
                source="is_available"
                data-test-id="input-is-available"
              />
            </Box>
          </Grid>
        </SimpleForm>
      </Box>
    </Edit>
  );
};

export default EditPage;
