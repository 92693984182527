import { UpdateParams, UpdateResult } from "react-admin";
import mbaas from "../../provider/mbaas";
import { differenceWith, toPairs, isEqual, fromPairs } from "lodash";

const UpdateFunction = async (resource: string, params: UpdateParams) => {
  let updatedData = params.data;
  console.log(789, "updatedData", updatedData);
  //
  if (resource === "generate_account_keys") {
    const { data } = await mbaas.client.invokeApi("save-credentials", {
      pubKey: updatedData.publicKey,
      privKey: updatedData.privateKey,
      accountId: params.id,
    });
    return { data } as UpdateResult;
  }
  //
  if (resource === "configuration") {
    const { data, error } = await mbaas.client.invokeApi(
      "update-configurations",
      {
        token: updatedData.totp,
        configuration_id: params.id,
        author: updatedData.author,
        key: updatedData.key,
        description: updatedData.description,
        value: updatedData.value,
      }
    );

    if (data) {
      const { token, ...newData } = data;
      //

      return { data: newData } as UpdateResult;
    }
    if (error) {
      console.log(error);
      throw new Error(error.error);
    }
  }
  //
  if (resource === "suppliers") {
    const { data, error } = await mbaas.client.invokeApi("update-supplier", {
      supplierId: params.id,
      dataSupplier: {
        name: updatedData.name,
        contact: updatedData.contact,
        description: updatedData.description,
        is_available: updatedData.is_available,
        user_id: updatedData.user_id,
      },
      token: updatedData.totp,
    });

    if (data) {
      const { token, ...newData } = data;
      //

      return { data: newData.dataUpdateSupplier } as UpdateResult;
    }
    if (error) {
      console.log(error);
      throw new Error(error.error);
    }
  }
  //
  if (resource === "convertion_rates") {
    // compare old data with new data and send the new one
    const changes = differenceWith(
      toPairs(params.data),
      toPairs(params.previousData),
      isEqual
    );

    const changes2 = fromPairs(changes);

    const { totp, ...changes2new } = changes2;

    let newUpdatedData = { ...changes2new };

    const { data, error } = await mbaas.client.invokeApi(
      "update-convertion-rate",
      {
        token: updatedData.totp,
        dataConvertionRate: {
          ...newUpdatedData,
        },
        convertion_rate_id: params.id,
      }
    );

    if (data) {
      const { token, ...newData } = data;
      //

      return { data: newData.dataUpdateConvertionRate } as UpdateResult;
    }
    if (error) {
      console.log(error);
      throw new Error(error.error);
    }
  }
  //
  if (resource === "accounts") {
    const { data } = await mbaas.client.getTable("accounts").update(
      Object.assign({
        id: params.id,
        data: {
          label: updatedData.label,
          contact: updatedData.contact,
          transaction_prefix: updatedData.transaction_prefix,
        },
      })
    );
    return { data: data } as UpdateResult;
  }
  //
  if (resource === "users") {
    delete updatedData.metadata;
    delete updatedData.customer_id;
    delete updatedData.fcm_tokens;

    const { type, facebook, google, twitter, password, ...newUpdatedData } =
      updatedData;

    updatedData = newUpdatedData;
  }
  //
  if (resource === "users_blocked") {
    resource = "users";
  }
  if (resource === "product_items") {
    const {
      product_category_id,
      item_key,
      label,
      description,
      type,
      inquiry_rules,
      execution_rules,
      is_available,
      supply_mode,
      country,
    } = params.data;
    updatedData = {
      product_category_id,
      item_key,
      label,
      description,
      type,
      inquiry_rules,
      execution_rules,
      is_available,
      supply_mode,
      country,
    };
  }
  if (resource === "product_categories") {
    const { json_metadata, is_available, label } = params.data;
    updatedData = {
      json_metadata,
      is_available,
      label,
    };
  }

  const { data } = await mbaas.client.getTable(resource).update(
    Object.assign({
      id: params.id,
      data: updatedData,
    })
  );
  return { data: data } as UpdateResult;
};

export default UpdateFunction;
