import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  TopToolbar,
  CreateButton,
  useListContext,
  usePermissions,
  BooleanField,
  TextInput,
  RaRecord,
  FunctionField,
} from "react-admin";
import ReactJson from "react-json-view";
import { Empty } from "../../EmptyPage";

const ListActions = () => {
  const { total, isLoading } = useListContext();
  const { isLoading: isLoadingPermission, permissions } = usePermissions();
  if (
    !isLoading &&
    !isLoadingPermission &&
    (["Administrator"].includes(permissions) ||
      (["ext_admin"].includes(permissions) && total === 0))
  ) {
    return (
      <TopToolbar>
        <CreateButton data-test-id="create-btn" />
      </TopToolbar>
    );
  }
  return null;
};

const Filters = [
  <TextInput source="q" label="Name" data-test-id="filter-label" alwaysOn />,
];

const ListPage = () => {
  return (
    <List
      perPage={25}
      sort={{ field: "label", order: "ASC" }}
      actions={<ListActions />}
      filters={Filters}
      empty={<Empty />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="label" label="Name" />
        <BooleanField source="is_available" />
        <FunctionField
          label="Metadata"
          render={(record: RaRecord) => (
            <div>
              <label>
                <ReactJson
                  src={record.json_metadata || {}}
                  name={false}
                  enableClipboard={false}
                  displayDataTypes={false}
                  displayObjectSize={false}
                />
              </label>
            </div>
          )}
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default ListPage;
