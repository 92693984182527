import { Drawer, Stack } from "@mui/material";
import * as React from "react";
import {
  Datagrid,
  TextField,
  TextInput,
  Pagination,
  RaRecord,
  List,
  FunctionField,
  FilterForm,
} from "react-admin";
//
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import EditPage from "./EditPage";
import ReactJson from "react-json-view";
import { format } from "date-fns";
//
const CutomPagination = (props: any) => (
  <Pagination rowsPerPageOptions={["5", "10", "25", "50", "100"]} {...props} />
);
//
const ListPage = () => {
  const Filters = [
    <TextInput
      source="reference_id"
      label="Reference ID"
      alwaysOn
      data-test-id="search-by-reference-id"
    />,
    <TextInput
      source="type"
      label="Type"
      alwaysOn
      data-test-id="search-by-type"
    />,
  ];
  const ListToolbar = () => (
    <Stack direction="row" justifyContent="space-between" width="100%">
      <FilterForm filters={Filters} />
    </Stack>
  );
  //
  const location = useLocation();
  const navigate = useNavigate();
  //
  const handleClose = React.useCallback(() => {
    console.log({ cancel: navigate });
    navigate("/execution_logs");
  }, [navigate]);
  //
  const matchEdit = matchPath("/execution_logs/:id", location.pathname);
  //
  return (
    <React.Fragment>
      <List
        perPage={50}
        sort={{ field: "created_at", order: "DESC" }}
        actions={<ListToolbar />}
        pagination={<CutomPagination />}
      >
        <Datagrid rowClick="edit" bulkActionButtons={false}>
          <FunctionField
            label="Created at"
            render={(record: RaRecord) => (
              <div>
                <label>
                  {format(new Date(record.created_at), "dd/MM/yyyy HH:mm:ss")}
                </label>
              </div>
            )}
          />
          <FunctionField
            label="Updated at"
            render={(record: RaRecord) => (
              <div>
                <label>
                  {format(new Date(record.updated_at), "dd/MM/yyyy HH:mm:ss")}
                </label>
              </div>
            )}
          />
          <TextField source="type" label="Type" />
          <TextField source="stage" label="Stage" />
          <TextField source="reference_id" label="Reference ID" />
          <TextField source="status" label="Status" />
          <FunctionField
            label="Reference Detail"
            onClick={(e: any) => e.stopPropagation()}
            render={(record: RaRecord) => (
              <div>
                <label>
                  <ReactJson
                    src={record.reference_detail || {}}
                    collapsed={true}
                    name={false}
                    enableClipboard={false}
                    displayDataTypes={false}
                    displayObjectSize={false}
                  />
                </label>
              </div>
            )}
          />
          <FunctionField
            label="Normal Detail"
            onClick={(e: any) => e.stopPropagation()}
            render={(record: RaRecord) => (
              <div>
                <label>
                  <ReactJson
                    src={record.normal_detail || {}}
                    collapsed={true}
                    name={false}
                    enableClipboard={false}
                    displayDataTypes={false}
                    displayObjectSize={false}
                  />
                </label>
              </div>
            )}
          />
          <FunctionField
            label="Exception Detail"
            onClick={(e: any) => e.stopPropagation()}
            render={(record: RaRecord) => (
              <div>
                <label>
                  <ReactJson
                    src={record.exception_detail || {}}
                    collapsed={true}
                    name={false}
                    enableClipboard={false}
                    displayDataTypes={false}
                    displayObjectSize={false}
                  />
                </label>
              </div>
            )}
          />
        </Datagrid>
      </List>
      <Drawer
        open={!!matchEdit && matchEdit?.params?.id !== "create"}
        anchor="right"
        onClose={handleClose}
        sx={{ zIndex: 100 }}
      >
        {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
        {!!matchEdit && matchEdit?.params?.id !== "create" && (
          <EditPage id={(matchEdit as any).params.id} />
        )}
      </Drawer>
    </React.Fragment>
  );
};

export default ListPage;
