import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Add as AddBalanceIcon } from "@mui/icons-material";
import DialogTitle from "@mui/material/DialogTitle";
import { FileInput, FileField, required, Confirm } from "react-admin";
import Swal from "sweetalert2";
import mbaas from "../../provider/mbaas";

interface DialogParams {
  refresh: (loadingMode?: boolean) => Promise<void>;
  account_id: string;
}

export default function AddBalaceDialog({ refresh, account_id }: DialogParams) {
  const [fileUpload, setFileUpload] = React.useState("");
  const [currency, setCurrency] = React.useState("HKD");
  const [amount, setAmount] = React.useState("");
  const [remark, setRemark] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  //
  const handleClickOpen = () => {
    setFileUpload("");
    setAmount("");
    setRemark("");
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleAdd = async () => {
    const response: any = await mbaas.client.storage.upload({
      file: fileUpload,
      params: {
        // @ts-ignore
        filename: `deposit-proof-${account_id}-${new Date().toISOString()}.${fileUpload.name
          .split(".")
          .pop()}`,
        bucket: "deposits",
        signed: false,
        expire: "60",
      },
    });
    //
    const { data, error } = await mbaas.client.invokeApi("topup-deposit", {
      account_id,
      currency,
      amount: Number(amount),
      proof_url: response?.data?.attributes.url,
      remark,
    });

    if (error) {
      Swal.fire(`Request failed: ${error.error}`, "", "error");
    }
    if (data) {
      Swal.fire("Success!", "", "success");
      refresh(true);
    }
    setOpenConfirm(false);
  };

  return (
    <div>
      <Button
        sx={{ marginTop: 2, marginBottom: 2 }}
        size="small"
        variant="contained"
        color="secondary"
        startIcon={<AddBalanceIcon />}
        onClick={handleClickOpen}
      >
        Balance
      </Button>
      <Confirm
        isOpen={openConfirm}
        title="Dear User,"
        content={"Are you sure? This action can not be reverted"}
        onClose={() => setOpenConfirm(false)}
        onConfirm={handleAdd}
      />
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Add Balance</DialogTitle>
        <DialogContent>
          <FileInput
            source="files"
            label="Proof"
            onChange={(e: any) => setFileUpload(e)}
            validate={[required()]}
            options={{ multiple: false }}
          >
            <FileField source="src" title="title" />
          </FileInput>
          <TextField
            required
            type="text"
            label="Currency"
            fullWidth
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
          />
          <TextField
            required
            type="number"
            label="amount"
            fullWidth
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <TextField
            required
            type="text"
            label="remark"
            fullWidth
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={[fileUpload, currency, amount, remark].includes("")}
            sx={{ margin: 2 }}
            variant="contained"
            color="error"
            size="small"
            onClick={() => {
              setOpen(false);
              setOpenConfirm(true);
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
