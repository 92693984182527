import React from "react";
import { Box, Grid } from "@mui/material";
import {
  Create,
  SimpleForm,
  required,
  TextInput,
  BooleanInput,
} from "react-admin";
import JSONInput from "../../JSONInput";

const CreatePage = () => {
  return (
    <Create redirect="/product_categories">
      <Box display="block" m={2} justifyContent="center" alignItems="center">
        <SimpleForm>
          <Grid container spacing={2}>
            <Box flex={2}>
              <TextInput
                data-test-id="input-label"
                fullWidth
                label="Name"
                source="label"
                validate={[required()]}
              />
              <JSONInput source="metadata" />
              <BooleanInput
                fullWidth
                source="is_available"
                data-test-id="input-is-available"
              />
            </Box>
          </Grid>
        </SimpleForm>
      </Box>
    </Create>
  );
};

export default CreatePage;
