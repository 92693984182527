import React from "react";
import { Box, Grid } from "@mui/material";
import {
  Create,
  SimpleForm,
  required,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  usePermissions,
} from "react-admin";

const CreatePage = () => {
  const { isLoading, permissions } = usePermissions();
  return (
    <Create redirect="/suppliers">
      <Box display="block" m={2} justifyContent="center" alignItems="center">
        <SimpleForm>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ReferenceInput source="user_id" reference="users" label="User">
                <SelectInput
                  data-test-id="select-user"
                  source="name"
                  label="User"
                  fullWidth
                />
              </ReferenceInput>
            </Grid>

            <Grid item xs={6}>
              <TextInput
                data-test-id="input-name"
                fullWidth
                label="Name"
                source="name"
                validate={[required()]}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                data-test-id="input-contact"
                fullWidth
                label="Contact"
                source="contact"
                validate={[required()]}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                data-test-id="input-desc"
                fullWidth
                label="Description"
                source="description"
                validate={[required()]}
              />
            </Grid>
            <Grid item xs={6}>
              <BooleanInput
                data-test-id="input-is-available"
                fullWidth
                source="is_available"
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                data-test-id="input-totp"
                fullWidth
                label="Totp"
                source="totp"
                validate={[required()]}
              />
            </Grid>
          </Grid>
        </SimpleForm>
      </Box>
    </Create>
  );
};

export default CreatePage;
