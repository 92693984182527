// @ts-nocheck
import { Drawer, Stack, Paper, Typography } from "@mui/material";
import * as React from "react";
import {
  Datagrid,
  TextField,
  CreateButton,
  ReferenceField,
  List,
  usePermissions,
  FunctionField,
  useNotify,
} from "react-admin";
//
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import CreatePage from "./CreatePage";
import EditPage from "./EditPage";
import { format } from "date-fns";
import ReactJson from "react-json-view";
import mbaas from "../../../provider/mbaas";
import { Empty } from "../../EmptyPage";
//
const ListPage = () => {
  const notify = useNotify();
  const { isLoading, permissions } = usePermissions();
  const location = useLocation();
  const navigate = useNavigate();
  const [userData, setUserData] = React.useState({});

  console.log(999, "userData", userData);

  const getUserData = async () => {
    const res = await mbaas.client.user.get();

    if (res.data) {
      setUserData(res.data);
    }
    if (res.error) {
      // @ts-ignore
      notify(res.error);
    }
  };
  //
  React.useEffect(() => {
    getUserData();
  }, []);

  const ListToolbar = () => (
    <Stack direction="row" justifyContent="flex-end" width="100%">
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <CreateButton data-test-id="create-btn" />
      </div>
    </Stack>
  );

  //

  const handleClose = React.useCallback(() => {
    console.log({ cancel: navigate });
    navigate("/configuration");
  }, [navigate]);

  const matchEdit = matchPath("/configuration/:id", location.pathname);
  const matchCreate = matchPath("/configuration/create", location.pathname);
  //
  return (
    <React.Fragment>
      <List
        perPage={25}
        // sort={{ field: "product_category_id", order: "asc" }}
        actions={<ListToolbar />}
        empty={<Empty />}
      >
        <Datagrid
          bulkActionButtons={false}
          rowClick={() =>
            !isLoading && ["Administrator", "staff"].includes(permissions)
              ? "edit"
              : ""
          }
        >
          <FunctionField
            label="Created at"
            render={(record: RaRecord) => (
              <div>
                <label>
                  {format(new Date(record.created_at), "dd/MM/yyyy HH:mm:ss")}
                </label>
              </div>
            )}
          />
          <FunctionField
            label="Updated at"
            render={(record: RaRecord) => (
              <div>
                <label>
                  {format(new Date(record.updated_at), "dd/MM/yyyy HH:mm:ss")}
                </label>
              </div>
            )}
          />
          <TextField source="key" />
          <TextField source="description" />
          <FunctionField
            label="Value"
            onClick={(e) => e.stopPropagation()}
            render={(record: RaRecord) => (
              <div>
                <label>
                  <ReactJson
                    src={record.value || {}}
                    collapsed={true}
                    name={false}
                    enableClipboard={false}
                    displayDataTypes={false}
                    displayObjectSize={false}
                  />
                </label>
              </div>
            )}
          />
          <ReferenceField source="author" reference="users" label="Author">
            <TextField source="name" />
          </ReferenceField>
          <FunctionField
            label="Metadata"
            onClick={(e) => e.stopPropagation()}
            render={(record: RaRecord) => (
              <div>
                <label>
                  <ReactJson
                    src={record.metadata || {}}
                    collapsed={true}
                    name={false}
                    enableClipboard={false}
                    displayDataTypes={false}
                    displayObjectSize={false}
                  />
                </label>
              </div>
            )}
          />
        </Datagrid>
      </List>
      <Drawer
        open={
          !!matchCreate || (!!matchEdit && matchEdit?.params?.id !== "create")
        }
        anchor="right"
        onClose={handleClose}
        sx={{ zIndex: 100 }}
      >
        {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
        {!!matchEdit && matchEdit?.params?.id !== "create" && (
          <EditPage id={(matchEdit as any).params.id} userData={userData} />
        )}
        {!!matchCreate && <CreatePage userData={userData} />}
      </Drawer>
    </React.Fragment>
  );
};

export default ListPage;
