// @ts-nocheck
import React, { useState } from "react";
import { Toolbar, SaveButton, Button, useNotify } from "react-admin";
import { makeStyles } from "@mui/styles";
import SendIcon from "@mui/icons-material/Send";
import LockResetIcon from "@mui/icons-material/LockReset";
import { SpinnerCircular } from "spinners-react";
import mbaas from "./../provider/mbaas";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { secondsToHms } from "../services/helperFunctions/convertSecondsToHMS";

const useStyles = makeStyles(() => ({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const ResetPassBtn = ({ dataForm }) => {
  const [loading, setLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const notify = useNotify();
  const timerStartIn = 61;
  const [timer, setTimer] = React.useState(timerStartIn);
  const id = React.useRef(null);
  const clear = () => {
    window.clearInterval(id.current);
  };
  // React.useEffect(() => {
  //   id.current = window.setInterval(() => {
  //     setTimer((time) => time - 1);
  //   }, 1000);
  //   return () => clear();
  // }, [loading === true]);

  const timerFunc = () => {
    id.current = window.setInterval(() => {
      setTimer((time) => time - 1);
    }, 1000);
    return () => clear();
  };

  React.useEffect(() => {
    if (timer === 0) {
      clear();
      setTimeout(() => {
        setTimer(timerStartIn);
      }, 1000);
    }
  }, [timer]);

  const handleClick = async () => {
    const isEmailValid = validateEmail(dataForm.email);

    if (dataForm.email) {
      if (isEmailValid) {
        setLoading(true);
        setIsSent(false);
        const res = await mbaas.client.auth.forgotPassword(dataForm.email);

        setTimeout(() => {
          setLoading(false);
          setIsSent(true);
          timerFunc();
        }, 3500);
      } else {
        notify("Please Provide a Valid Email");
      }
    } else {
      notify("Required");
    }
  };

  const validateEmail = (email) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
      // return true;
    } else {
      return false;
    }
    // alert("You have entered an invalid email address!");
    // return false;
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          {timer > 0 && timer < timerStartIn ? (
            <Grid item xs={12}>
              <Typography variant="caption">{`Resend Url Will Available in ...${timer}`}</Typography>
            </Grid>
          ) : (
            ""
          )}

          <Grid item xs={12}>
            <Button
              sx={{
                "& .MuiButton-startIcon": {
                  marginRight: "0px",
                  marginLeft: "0px",
                },
              }}
              onClick={handleClick}
              variant="contained"
              disabled={(timer > 0 && timer < timerStartIn) || loading}
            >
              {loading ? (
                <SpinnerCircular speed={100} enabled={loading} size={25} />
              ) : isSent ? (
                <>
                  Resend Url
                  <LockResetIcon style={{ marginLeft: "5px" }} />
                </>
              ) : (
                <>
                  Send Url
                  <LockResetIcon />
                </>
              )}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const InviteButton = ({ dataForm }) => {
  const [loading, setLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const timerStartIn = 305;
  const [timer, setTimer] = React.useState(timerStartIn);
  const notify = useNotify();
  const id = React.useRef(null);
  const clear = () => {
    window.clearInterval(id.current);
  };

  const timerFunc = () => {
    id.current = window.setInterval(() => {
      setTimer((time) => time - 1);
    }, 1000);
    return () => clear();
  };

  React.useEffect(() => {
    if (timer === 0) {
      clear();
      setTimeout(() => {
        setTimer(timerStartIn);
      }, 1000);
    }
  }, [timer]);

  const handleClick = async () => {
    setLoading(true);
    const { account_id, email, is_blocked, name, roles } = dataForm;
    const res = await mbaas.client.invokeApi(
      "invite-user",
      Object.assign(
        {
          name,
          email,
          role: roles,
          is_blocked,
        },
        account_id ? { account_id } : {}
      )
    );

    if (res.data) {
      notify("Email invitation is sent");
      setTimeout(() => {
        setLoading(false);
        setIsSent(true);
        timerFunc();
      }, 3500);
    }
    if (res.error) {
      setLoading(false);
      notify("User already added");
    }
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          {timer > 0 && timer < timerStartIn ? (
            <Grid item xs={12}>
              <Typography variant="caption">{`Resend Url Will Available in ...${secondsToHms(
                timer
              )}`}</Typography>
            </Grid>
          ) : (
            ""
          )}

          <Grid item xs={12}>
            <Button
              sx={{
                "& .MuiButton-startIcon": {
                  marginRight: "0px",
                  marginLeft: "0px",
                },
              }}
              onClick={handleClick}
              variant="contained"
              disabled={(timer > 0 && timer < timerStartIn) || loading}
            >
              {loading ? (
                <SpinnerCircular speed={100} enabled={loading} size={25} />
              ) : isSent ? (
                <>
                  Resend Invitation
                  <SendIcon style={{ marginLeft: "5px" }} />
                </>
              ) : (
                <>
                  Send Invitation
                  <SendIcon style={{ marginLeft: "5px" }} />
                </>
              )}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const ValidateButton = ({ dataForm, handleClose }) => {
  const notify = useNotify();

  const handleValidate = async () => {
    const { data, error } = await mbaas.client.invokeApi("verify-totp-secret", {
      token: dataForm.totp,
    });
    if (data) {
      notify(data.message);
      handleClose();
    }
    if (error) {
      notify(error.error);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid xs={12} align="center">
        <Button
          sx={{
            "& .MuiButton-startIcon": {
              marginRight: "0px",
              marginLeft: "0px",
            },
          }}
          onClick={handleValidate}
          variant="contained"
          disabled={!dataForm.totp}
        >
          Validate
        </Button>
      </Grid>
    </Grid>
  );
};

export const CustomToolbar = ({
  handleClose,
  modalType,
  dataForm,
  ...props
}) => (
  <Toolbar {...props} classes={useStyles()}>
    {(() => {
      switch (modalType) {
        case "resetPassword":
          return <ResetPassBtn dataForm={dataForm} />;
        case "inviteUser":
          return <InviteButton dataForm={dataForm} />;
        case "TwoFaModal":
          return (
            <ValidateButton handleClose={handleClose} dataForm={dataForm} />
          );
        default:
          <SaveButton />;
      }
    })()}
  </Toolbar>
);
