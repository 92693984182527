import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  ReferenceField,
  FunctionField,
  RaRecord,
  FileField,
  AutocompleteInput,
} from "react-admin";

const ReportFilter = (props: any) => (
  <Filter {...props}>
    <AutocompleteInput
      source="type"
      optionValue="name"
      choices={[
        { name: "product_items" },
        { name: "suppliers" },
        { name: "transaction_entries" },
        { name: "supply_items" },
        ,
      ]}
      alwaysOn
    />
  </Filter>
);

const ListPage = (props: any) => {
  return (
    <List
      {...props}
      filters={<ReportFilter />}
      sort={{ field: "created_at", order: "DESC" }}
    >
      <Datagrid bulkActionButtons={false}>
        <DateField source="created_at" label="Created" showTime />
        <ReferenceField label="Author" source="author_id" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="type" />
        <FunctionField
          label="Status"
          render={(record: RaRecord) =>
            record.url ? (
              <FileField
                source="url"
                title={record.url.split("/").pop()}
                label="Status"
              />
            ) : (
              <label>{record.status.toUpperCase()}</label>
            )
          }
        />
      </Datagrid>
    </List>
  );
};

export default ListPage;
