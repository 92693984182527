import * as React from "react";
import MaterialTable, { Column } from "@material-table/core";
import mbaas from "../../../provider/mbaas";
import gql from "graphql-tag";
import Swal from "sweetalert2";

import { Confirm, useRecordContext } from "react-admin";
import { Paper, TextField, Autocomplete } from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";

import { differenceWith, toPairs, isEqual, fromPairs } from "lodash";

interface IProductPricingItem {
  id: string;
  value: number;
  account_id: string;
  account_label: string;
  account_id_data: { id: string; label: string };
  account_list: { id: string; label: string };
  currency: string;
  method: string;
  product_pricing_id: string;
  is_available: boolean;
}

const ProductPricing = () => {
  const record = useRecordContext();
  const mapData = (rawData: any) => {
    return rawData.map((x: any) =>
      Object.assign({
        product_item_id: record.id,
        ...x,
        product_pricing_id: x.id,
        account_id: x.account_id_data.id,
        account_label: x.account_id_data.label,
      })
    );
  };

  const originData = mapData(record.product_pricings_product_item_id_list.data);
  const [isTableLoading, setIsTableLoading] = React.useState(false);
  const [tableData, setTableData] = React.useState(originData);
  //
  console.log(555, "originData", originData);
  console.log(555, "record", record);
  //
  const [open, setOpen] = React.useState(false);
  const [otpCode, setOtpCode] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [submitConf, setSubmitConf] = React.useState({ method: "", data: {} });
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async () => {
    setIsLoading(true);
    const { error, data } = await mbaas.client.invokeApi(submitConf.method, {
      ...submitConf.data,
      token: otpCode,
    });
    if (error) {
      Swal.fire(`Request failed: ${error.error}`, "", "error");
    }
    if (data) {
      Swal.fire("Success!", "", "success");
      initData();
    }
    setIsLoading(false);
    setOpen(false);
  };
  //

  // initData here
  const initData = async () => {
    setIsTableLoading(true);
    const { getProductItemsById } = await mbaas.client.gql.query({
      query: gql`
        query ProductItem($id: UUID!) {
          getProductItemsById(id: $id) {
            id
            item_key
            label
            description
            product_category_id
            type
            supply_mode
            product_pricings_product_item_id_list {
              data {
                id
                product_item_id
                account_id
                currency
                method
                value
                is_available
                account_id_data {
                  id
                  label
                }
                product_item_id_data {
                  id
                  label
                }
              }
            }
          }
        }
      `,
      variables: {
        id: record.id,
      },
    });
    //
    const mappedData = mapData(
      getProductItemsById.product_pricings_product_item_id_list.data
    );

    setTableData(mappedData);
    setIsTableLoading(false);
  };
  //
  const accountList: any = {};
  record.account_list.forEach((x: { id: string; label: string }) => {
    accountList[x.id] = x.label;
  });

  const optionAccountList = record.account_list.map(
    (x: { id: string; label: string }) => {
      return { label: x.label, id: x.id };
    }
  );

  const optionCurrency = [
    {
      id: "HKD",
      label: "HKD",
    },
    {
      id: "TWD",
      label: "TWD",
    },
    {
      id: "IDR",
      label: "IDR",
    },
  ];
  //
  const columns: Array<Column<IProductPricingItem>> = [
    {
      title: "Value",
      field: "value",
      type: "numeric",
    },
    {
      title: "Account",
      field: "account_id",
      render: (rowData) => rowData.account_label,
      editComponent(props) {
        console.log(234, "props", props);
        return (
          <Autocomplete
            options={record.account_list}
            disablePortal
            id="account-search-input"
            value={
              props.rowData?.account_id === props.rowData?.account_id_data?.id
                ? props.value
                : null
            }
            onChange={(a, b) => props.onChange(b.id)}
            getOptionLabel={(option) =>
              option.label || props.rowData.account_label || ""
            }
            sx={{ width: 250 }}
            renderInput={(params) => <TextField {...params} label="account" />}
          />
        );
      },
    },
    {
      title: "Currency",
      field: "currency",
      editComponent(props) {
        return (
          <Autocomplete
            options={optionCurrency}
            disablePortal
            id="curency-search-input"
            // @ts-ignore
            value={props?.rowData?.currency ? props?.rowData?.currency : null}
            onChange={(a, b) => props.onChange(b?.id)}
            getOptionLabel={(option) =>
              option.label || props.rowData.currency || ""
            }
            sx={{ width: 250 }}
            renderInput={(params) => <TextField {...params} label="currency" />}
          />
        );
      },
    },
    {
      title: "Method",
      field: "method",
      lookup: {
        ABSOLUTE: "ABSOLUTE",
        DYNAMIC_SUPPLIER_BILL: "BILL",
      },
    },
    {
      title: "Available",
      field: "is_available",
      render: (rowData) =>
        rowData.is_available ? <CheckIcon /> : <ClearIcon />,
      type: "boolean",
      lookup: { true: "true", false: "false" },
    },
  ];

  if (!record) return null;
  return (
    <>
      <Confirm
        isOpen={open}
        loading={otpCode.length < 6 ? true : isLoading}
        title="OTP code"
        content={
          <TextField
            type="number"
            fullWidth
            data-test-id="input-otp"
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
          />
        }
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
      <Paper elevation={8}>
        <MaterialTable
          columns={columns}
          data={tableData}
          title={`${record.label}`}
          editable={{
            onRowAdd: async (newData) => {
              console.log(234, newData);
              if (Object.keys(newData).length === 0) {
                Swal.fire("There is no data to be added!", "", "error");
              } else {
                const { account_id, currency, is_available, method, value } =
                  newData;
                //
                setOtpCode("");
                setSubmitConf({
                  method: "add-product-pricing",
                  data: {
                    dataProductPricing: {
                      product_item_id: record.id,
                      account_id: account_id,
                      currency: currency,
                      method: method,
                      value: value,
                      is_available: is_available ? true : false,
                    },
                  },
                });
                setOpen(true);
              }
            },
            onRowUpdate: async (newData, oldData) => {
              console.log(234, {
                oldData,
                newData,
              });
              // compare old data with new data and send the new one
              const changes = differenceWith(
                toPairs(newData),
                toPairs(oldData),
                isEqual
              );
              const changes2 = fromPairs(changes);
              //
              if (changes2.is_available) {
                console.log(234, "hai");
                changes2.is_available =
                  changes2.is_available === "true" ? true : false;
              }
              //
              if (Object.keys(changes2).length === 0) {
                Swal.fire("There is no data to be updated!", "", "error");
              } else {
                setOtpCode("");
                setSubmitConf({
                  method: "update-product-pricing",
                  data: {
                    dataProductPricing: {
                      ...changes2,
                    },
                    product_pricing_id: newData.product_pricing_id,
                  },
                });
                setOpen(true);
              }
            },
            onRowDelete: async (oldData) => {
              console.log({ oldData });
              setOtpCode("");
              setSubmitConf({
                method: "delete-product-pricing",
                data: {
                  product_pricing_id: oldData.product_pricing_id,
                },
              });
              setOpen(true);
            },
          }}
        />
      </Paper>
    </>
  );
};

export default ProductPricing;
