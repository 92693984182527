import { useEffect, useState } from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import {
  AppBar,
  Layout,
  Logout,
  UserMenu,
  LayoutProps,
  useAuthProvider,
  usePermissions,
  useNotify,
} from "react-admin";
import Menu from "./Menu";
import { ListItemIcon, MenuItem, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { manageProfile } from "./../services/manageProfile";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import TwoFaModal from "./../component/twoFA-modal";
import mbaas from "../provider/mbaas";

const ManageProfile = () => {
  const authP = useAuthProvider();
  return (
    <MenuItem onClick={() => manageProfile(authP.data())}>
      <ListItemIcon style={{ minWidth: "40px" }}>
        <AccountBoxIcon />
      </ListItemIcon>
      Manage Profile
    </MenuItem>
  );
};

const MyUserMenu = () => (
  <UserMenu>
    <Logout />
    <ManageProfile />
  </UserMenu>
);

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
});

const MyAppBar = (props: any) => {
  const classes = useStyles();
  const authP = useAuthProvider();
  const [isHaveMfa, setIsHaveMfa] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const notify = useNotify();
  const email = localStorage.getItem("username");
  const { isLoading: isLoadingPermission, permissions } = usePermissions();

  const executeFunction = ["Administrator", "staff"].includes(permissions);

  useEffect(() => {
    if (executeFunction) {
      checkUserHaveMfa();
    }
  }, [executeFunction]);

  const checkUserHaveMfa = async () => {
    try {
      const userData = await authP.data();
      if (userData) {
        if (!userData.mfa) {
          setIsHaveMfa(false);
          handleOpen();
          const { data: regenerateData, error: regenerateError } =
            await mbaas.client.invokeApi("regenerate-totp-secret");
          if (regenerateError) {
            throw new Error(regenerateError);
          }
          //
          setQrCode(regenerateData);
        } else {
          setIsHaveMfa(true);
        }
      }
    } catch (error) {
      notify(error as string);
    }
  };
  return (
    <>
      <AppBar {...props} userMenu={<MyUserMenu />}>
        {" "}
        <Typography
          variant="h6"
          color="inherit"
          id="react-admin-title"
          className={classes.title}
        />
        <span className={classes.spacer} />
        <div>
          <span data-test-id="logged-in-user">{email}</span>
        </div>
      </AppBar>
      {!isHaveMfa &&
      !isLoadingPermission &&
      (["Administrator"].includes(permissions) ||
        ["staff"].includes(permissions)) ? (
        <TwoFaModal
          modalType="TwoFaModal"
          qrCode={qrCode}
          open={open}
          handleClose={handleClose}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default (props: LayoutProps) => (
  <>
    <Layout {...props} appBar={MyAppBar} menu={Menu} />
    <ReactQueryDevtools
      initialIsOpen={false}
      toggleButtonProps={{ style: { width: 20, height: 30 } }}
    />
  </>
);
