import React from "react";
import { Box, Grid } from "@mui/material";
import {
  Create,
  SimpleForm,
  required,
  TextInput,
  ReferenceInput,
  SelectInput,
  usePermissions,
} from "react-admin";

const CreatePage = () => {
  const { isLoading, permissions } = usePermissions();

  const validatePrefix = (value: any) => {
    if (!value) {
      return "prefix is required";
    }
    if (value.length > 3) {
      return "prefix must have 3 characters";
    }
    if (value.length < 3) {
      return "prefix must have 3 characters";
    }
  };

  const prefixValidation: any = [required(), validatePrefix];

  return (
    <Create redirect="/accounts">
      <Box display="block" m={2} justifyContent="center" alignItems="center">
        <SimpleForm>
          <Grid container spacing={2}>
            <Box flex={2}>
              {!isLoading && ["Administrator"].includes(permissions) && (
                <ReferenceInput
                  source="id"
                  reference="users_ext_admin"
                  label="User"
                >
                  <SelectInput
                    data-test-id="select-user"
                    source="name"
                    label="User"
                    fullWidth
                  />
                </ReferenceInput>
              )}
              <TextInput
                data-test-id="input-label"
                fullWidth
                label="Label"
                source="label"
                validate={[required()]}
              />
              <TextInput
                data-test-id="input-contact"
                fullWidth
                label="Contact"
                source="contact"
                validate={[required()]}
              />
              <TextInput
                data-test-id="input-transaction-prefix"
                fullWidth
                label="Transcation Prefix"
                source="transaction_prefix"
                onChange={(e) => validatePrefix(e.target.value)}
                validate={prefixValidation}
              />
            </Box>
          </Grid>
        </SimpleForm>
      </Box>
    </Create>
  );
};

export default CreatePage;
