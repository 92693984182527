// @ts-nocheck
import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { SimpleForm, FormDataConsumer, TextInput, required } from "react-admin";

import { CustomToolbar } from "./CustomToolbar";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "7px",
  boxShadow: 24,
  p: 4,
};

type compProps = {
  open: boolean;
  handleClose: any;
  modalType: string;
  qrCode: any;
};

export default function TwoFaModal({
  open,
  handleClose,
  modalType,
  qrCode,
}: compProps) {
  const classes = useStyles();
  const [dataForm, setDataForm] = React.useState([]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            handleClose();
          }
        }}
        closeAfterTransition
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography variant="h6" gutterBottom component="div">
              Please Scan the QR Code Below
            </Typography>
            <SimpleForm
              toolbar={
                <CustomToolbar
                  modalType={modalType}
                  handleClose={handleClose}
                  dataForm={dataForm}
                />
              }
            >
              <Grid container spacing={1} justify="center">
                <Grid item xs={12} align="center">
                  <img src={qrCode} title="qrcode" />
                </Grid>
                <Grid item xs={12} align="center">
                  <TextInput
                    className={classes.input}
                    source="totp"
                    validate={[required()]}
                  />
                </Grid>
                <FormDataConsumer style={{ width: "100%" }}>
                  {({ formData, ...rest }) => {
                    // console.log(999, "formData", formData);
                    setDataForm(formData);
                  }}
                </FormDataConsumer>
              </Grid>
            </SimpleForm>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  form: {
    alignItems: "center",
  },
  input: {
    marginBottom: "-20px ",
  },
  text: {
    color: "#0000008A",
  },
  backDrop: {
    background: "rgba(255,255,255,0.2)",
  },
}));
