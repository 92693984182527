import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  CreateProps,
  NumberInput,
  useRefresh,
  required,
} from "react-admin";

const CreatePage = ({ ...props }: CreateProps) => {
  const refresh = useRefresh();
  const onSettled = () => {
    refresh();
  };
  //
  return (
    <Create
      title={<></>}
      redirect="/convertion_rates"
      {...props}
      mutationOptions={{ onSettled }}
    >
      <Box
        display="block"
        ml={2}
        mr={2}
        justifyContent="center"
        alignItems="center"
        width="50vW"
      >
        <Stack direction="row" pr={1}>
          <Typography variant="h6" flex="1">
            Create New Convertion Rates
          </Typography>
        </Stack>
        <SimpleForm>
          <Grid container spacing={2}>
            <Box flex={2}>
              <div style={{ display: "none" }}>
                <TextInput
                  source="author"
                  // @ts-ignore
                  value={props.userData?.id}
                  // @ts-ignore
                  defaultValue={props.userData?.id}
                  validate={[required()]}
                  data-test-id="key"
                  fullWidth
                  disabled={true}
                />
              </div>

              <SelectInput
                source="origin"
                data-test-id="origin"
                validate={[required()]}
                fullWidth
                choices={[
                  { id: "HKD", name: "HKD" },
                  { id: "IDR", name: "IDR" },
                  { id: "TWD", name: "TWD" },
                  { id: "MYR", name: "MYR" },
                  { id: "USD", name: "USD" },
                ]}
              />
              <SelectInput
                source="destination"
                data-test-id="destination"
                validate={[required()]}
                fullWidth
                choices={[
                  { id: "HKD", name: "HKD" },
                  { id: "IDR", name: "IDR" },
                  { id: "TWD", name: "TWD" },
                  { id: "MYR", name: "MYR" },
                  { id: "USD", name: "USD" },
                ]}
              />
              <NumberInput
                source="rate"
                validate={[required()]}
                data-test-id="rate"
                fullWidth
              />
              <TextInput
                source="totp"
                validate={[required()]}
                data-test-id="totp"
                fullWidth
              />
            </Box>
          </Grid>
        </SimpleForm>
      </Box>
    </Create>
  );
};

export default CreatePage;
