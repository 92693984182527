// @ts-nocheck
import * as React from "react";
import { useState, useEffect } from "react";
import {
  useLogin,
  useNotify,
  Notification,
  useAuthState,
  Loading,
} from "react-admin";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Grid from "@mui/material/Grid";
import { useNavigate, useSearchParams } from "react-router-dom";
import mbaas from "../provider/mbaas";
import { FormHelperText } from "@mui/material";
import { SpinnerCircular } from "spinners-react";

export default function ResetPassword() {
  const { isLoading, authenticated } = useAuthState();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState("");
  const [validatePasswordLength, setValidatePasswordLength] = useState(false);
  const [isPasswordSame, setIsPasswordSame] = useState(false);
  const [messageIsShown, setMessageIsShown] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [isError, setIsError] = useState({
    confirmPassword: false,
    password: false,
    req: false,
    msg: "",
  });
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const login = useLogin();
  const notify = useNotify();

  useEffect(() => {
    validatePasswordwithConfirmPassword(password, confirmPassword);
    validatePasswordLengthFunc(password);
  }, [password, confirmPassword]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password === "" && confirmPassword === "") {
      setIsError({ confirmPassword: true, password: true, msg: "required" });
    }

    if (password) {
      setIsError({ confirmPassword: false, password: false });
      if (validatePasswordLength) {
        setMessageIsShown(false);
        if (isPasswordSame) {
          setLoading(true);
          const { data, error, message } =
            await mbaas.client.auth.resetPassword(
              searchParams.get("token"),
              password
            );
          if (data) {
            setLoading(false);
            setIsError({
              confirmPassword: false,
              password: false,
              req: true,
              msg: "Password successfully reseted",
              color: "#00a152",
            });
            setTimeout(() => {
              navigate("/login");
            }, 3500);
          }
          if (error) {
            setLoading(false);
            setIsError({
              confirmPassword: false,
              password: false,
              req: true,
              msg: error.title,
              color: "#EB5757",
            });
          }
        } else {
          setIsError({
            confirmPassword: true,
            password: false,
            msg: "make sure the password is match",
            color: "#EB5757",
          });
        }
      } else {
        setIsError({
          confirmPassword: false,
          password: true,
          msg: "Password must be longer than 7 characters",
          color: "#EB5757",
        });
      }
    }
  };

  const validatePasswordwithConfirmPassword = (password, confirmPassword) => {
    if (password === confirmPassword) {
      setIsPasswordSame(true);
    } else {
      setIsPasswordSame(false);
    }
  };

  const validatePasswordLengthFunc = (password) => {
    console.log("pas-fun", password);
    if (password.length <= 7) {
      setValidatePasswordLength(false);
    } else {
      setValidatePasswordLength(true);
    }
  };

  if (isLoading) {
    return <Loading />;
  }
  // if (authenticated) {
  //   return <div>authenticated</div>;
  // } else {}
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundImage:
          "radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "300px",
          background: "white",
          padding: "8px",
          borderRadius: "5px",
          marginTop: "-150px",
        }}
      >
        <Box
          sx={{
            marginTop: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar style={{ margin: "auto" }}>
            <LockOpenIcon />
          </Avatar>
          <div style={{ width: "100%" }}>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                error={isError.password}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                // autoComplete="current-password"
                value={password}
                onChange={(e) => {
                  isError.email &&
                    setIsError((old) => ({ ...old, email: false }));
                  setPassword(e.target.value);
                }}
                // helperText={isError.password && "Required"}
              />

              {isError.password && (
                <FormHelperText style={{ color: isError.color }}>
                  {isError.msg}
                </FormHelperText>
              )}

              <TextField
                error={isError.confirmPassword}
                margin="normal"
                required
                fullWidth
                name="confirm password"
                label="Confirm Password"
                type="password"
                id="confirm-password"
                value={confirmPassword}
                onChange={(e) => {
                  isError.email &&
                    setIsError((old) => ({ ...old, email: false }));
                  setConfirmPassword(e.target.value);
                }}
                // helperText={isError.password && "Required"}
              />

              {isError.confirmPassword && (
                <FormHelperText style={{ color: isError.color }}>
                  {isError.msg}
                </FormHelperText>
              )}

              {isError.req && (
                <FormHelperText style={{ color: isError.color }}>
                  {isError.msg}
                </FormHelperText>
              )}

              <Grid item>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "8px" }}
                  disabled={false}
                >
                  {loading ? (
                    <SpinnerCircular speed={100} enabled={loading} size={25} />
                  ) : (
                    "Reset Password"
                  )}
                </Button>
              </Grid>
            </Box>
          </div>
        </Box>
      </div>

      <Notification />
    </div>
  );
}
