// @ts-nocheck
import React from "react";
import { useGetOne, Loading } from "react-admin";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import mbaas from "../../provider/mbaas";

export default () => {
  const [value, setValue] = React.useState("1");
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const {
    data: dataOverall,
    isLoading: loadingOverall,
    error: errorOverall,
  } = useGetOne("overall-dashboard", { id: mbaas.isProd ? 39 : 2 });
  const {
    data: dataAccount,
    isLoading: loadingAccount,
    error: errorAccount,
  } = useGetOne("account-dashboard", { id: mbaas.isProd ? 37 : 1 });
  if (loadingOverall || loadingAccount) {
    return <Loading />;
  }
  if (errorAccount || errorOverall) {
    console.log({ error: errorAccount || errorOverall });
  }

  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          <Tab label="Overall" value="1" />
          <Tab label="Accounts" value="2" />
        </TabList>
      </Box>
      <TabPanel value="1" style={{ height: "100vh", padding: "0px" }}>
        {dataOverall && dataOverall.metabaseUrl && (
          <iframe
            frameBorder={0}
            src={dataOverall.metabaseUrl}
            width="100%"
            height="100%"
            allowTransparency
          />
        )}
      </TabPanel>
      <TabPanel value="2" style={{ height: "100vh", padding: "0px" }}>
        {dataAccount && dataAccount.metabaseUrl && (
          <iframe
            frameBorder={0}
            src={dataAccount.metabaseUrl}
            width="100%"
            height="100%"
            allowTransparency
          />
        )}
      </TabPanel>
    </TabContext>
  );
};
