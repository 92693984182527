import { DeleteManyParams, DeleteManyResult } from "react-admin";

import gql from "graphql-tag";
import mbaas from "../../provider/mbaas";

const DeleteManyFunction = async (
  resource: string,
  params: DeleteManyParams<any>
): Promise<DeleteManyResult<any>> => {
  if (resource === "supply_items") {
    console.log({ resource, params });
    const queryResult = await mbaas.client.gql.mutation({
      mutation: gql`
        mutation ($ids: [UUID!]!) {
          deleteSupplyItemsById(ids: $ids)
        }
      `,
      variables: {
        ids: params.ids,
      },
    });
    console.log({ data: queryResult?.deleteSupplyItemsById });
    return { data: params.ids } as DeleteManyResult;
  }
  return { data: ["resource undefined"] };
};

export default DeleteManyFunction;
