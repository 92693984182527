// @ts-nocheck
import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  TopToolbar,
  CreateButton,
  useListContext,
  useRecordContext,
  EditButton,
  usePermissions,
  BooleanField,
  Button,
  Filter,
  useCreate,
  TextInput,
  Confirm,
  ReferenceField,
} from "react-admin";
//
import GetAppIcon from "@mui/icons-material/GetApp";
import { Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import mbaas from "../../provider/mbaas";
import { format, endOfToday } from "date-fns";
//

const ListActions = () => {
  const { total, isLoading } = useListContext();
  const { isLoading: isLoadingPermission, permissions } = usePermissions();
  const [userData, setUserData] = React.useState({});
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const getUserData = async () => {
    const res = await mbaas.client.user.get();
    if (res.data) {
      setUserData(res.data);
    }
    if (res.error) {
      // @ts-ignore
      notify(res.error);
    }
  };
  React.useEffect(() => {
    getUserData();
  }, []);

  const ProductItemsExportButton = () => {
    const { displayedFilters, filterValues, showFilter } = useListContext();

    console.log(656, "filterValues", filterValues);

    const type_name = "suppliers";
    const gen_file_name = `${type_name}_${format(
      new Date(endOfToday()),
      "yyyy-MM-dd"
    )}`;

    const [exportData, { data, isLoading, error }] = useCreate("reports", {
      data: {
        type: type_name,
        author_id: userData?.id,
        metadata: {
          begin: "2022-06-02",
          end: format(new Date(endOfToday()), "yyyy-MM-dd"),
          generated_filename: gen_file_name,
          name: filterValues.name,
        },
      },
    });
    //
    if (data && !error) {
      //
      console.log(767, "haiii");
      // navigate(encodeURI(`/reports?filter={"type":"suppliers"}`));
    }

    const handleOnClick = () => {
      exportData();
      setOpen(true);
    };

    const navigateToReportPage = () => {
      navigate(encodeURI(`/reports?filter={"type":"suppliers"}`));
    };
    const ConfirmDialogContent = () => {
      return (
        <Paper elevation={0}>
          <Typography variant="body1" gutterBottom>
            Your file is exported as "{gen_file_name}.csv", to view the progress
            please visit report page below
          </Typography>
        </Paper>
      );
    };

    return (
      <Button label="Export Suppliers Data" onClick={() => handleOnClick()}>
        <GetAppIcon />
        <Confirm
          isOpen={open}
          title="Dear User,"
          content={<ConfirmDialogContent />}
          onClose={() => setOpen(false)}
          confirm="Go To Report"
          onConfirm={navigateToReportPage}
        />
      </Button>
    );
  };

  if (
    !isLoading &&
    !isLoadingPermission &&
    ["Administrator"].includes(permissions)
  ) {
    return (
      <TopToolbar>
        <CreateButton data-test-id="create-btn" />
        <ProductItemsExportButton />
      </TopToolbar>
    );
  }
  return null;
};

const SupplierFilter = (props: any) => (
  <Filter {...props}>
    <TextInput
      data-test-id="search-by-name"
      source="name"
      label="name"
      alwaysOn
    />
  </Filter>
);

const ListPage = () => {
  const record = useRecordContext();
  return (
    <List
      perPage={25}
      sort={{ field: "created_at", order: "desc" }}
      actions={<ListActions />}
      filters={<SupplierFilter />}
    >
      <Datagrid bulkActionButtons={false}>
        <ReferenceField label="User" source="user_id" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="name" />
        <TextField source="contact" />
        <TextField source="description" />
        <BooleanField source="is_available" label="Available" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default ListPage;
