import { init, composeFilter } from "@mocobaas/client-js";
import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import { EncryptionOptions } from "@mocobaas/client-js/build/PayloadEncryption";

class Mbaas {
  beURL: string;
  storageURL: string;
  host: string;
  mbaasClient: MobileServiceClient;

  constructor() {
    this.host = window.location.host;
    // BE
    const beProd = "https://vas.live.telinhk.xyz";
    const beTest = "https://vas.develop.telinhk.xyz";
    // Storage
    const storageProd = "https://object.live.telinhk.xyz";
    const storageTest = "https://minio.develop.telinhk.xyz";
    //
    let activeBEURL: string = beTest,
      activeStorageURL: string = storageTest,
      enforcement: EncryptionOptions["enforcement"] = [];
    if (this.host === "vas-console.pages.dev") {
      activeBEURL = beProd;
      activeStorageURL = storageProd;
    }
    //
    this.beURL = activeBEURL;
    this.storageURL = activeStorageURL;
    this.mbaasClient = new init(activeBEURL, {
      payloadEncryption: {
        enforcement,
      },
    });
  }

  get appURL() {
    return this.host;
  }

  get isProd() {
    return this.host === "vas-console.pages.dev";
  }

  get client() {
    return this.mbaasClient;
  }

  get composeFilter() {
    return composeFilter();
  }
}

export default new Mbaas();
