// @ts-nocheck
import * as React from "react";
import { useState, useEffect } from "react";
import {
  useLogin,
  useNotify,
  Notification,
  defaultTheme,
  useAuthState,
  Loading,
} from "react-admin";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Grid from "@mui/material/Grid";
import { FormHelperText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import mbaas from "../provider/mbaas";
import { SpinnerCircular } from "spinners-react";
import Typography from "@mui/material/Typography";

export default function ForgotPassword() {
  const { isLoading, authenticated } = useAuthState();

  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState({ email: false, msg: "" });
  const [isSent, setIsSent] = useState(false);
  const timerStartIn = 61;
  const [timer, setTimer] = React.useState(timerStartIn);
  const id = React.useRef(null);
  const clear = () => {
    window.clearInterval(id.current);
  };
  const history = useNavigate();
  const login = useLogin();
  const notify = useNotify();

  const timerFunc = () => {
    id.current = window.setInterval(() => {
      setTimer((time) => time - 1);
    }, 1000);
    return () => clear();
  };

  React.useEffect(() => {
    if (timer === 0) {
      clear();
      setTimeout(() => {
        setTimer(timerStartIn);
      }, 1000);
    }
  }, [timer]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (email) {
      if (isEmailValid) {
        setLoading(true);
        setIsSent(false);
        const res = await mbaas.client.auth.forgotPassword(email);

        setTimeout(() => {
          notify("Reset password url has been successfully sent");
        }, 2500);
        setTimeout(() => {
          setLoading(false);
          setIsSent(true);
          timerFunc();
        }, 3500);
      } else {
        setIsError({ email: true, msg: "Please Provide a Valid Email" });
      }
    } else {
      setIsError({ email: true, msg: "Required" });
    }
  };

  useEffect(() => {
    validateEmail(email);
  }, [email]);

  const validateEmail = (email) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setIsEmailValid(true);
      // return true;
    } else {
      setIsEmailValid(false);
    }
    // alert("You have entered an invalid email address!");
    // return false;
  };

  // if (isLoading) {
  //   return <Loading />;
  // }
  // console.log(777, "authenticated", authenticated);
  // if (authenticated) {
  //   return <></>;
  // }
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundImage:
          "radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "300px",
          background: "white",
          padding: "8px",
          borderRadius: "5px",
          marginTop: "-150px",
        }}
      >
        <Box
          sx={{
            marginTop: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar style={{ margin: "auto" }}>
            <LockOpenIcon />
          </Avatar>
          <div style={{ width: "100%" }}>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                data-test-id="input-email"
                error={isError.email}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => {
                  isError.email &&
                    setIsError((old) => ({ ...old, email: false }));
                  setEmail(e.target.value);
                }}
                // helperText={isError.email && "Required"}
              />

              {timer > 0 && timer < timerStartIn ? (
                <Grid item xs={12}>
                  <Typography variant="caption">{`Resend Url Will Available in ...${timer}`}</Typography>
                </Grid>
              ) : (
                ""
              )}

              {isError.email && (
                <FormHelperText style={{ color: "#EB5757" }}>
                  {isError.msg}
                </FormHelperText>
              )}

              {/* {isSent && (
                <FormHelperText style={{ color: "#4caf50" }}>
                  Reset password url has been successfully sent
                </FormHelperText>
              )} */}

              <Grid item>
                <Button
                  data-test-id="send-link-btn"
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "8px" }}
                  disabled={(timer > 0 && timer < timerStartIn) || loading}
                >
                  {loading ? (
                    <SpinnerCircular speed={100} enabled={loading} size={25} />
                  ) : isSent ? (
                    "Resend Link"
                  ) : (
                    "Send Link"
                  )}
                </Button>
              </Grid>
            </Box>
          </div>
        </Box>
      </div>

      <Notification />
    </div>
  );
}
