import List from "./ListPage";
import Create from "./CreatePage";
import Edit from "./EditPage";

const account = {
  list: List,
  create: Create,
  edit: Edit,
};

export default account;
