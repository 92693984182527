// @ts-nocheck
import React, { useState, useEffect } from "react";
import {
  Create,
  TextInput,
  SimpleForm,
  required,
  NumberInput,
  BooleanInput,
  useInput,
  Button,
} from "react-admin";
import AceEditor from "react-ace";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ToolbarCreate } from "./ToolbarCreate";

const SupplyItemCreate = ({ onCancel, supplierId, ...props }) => {
  return (
    <Create resource="supply_items" {...props}>
      <Box pt={5} width={{ xs: "100vW", sm: 600 }} mt={{ xs: 2, sm: 1 }}>
        <Stack direction="row" p={2}>
          <Typography variant="h6" flex="1">
            Add New Item
          </Typography>
          <IconButton onClick={onCancel} size="small">
            <CloseIcon />
          </IconButton>
        </Stack>
        <SimpleForm toolbar={<ToolbarCreate supplierId={supplierId} />}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextInput
                fullWidth
                source="label"
                data-test-id="input-label"
                validate={required()}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                fullWidth
                source="description"
                data-test-id="input-description"
                validate={required()}
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                fullWidth
                source="origin_currency"
                label="Origin Currency"
                data-test-id="input-origin-currency"
                validate={required()}
              />
            </Grid>
            <Grid item xs={4}>
              <NumberInput
                fullWidth
                source="origin_cost"
                label="Origin Cost"
                data-test-id="input-origin-cost"
                validate={required()}
              />
            </Grid>
            <Grid item xs={4}>
              <NumberInput
                fullWidth
                source="hkd_cost"
                label="HK$ Cost"
                data-test-id="input-hkd-cost"
                validate={required()}
              />
            </Grid>
            <Grid item xs={12}>
              <p>Inquiry Rules</p>
              <InquiryRulesInput source="inquiry_rules" />
            </Grid>
            <Grid item xs={12}>
              <p>Execution Rules</p>
              <ExecutionRulesInput source="execution_rules" />
            </Grid>
            <Grid item xs={12}>
              <p>Convertion Detail</p>
              <ConvertionDetailInput source="convertion_detail" />
            </Grid>
            <Grid item xs={12}>
              <BooleanInput
                fullWidth
                source="is_available"
                label="Is Available?"
                data-test-id="input-is-available"
                validate={required()}
              />
            </Grid>
          </Grid>
        </SimpleForm>
      </Box>
    </Create>
  );
};

const InquiryRulesInput = (props) => {
  const [inquiryRulesValue, setInquiryRulesValue] = useState("");
  const [isChanged, setIsChanged] = useState(false);
  const inquiryRules = useInput(props);

  useEffect(() => {
    setInquiryRulesValue(JSON.stringify(inquiryRules.field.value, null, "\t"));
    setIsChanged(false);
  }, [inquiryRules.field.value]);

  function onChange(newValue) {
    setInquiryRulesValue(newValue);
    setIsChanged(true);
  }

  function saveToForm() {
    const parsed = JSON.parse(inquiryRulesValue);
    inquiryRules.field.onChange(parsed);
  }

  return (
    <div>
      <AceEditor
        name="inquiryRules"
        style={{ height: "200px", width: "100%" }}
        mode="json"
        theme="textmate"
        onChange={onChange}
        value={inquiryRulesValue}
      />
      {isChanged && (
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={saveToForm}
        >
          <>Commit Changes</>
        </Button>
      )}
    </div>
  );
};

const ExecutionRulesInput = (props) => {
  const [executionRulesValue, setExecutionRulesValue] = useState("");
  const [isChanged, setIsChanged] = useState(false);
  const executionRules = useInput(props);

  useEffect(() => {
    setExecutionRulesValue(
      JSON.stringify(executionRules.field.value, null, "\t")
    );
    setIsChanged(false);
  }, [executionRules.field.value]);

  function onChange(newValue) {
    setExecutionRulesValue(newValue);
    setIsChanged(true);
  }

  function saveToForm() {
    const parsed = JSON.parse(executionRulesValue);
    executionRules.field.onChange(parsed);
  }

  return (
    <div>
      <AceEditor
        name="executionRules"
        style={{ height: "200px", width: "100%" }}
        mode="json"
        theme="textmate"
        onChange={onChange}
        value={executionRulesValue}
      />
      {isChanged && (
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={saveToForm}
        >
          <>Commit Changes</>
        </Button>
      )}
    </div>
  );
};

const ConvertionDetailInput = (props) => {
  const [convertionDetailValue, setConvertionDetailValue] = useState("");
  const [isChanged, setIsChanged] = useState(false);
  const convertionDetail = useInput(props);

  useEffect(() => {
    setConvertionDetailValue(
      JSON.stringify(convertionDetail.field.value, null, "\t")
    );
    setIsChanged(false);
  }, [convertionDetail.field.value]);

  function onChange(newValue) {
    setConvertionDetailValue(newValue);
    setIsChanged(true);
  }

  function saveToForm() {
    const parsed = JSON.parse(convertionDetailValue);
    convertionDetail.field.onChange(parsed);
  }

  return (
    <div>
      <AceEditor
        name="convertionDetail"
        style={{ height: "200px", width: "100%" }}
        mode="json"
        theme="textmate"
        onChange={onChange}
        value={convertionDetailValue}
      />
      {isChanged && (
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={saveToForm}
        >
          <>Commit Changes</>
        </Button>
      )}
    </div>
  );
};

export default SupplyItemCreate;
