// @ts-nocheck
import React, { useState, useEffect } from "react";
import {
  TextInput,
  SimpleForm,
  Edit,
  required,
  BooleanInput,
  useRecordContext,
  Button,
  useInput,
  Toolbar,
  SaveButton,
} from "react-admin";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";

import AceEditor from "react-ace";

const EditTitle = () => {
  const record = useRecordContext();
  return (
    <span data-test-id="edit-title">
      {record ? `"${record.email}"` : ""} Edit
    </span>
  );
};

const UserEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const RolesInput = (props) => {
  const [rolesValue, setRolesValue] = useState("");
  const [isChanged, setIsChanged] = useState(false);
  const roles = useInput(props);

  useEffect(() => {
    setRolesValue(JSON.stringify(roles.field.value, null, "\t"));
    setIsChanged(false);
  }, [roles.field.value]);

  function onChange(newValue) {
    setRolesValue(newValue);
    setIsChanged(true);
  }

  function saveToForm() {
    const parsed = JSON.parse(rolesValue);
    roles.field.onChange(parsed);
  }

  return (
    <>
      <AceEditor
        name="roles"
        style={{ height: "200px", width: "100%" }}
        mode="json"
        theme="textmate"
        onChange={onChange}
        value={rolesValue}
      />
      {isChanged && (
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={saveToForm}
        >
          <>Commit Changes</>
        </Button>
      )}
    </>
  );
};

const EditPage = (props) => {
  const classes = useStyles();

  return (
    <Edit title={<EditTitle />} {...props}>
      <SimpleForm toolbar={<UserEditToolbar />}>
        <div className={classes.root}>
          <div className={classes.form}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextInput
                  className={classes.input}
                  fullWidth
                  source="id"
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  className={classes.input}
                  fullWidth
                  source="email"
                  validate={[required()]}
                  type="email"
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  className={classes.input}
                  fullWidth
                  source="name"
                  validate={[required()]}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  className={classes.input}
                  fullWidth
                  source="status"
                />
              </Grid>
              <Grid item xs={6}>
                <BooleanInput
                  className={classes.input}
                  fullWidth
                  source="verified"
                />
              </Grid>
              <Grid item xs={6}>
                <BooleanInput
                  className={classes.input}
                  fullWidth
                  source="is_blocked"
                />
              </Grid>
              <Grid item xs={12}>
                <p className={classes.text}>Roles</p>
                <RolesInput fullWidth source="roles" />
              </Grid>
            </Grid>
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
  },
  form: {
    flexGrow: 1,
  },
  input: {
    marginBottom: "-20px ",
  },
  text: {
    color: "#0000008A",
  },
  gridResponsive: {},
}));

export default EditPage;
