import * as React from "react";
import {
  Edit,
  TextInput,
  SimpleForm,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  useRefresh,
  EditProps,
  useShowController,
  Tab,
  TabbedShowLayout,
  Confirm,
  Toolbar,
  SaveButton,
  FunctionField,
  FormDataConsumer,
  RaRecord,
  AutocompleteInput,
  required,
} from "react-admin";
import { Grid, Box, Typography, Button, Stack, TextField } from "@mui/material";
import JSONInput from "../../JSONInput";
import {
  Edit as EditIcon2,
  ArrowBackIosNew as CancelIcon,
} from "@mui/icons-material";
import ReactJson from "react-json-view";
import ProductRouting from "./ProductRouting";
import ProductPricing from "./ProductPricing";

import mbaas from "../../../provider/mbaas";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const EditPage = ({ ...props }: EditProps) => {
  const refresh = useRefresh();
  const navigate = useNavigate();

  const [isEdit, setIsEdit] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [otpCode, setOtpCode] = React.useState("");
  const [formData, setFormData] = React.useState({
    product_category_id: "",
    label: "",
    description: "",
    type: "",
    is_available: false,
    supply_mode: "",
    execution_rules: {},
    inquiry_rules: {},
    id: "",
    country: "",
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const handleDialogClose = () => setOpen(false);
  //
  const handleConfirm = async () => {
    setIsLoading(true);
    const { data, error } = await mbaas.client.invokeApi(
      "update-product-item",
      {
        dataProductItem: {
          product_category_id: formData.product_category_id,
          label: formData.label,
          description: formData.description,
          type: formData.type,
          is_available: formData.is_available,
          supply_mode: formData.supply_mode,
          execution_rules: formData.execution_rules,
          inquiry_rules: formData.inquiry_rules,
          country: formData.country,
        },
        productItemId: formData.id,
        token: otpCode,
      }
    );

    if (error) {
      Swal.fire(`Request failed: ${error.error}`, "", "error");
    }
    if (data) {
      Swal.fire("Success!", "", "success");
      navigate("/product_items");
      refresh();
    }
    setIsLoading(false);
    setOpen(false);
  };
  //

  const { record } = useShowController(props);

  console.log(77872, record);

  const CustomToolbar = () => (
    <Toolbar>
      <Stack direction="row" justifyContent="space-between" width="100%">
        {isEdit && <SaveButton label="Save" onClick={() => setOpen(true)} />}
        {isEdit && (
          <Button
            onClick={() => setIsEdit(false)}
            variant="contained"
            color="error"
            startIcon={<CancelIcon />}
          >
            Cancel
          </Button>
        )}
        {!isEdit && (
          <Button
            onClick={() => setIsEdit(true)}
            variant="contained"
            startIcon={<EditIcon2 />}
          >
            Edit
          </Button>
        )}
      </Stack>
    </Toolbar>
  );

  if (!record) return null;

  return (
    <Edit title={<></>} {...props}>
      <Confirm
        isOpen={open}
        loading={otpCode.length < 6 ? true : isLoading}
        title="OTP code"
        content={
          <TextField
            type="number"
            fullWidth
            data-test-id="input-otp"
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
          />
        }
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
      <Box width="50vW" ml={2} mr={2}>
        <TabbedShowLayout syncWithLocation={false}>
          <Tab label="Details" data-test-id="tab-details">
            <SimpleForm toolbar={<CustomToolbar />}>
              <Grid container spacing={2}>
                <Box>
                  <BooleanInput
                    fullWidth
                    source="is_available"
                    label="Status"
                    disabled={!isEdit}
                    data-test-id="status"
                  />
                  <TextInput
                    source="item_key"
                    label="Code"
                    fullWidth
                    disabled
                  />
                  <ReferenceInput
                    source="product_category_id"
                    reference="product_categories"
                    validate={[required()]}
                  >
                    <AutocompleteInput
                      source="label"
                      label="Category"
                      fullWidth
                      data-test-id="category"
                      optionText={(choice?: any) =>
                        choice?.id ? choice.label : ""
                      }
                      disabled={!isEdit}
                    />
                  </ReferenceInput>
                  <TextInput
                    source="label"
                    validate={[required()]}
                    fullWidth
                    data-test-id="label"
                    disabled={!isEdit}
                  />
                  <TextInput
                    source="description"
                    fullWidth
                    validate={[required()]}
                    disabled={!isEdit}
                  />
                  <SelectInput
                    source="type"
                    validate={[required()]}
                    fullWidth
                    choices={[
                      { id: "ABSOLUTE", name: "ABSOLUTE" },
                      { id: "BILL", name: "BILL" },
                    ]}
                    disabled={!isEdit}
                  />
                  <SelectInput
                    source="country"
                    validate={[required()]}
                    fullWidth
                    choices={[
                      { id: "ID", name: "ID (Indonesia)" },
                      { id: "HK", name: "HK (Hongkong)" },
                      { id: "TW", name: "TW (Taiwan)" },
                    ]}
                    disabled={!isEdit}
                  />
                  {isEdit && <JSONInput source="inquiry_rules" />}
                  {isEdit && <JSONInput source="execution_rules" />}
                  {!isEdit && (
                    <>
                      <Typography mb={1}>Inquiry rules</Typography>
                      <FunctionField
                        label="Inquiry rules"
                        render={(record: RaRecord) => (
                          <div>
                            <label>
                              <ReactJson
                                src={record.inquiry_rules || {}}
                                name={false}
                                enableClipboard={false}
                                displayDataTypes={false}
                                displayObjectSize={false}
                              />
                            </label>
                          </div>
                        )}
                      />
                    </>
                  )}
                  {!isEdit && (
                    <div style={{ marginBottom: 25 }}>
                      <Typography mb={1} mt={3}>
                        Execution rules
                      </Typography>
                      <FunctionField
                        label="Execution rules"
                        render={(record: RaRecord) => (
                          <div>
                            <label>
                              <ReactJson
                                src={record.execution_rules || {}}
                                name={false}
                                enableClipboard={false}
                                displayDataTypes={false}
                                displayObjectSize={false}
                              />
                            </label>
                          </div>
                        )}
                      />
                    </div>
                  )}
                  <SelectInput
                    source="supply_mode"
                    fullWidth
                    validate={[required()]}
                    choices={[
                      { id: "LCR", name: "Least Cost Routing (LCR)" },
                      { id: "LB", name: "Load Balancing (LB)" },
                    ]}
                    disabled={!isEdit}
                  />
                </Box>
                <FormDataConsumer>
                  {({ formData, ...rest }) => {
                    setFormData(formData);
                    return <></>;
                  }}
                </FormDataConsumer>
              </Grid>
            </SimpleForm>
          </Tab>
          <Tab label="Routing" data-test-id="tab-routing">
            <ProductRouting />
          </Tab>
          <Tab label="Pricing" data-test-id="tab-pricing">
            <ProductPricing />
          </Tab>
        </TabbedShowLayout>
      </Box>
    </Edit>
  );
};

export default EditPage;
