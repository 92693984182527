import React, { useState } from "react";
import {
  Toolbar,
  DeleteWithConfirmButton,
  useNotify,
  useRedirect,
  useDataProvider,
  Button,
} from "react-admin";
import { Save as SaveIcon } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useWatch } from "react-hook-form";

const SaveButton = ({ supplierId }: { supplierId: string }) => {
  const formData: any = useWatch();
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const [isLoading, setIsLoading] = useState(false);

  let startIcon = <SaveIcon />;
  if (isLoading) {
    startIcon = <CircularProgress size={20} />;
  }

  const saveData = async () => {
    setIsLoading(true);
    const supply_item_id = formData.id;
    delete formData.id;
    delete formData.type;
    delete formData.created_at;
    delete formData.updated_at;
    await dataProvider
      .update("supply_items", {
        id: supply_item_id,
        data: {
          ...formData,
        },
        previousData: {},
      })
      .then(() => {
        redirect(`/suppliers/${supplierId}/supply_items_upserted`);
        notify("Item edited");
      })
      .catch((error) => {
        notify(`supply_items:Edit error: ${error.message}`, {
          type: "warning",
        });
      });
    setIsLoading(false);
  };

  const { label, description, origin_currency, origin_cost, hkd_cost } =
    formData;

  return (
    <Button
      startIcon={startIcon}
      label="Save"
      variant="contained"
      disabled={
        label === "" ||
        description === "" ||
        origin_currency === "" ||
        !origin_cost ||
        !hkd_cost
      }
      onClick={() => {
        saveData();
      }}
    />
  );
};

export const ToolbarEdit = ({ supplierId }: { supplierId: string }) => {
  // console.log(3333, props)
  return (
    <Toolbar>
      <SaveButton supplierId={supplierId} />
      {/* <DeleteWithConfirmButton
        redirect={`/suppliers/${supplierId}`}
        sx={{ marginLeft: "400px" }}
        data-test-id="button-delete"
      /> */}
    </Toolbar>
  );
};
