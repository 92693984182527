import * as React from "react";
import { Confirm, useRecordContext } from "react-admin";
//
import MaterialTable, { Column } from "@material-table/core";
import mbaas from "../../../provider/mbaas";
import gql from "graphql-tag";
import { Box, Autocomplete, TextField, MenuItem, Select } from "@mui/material";
import Swal from "sweetalert2";

import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";

interface ISuppliedItem {
  id: string;
  supplier_id: string;
  supply_route_id: string;
  product_item_id: string;
  label: string;
  description: string;
  origin_currency: string;
  origin_cost: number;
  hkd_cost: number;
  is_available: boolean;
  supplier_name: string;
  supplier_id_data: { id: string; name: string };
}

const RoutingShow = () => {
  const record = useRecordContext();
  const mapData = (rawData: any) => {
    return rawData.map((x: any) =>
      Object.assign({
        product_item_id: record.id,
        ...x.supply_item_id_data,
        supply_route_id: x.id,
        supplier_name: x.supply_item_id_data.supplier_id_data.name,
        supplier_id: x.supply_item_id_data.supplier_id_data.id,
      })
    );
  };
  const originData = mapData(record.supply_routes_product_item_id_list.data);
  const [isTableLoading, setIsTableLoading] = React.useState(false);
  const [tableData, setTableData] = React.useState(originData);
  //
  const [open, setOpen] = React.useState(false);
  const [otpCode, setOtpCode] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [submitConf, setSubmitConf] = React.useState({ method: "", data: {} });
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async () => {
    setIsLoading(true);
    const { error, data } = await mbaas.client.invokeApi(submitConf.method, {
      ...submitConf.data,
      token: otpCode,
    });
    if (error) {
      Swal.fire(`Request failed: ${error.error}`, "", "error");
    }
    if (data) {
      Swal.fire("Success!", "", "success");
      initData();
    }
    setIsLoading(false);
    setOpen(false);
  };
  //
  const supplierOpts: any = {};
  record.supplier_list.forEach((x: { id: string; name: string }) => {
    supplierOpts[x.id] = x.name;
  });
  //
  const columns: Array<Column<ISuppliedItem>> = [
    {
      field: "product_item_id",
      type: "string",
      editable: "never",
      hidden: true,
    },
    {
      field: "supply_route_id",
      type: "string",
      editable: "never",
      hidden: true,
    },
    {
      title: "Supplier",
      field: "supplier_id",
      render: (rowData) => rowData.supplier_name,
      editComponent(props) {
        return (
          <Select
            id="supplier"
            value={props.value || ""}
            label="Supplier"
            onChange={(e) => props.onChange(e.target.value)}
          >
            {record.supplier_list.map((x: any) => (
              <MenuItem key={x.id} value={x.id}>
                {x.name}
              </MenuItem>
            ))}
          </Select>
        );
      },
    },
    {
      title: "Label",
      field: "label",
      render: (rowData) => rowData.label,
      editComponent: (props) => {
        return (
          <Autocomplete
            disablePortal
            defaultChecked={false}
            value={
              props.rowData?.supplier_id === props.rowData?.supplier_id_data?.id
                ? props.value
                : null
            }
            id="product-label"
            sx={{ width: 300 }}
            getOptionLabel={(option) =>
              option.label || props.rowData.label || ""
            }
            options={record.supplied_items}
            filterOptions={(options, state) =>
              options.filter(
                (x: ISuppliedItem) =>
                  x.supplier_id === props.rowData.supplier_id &&
                  x.label.toLowerCase().includes(state.inputValue.toLowerCase())
              )
            }
            onChange={(_e, v) => props.onChange(v)}
            renderInput={(params) => <TextField {...params} label="product" />}
          />
        );
      },
    },
    { title: "Currency", field: "origin_currency", editable: "never" },
    {
      title: "Origin Cost",
      field: "origin_cost",
      type: "numeric",
      editable: "never",
    },
    {
      title: "HKD Cost",
      field: "hkd_cost",
      type: "numeric",
      editable: "never",
    },
    {
      title: "Status",
      field: "is_available",
      type: "boolean",
      editable: "never",
      render: (rowData) =>
        rowData.is_available ? <CheckIcon /> : <ClearIcon />,
    },
  ];
  //
  const initData = async () => {
    setIsTableLoading(true);
    const { getProductItemsById } = await mbaas.client.gql.query({
      query: gql`
        query ProductItem($id: UUID!) {
          getProductItemsById(id: $id) {
            id
            item_key
            label
            description
            product_category_id
            type
            supply_mode
            supply_routes_product_item_id_list {
              data {
                id
                supply_item_id_data {
                  id
                  label
                  origin_currency
                  origin_cost
                  hkd_cost
                  is_available
                  supplier_id_data {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        id: record.id,
      },
    });
    //
    const mappedData = mapData(
      getProductItemsById.supply_routes_product_item_id_list.data
    );
    setTableData(mappedData);
    setIsTableLoading(false);
  };
  //

  if (!record) return null;
  return (
    <Box m={0} ml={-2} mr={-2}>
      <Confirm
        isOpen={open}
        loading={otpCode.length < 6 ? true : isLoading}
        title="OTP code"
        content={
          <TextField
            type="number"
            data-test-id="input-otp"
            fullWidth
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
          />
        }
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
      <MaterialTable
        columns={columns}
        isLoading={isTableLoading}
        options={{
          search: false,
        }}
        data={tableData}
        editable={{
          onRowAdd: async (newData) => {
            if (Object.keys(newData).length === 0) {
              Swal.fire("There is no data to be added!", "", "error");
            } else {
              setOtpCode("");
              setSubmitConf({
                method: "define-supply-route",
                data: {
                  entry: {
                    product_item_id: record.id,
                    // @ts-ignore
                    supply_item_id: newData.label.id,
                    weight: 1,
                  },
                },
              });
              setOpen(true);
            }
          },
          onRowUpdate: async (newData, oldData) => {
            console.log({
              oldData,
              newData,
            });
            if (typeof newData.label === "string") {
              Swal.fire("There is no data to be updated!", "", "error");
            } else {
              setOtpCode("");
              setSubmitConf({
                method: "update-supply-route",
                data: {
                  entry: {
                    product_item_id: newData.product_item_id,
                    // @ts-ignore
                    supply_item_id: newData.label.id,
                    weight: 1,
                  },
                  supply_route_id: newData.supply_route_id,
                },
              });
              setOpen(true);
            }
          },
          onRowDelete: async (oldData) => {
            console.log({ oldData });
            setOtpCode("");
            setSubmitConf({
              method: "delete-supply-route",
              data: {
                supply_route_id: oldData.supply_route_id,
                product_item_id: oldData.product_item_id,
              },
            });
            setOpen(true);
          },
        }}
        title={`${record.label}`}
      />
    </Box>
  );
};

export default RoutingShow;
