import * as React from "react";
import {
  Edit,
  TextInput,
  SimpleForm,
  SelectInput,
  EditProps,
  useShowController,
  Toolbar,
  SaveButton,
  NumberInput,
  required,
} from "react-admin";
import { Grid, Box, Button, Stack, Typography } from "@mui/material";
import {
  Edit as EditIcon2,
  ArrowBackIosNew as CancelIcon,
} from "@mui/icons-material";

const EditPage = ({ ...props }: EditProps) => {
  const [isEdit, setIsEdit] = React.useState(false);

  const { record } = useShowController(props);

  const CustomToolbar = () => (
    <Toolbar>
      <Stack direction="row" justifyContent="space-between" width="100%">
        {isEdit && <SaveButton label="Save" />}
        {isEdit && (
          <Button
            onClick={() => setIsEdit(false)}
            variant="contained"
            color="error"
            startIcon={<CancelIcon />}
          >
            Cancel
          </Button>
        )}
        {!isEdit && (
          <Button
            onClick={() => setIsEdit(true)}
            variant="contained"
            startIcon={<EditIcon2 />}
          >
            Edit
          </Button>
        )}
      </Stack>
    </Toolbar>
  );

  if (!record) return null;

  return (
    <Edit title={<></>} {...props} mutationMode="pessimistic">
      <Box width="50vW" ml={2} mr={2}>
        <Stack direction="row" pr={1}>
          <Typography variant="h6" flex="1">
            Edit Convertion Rate
          </Typography>
        </Stack>
        <SimpleForm toolbar={<CustomToolbar />}>
          <Grid container spacing={2}>
            <Box flex={2}>
              <div style={{ display: "none" }}>
                <TextInput
                  source="author"
                  // @ts-ignore
                  value={props.userData?.id}
                  // @ts-ignore
                  defaultValue={props.userData?.id}
                  validate={[required()]}
                  data-test-id="key"
                  fullWidth
                  disabled={true}
                />
              </div>

              <SelectInput
                source="origin"
                data-test-id="origin"
                validate={[required()]}
                fullWidth
                disabled={!isEdit}
                choices={[
                  { id: "HKD", name: "HKD" },
                  { id: "IDR", name: "IDR" },
                  { id: "TWD", name: "TWD" },
                  { id: "MYR", name: "MYR" },
                  { id: "USD", name: "USD" },
                ]}
              />
              <SelectInput
                source="destination"
                data-test-id="destination"
                validate={[required()]}
                fullWidth
                disabled={!isEdit}
                choices={[
                  { id: "HKD", name: "HKD" },
                  { id: "IDR", name: "IDR" },
                  { id: "TWD", name: "TWD" },
                  { id: "MYR", name: "MYR" },
                  { id: "USD", name: "USD" },
                ]}
              />
              <NumberInput
                source="rate"
                validate={[required()]}
                disabled={!isEdit}
                data-test-id="rate"
                fullWidth
              />
              {isEdit && (
                <TextInput
                  source="totp"
                  validate={[required()]}
                  data-test-id="totp"
                  fullWidth
                />
              )}
            </Box>
          </Grid>
        </SimpleForm>
      </Box>
    </Edit>
  );
};

export default EditPage;
