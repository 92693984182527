import * as React from "react";
import {
  TextInput,
  SimpleForm,
  useRefresh,
  required,
  usePermissions,
  FunctionField,
  Confirm,
} from "react-admin";
import {
  Grid,
  Box,
  Typography,
  TableHead,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  TextField,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import GenerateKeysButton from "./GenerateKeys";
import AddUserDialog from "./AddUserDialog";
import mbaas from "../../provider/mbaas";
import Swal from "sweetalert2";

const EditPage = ({ account_id }: { account_id: string }) => {
  const refresh = useRefresh();
  const { isLoading, permissions } = usePermissions();
  const [open, setOpen] = React.useState(false);
  const [cOpen, setCOpen] = React.useState(false);
  const [otpCode, setOtpCode] = React.useState("");
  const [userId, setUserId] = React.useState("");

  const validatePrefix = (value: any) => {
    if (!value) {
      return "prefix is required";
    }
    if (value.length > 3) {
      return "prefix must have 3 characters";
    }
    if (value.length < 3) {
      return "prefix must have 3 characters";
    }
  };

  const prefixValidation: any = [required(), validatePrefix];

  //
  const handleClickOpen = () => {
    setOtpCode("");
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleAdd = async () => {
    const { data, error } = await mbaas.client.invokeApi("ud-user-account", {
      user_id: userId,
      account_id,
      token: otpCode,
      method: "update",
    });

    if (error) {
      Swal.fire(`Request failed: ${error.error}`, "", "error");
    }
    if (data) {
      Swal.fire("Success!", "", "success");
      refresh();
    }
    setOpen(false);
  };

  const handleDelete = async () => {
    setCOpen(false);
    const { data, error } = await mbaas.client.invokeApi("ud-user-account", {
      user_id: userId,
      account_id,
      token: otpCode,
      method: "delete",
    });

    if (error) {
      Swal.fire(`Request failed: ${error.error}`, "", "error");
    }
    if (data) {
      Swal.fire("Success!", "", "success");
      refresh();
    }
  };
  //

  return (
    <>
      <Confirm
        isOpen={cOpen}
        title="OTP 2FA"
        content={
          <TextField
            type="number"
            fullWidth
            data-test-id="input-otp"
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
          />
        }
        onConfirm={handleDelete}
        onClose={() => setCOpen(false)}
      />
      <Box
        width="100%"
        display="block"
        m={0}
        justifyContent="center"
        alignItems="center"
      >
        <SimpleForm>
          <Grid container spacing={2}>
            <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
              <TextInput
                data-test-id="input-label"
                source="label"
                isRequired
                fullWidth
              />
              <TextInput
                data-test-id="input-contact"
                source="contact"
                isRequired
                fullWidth
              />
              <TextInput
                data-test-id="input-transaction-prefix"
                source="transaction_prefix"
                isRequired
                fullWidth
                onChange={(e) => validatePrefix(e.target.value)}
                validate={prefixValidation}
              />
            </Box>
          </Grid>
          {!isLoading && ["Administrator", "ext_admin"].includes(permissions) && (
            <>
              <Grid container spacing={2} marginTop={1}>
                <Box
                  width="100%"
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Box mr={2}>
                    <Typography variant="h5">Associated Users</Typography>
                  </Box>
                  <Box mr={1}>
                    <AddUserDialog
                      setUserId={setUserId}
                      open={open}
                      otpCode={otpCode}
                      setOtpCode={setOtpCode}
                      handleClickOpen={handleClickOpen}
                      handleClose={handleClose}
                      handleAdd={handleAdd}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid container spacing={2} marginTop={1}>
                <Box flex={2} mt={2} mr={{ xs: 0, sm: "0.5em" }}>
                  <FunctionField
                    label="Name"
                    render={(record: any) => (
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 650 }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align="left"
                                sx={{ fontWeight: "bold" }}
                              >
                                E-mail
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ fontWeight: "bold" }}
                              >
                                Name
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ fontWeight: "bold" }}
                              >
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {record?.users_account_id_list?.data.map(
                              (row: {
                                name: string;
                                email: string;
                                id: string;
                              }) => (
                                <TableRow
                                  key={row.email}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {row.email}
                                  </TableCell>
                                  <TableCell align="left">{row.name}</TableCell>
                                  <TableCell align="center">
                                    <Button
                                      variant="contained"
                                      size="small"
                                      color="error"
                                      startIcon={<DeleteIcon />}
                                      onClick={() => {
                                        setUserId(row.id);
                                        setOtpCode("");
                                        setCOpen(true);
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  />
                </Box>
              </Grid>
            </>
          )}
          <Grid container spacing={2} marginTop={3}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box mr={2}>
                <Typography variant="h5">Credential</Typography>
              </Box>
              {!isLoading && ["ext_admin"].includes(permissions) && (
                <Box mr={2}>
                  <GenerateKeysButton refresh={refresh} />
                </Box>
              )}
            </Box>
          </Grid>
          <Grid container spacing={2} marginTop={2}>
            <Box flex={4} mr={{ xs: 0, sm: "0.5em" }}>
              <TextInput
                source="private_key_signature"
                label="Private key Signature"
                disabled
                multiline
                fullWidth
              />
              <TextInput source="public_key" disabled multiline fullWidth />
            </Box>
          </Grid>
        </SimpleForm>
      </Box>
    </>
  );
};

export default EditPage;
