// @ts-nocheck
import { Drawer, Stack, Paper, Typography } from "@mui/material";
import * as React from "react";
import {
  Datagrid,
  TextField,
  CreateButton,
  BooleanField,
  ReferenceField,
  ReferenceInput,
  useListContext,
  TextInput,
  List,
  Confirm,
  useCreate,
  FilterForm,
  AutocompleteInput,
  usePermissions,
  Button,
  useNotify,
} from "react-admin";
//
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import GetAppIcon from "@mui/icons-material/GetApp";
import CreatePage from "./CreatePage";
import EditPage from "./EditPage";
import { format, endOfToday } from "date-fns";
import { Empty } from "../../EmptyPage";
import UpdateIcon from "@mui/icons-material/Update";
//
import mbaas from "../../../provider/mbaas";
//
import UploadDialog from "./UploadDialog";
//
const ListPage = () => {
  const notify = useNotify();
  const [userData, setUserData] = React.useState({});
  const { isLoading, permissions } = usePermissions();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState({
    isOpen: false,
    dialogType: "",
  });

  const getUserData = async () => {
    const res = await mbaas.client.user.get();

    if (res.data) {
      setUserData(res.data);
    }
    if (res.error) {
      // @ts-ignore
      notify(res.error);
    }
  };
  React.useEffect(() => {
    getUserData();
  }, []);

  const Filters = [
    <ReferenceInput
      source="product_category_id"
      reference="product_categories"
      alwaysOn
    >
      <AutocompleteInput
        label="Category"
        source="label"
        data-test-id="search-by-category"
        optionText={(choice?: any) => (choice?.id ? choice.label : "")}
      />
    </ReferenceInput>,
    <TextInput
      source="label"
      label="Product"
      alwaysOn
      data-test-id="search-by-label"
    />,
  ];

  const ProductItemsExportButton = () => {
    const { displayedFilters, filterValues, showFilter } = useListContext();

    // const endOfToday = format(new Date(endOfToday()), "yyyy-MM-dd");
    const type_name = "product_items";
    const gen_file_name = `${type_name}_${format(
      new Date(endOfToday()),
      "yyyy-MM-dd"
    )}`;

    const [exportData, { data, isLoading, error }] = useCreate("reports", {
      data: {
        type: type_name,
        author_id: userData?.id,
        metadata: {
          // date awal mulai project (juni)
          begin: "2022-06-01",
          // nanti diganti dengan tanggal hari ini ditambah 1. atau endOfToday
          end: format(new Date(endOfToday()), "yyyy-MM-dd"),
          generated_filename: gen_file_name,
          product_category_id: filterValues.product_category_id,
          label: filterValues.label,
        },
      },
    });

    if (data && !error) {
      //
      navigate(encodeURI(`/reports?filter={"type":"product_items"}`));
    }

    const handleOnClick = () => {
      exportData();
      setOpen(true);
    };

    const navigateToReportPage = () => {
      navigate(encodeURI(`/reports?filter={"type":"product_items"}`));
    };
    const ConfirmDialogContent = () => {
      return (
        <Paper elevation={0}>
          <Typography variant="body1" gutterBottom>
            Your file is exported as "{gen_file_name}.csv", to view the progress
            please visit report page
          </Typography>
        </Paper>
      );
    };

    return (
      <>
        <Button
          label="Export Product Items"
          onClick={() => handleOnClick()}
          color="secondary"
        >
          <GetAppIcon />
        </Button>
        <Confirm
          isOpen={open}
          title="Dear User,"
          content={<ConfirmDialogContent />}
          onClose={() => setOpen(false)}
          confirm="Go To Report"
          onConfirm={navigateToReportPage}
        />
      </>
    );
  };

  const UpdateProductCsv = () => {
    const handleClickOpenDialog = (dialogType: string) => {
      setOpenDialog({ isOpen: true, dialogType: dialogType });
    };
    const handleCloseDialog = (dialogType: string) => {
      setOpenDialog({ isOpen: false, dialogType: dialogType });
    };

    return (
      <>
        <Button
          color="secondary"
          label="Update Product"
          onClick={() => handleClickOpenDialog("product")}
        >
          <UpdateIcon />
        </Button>
        <Button
          color="secondary"
          label="Update Pricing"
          onClick={() => handleClickOpenDialog("pricing")}
        >
          <UpdateIcon />
        </Button>

        <UploadDialog
          openDialog={openDialog.isOpen}
          dialogType={openDialog.dialogType}
          handleClickOpen={handleClickOpenDialog}
          handleClose={handleCloseDialog}
        />
      </>
    );
  };

  const ListToolbar = () => (
    <Stack direction="row" justifyContent="space-between" width="100%">
      <FilterForm filters={Filters} />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          maxHeight: "48px",
          marginTop: "8px",
        }}
      >
        <UpdateProductCsv />
        <ProductItemsExportButton />
        <CreateButton data-test-id="create-btn" />
      </div>
    </Stack>
  );
  //
  const handleClose = React.useCallback(() => {
    console.log({ cancel: navigate });
    navigate("/product_items");
  }, [navigate]);
  //
  const matchEdit = matchPath("/product_items/:id", location.pathname);
  const matchCreate = matchPath("/product_items/create", location.pathname);
  //
  return (
    <React.Fragment>
      <List
        perPage={25}
        sort={{ field: "product_category_id", order: "ASC" }}
        actions={<ListToolbar />}
        empty={<Empty />}
      >
        <Datagrid
          bulkActionButtons={false}
          rowClick={() =>
            !isLoading && ["Administrator", "staff"].includes(permissions)
              ? "edit"
              : ""
          }
        >
          <TextField source="item_key" label="Code" />
          <TextField source="label" label="Product" />
          <TextField source="description" />
          <ReferenceField
            source="product_category_id"
            reference="product_categories"
            label="Category"
          >
            <TextField source="label" />
          </ReferenceField>
          <TextField source="type" />
          {!isLoading && ["Administrator", "staff"].includes(permissions) && (
            <TextField source="supply_mode" />
          )}
          <TextField source="country" />
          <BooleanField source="is_available" label="Status" />
        </Datagrid>
      </List>
      <Drawer
        open={
          !!matchCreate || (!!matchEdit && matchEdit?.params?.id !== "create")
        }
        anchor="right"
        onClose={handleClose}
        sx={{ zIndex: 100 }}
      >
        {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
        {!!matchEdit && matchEdit?.params?.id !== "create" && (
          <EditPage id={(matchEdit as any).params?.id} />
        )}
        {!!matchCreate && <CreatePage />}
      </Drawer>
    </React.Fragment>
  );
};

export default ListPage;
