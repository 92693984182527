// @ts-nocheck
import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import {
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectInput,
  required,
  FormDataConsumer,
  AutocompleteArrayInput,
  ReferenceInput,
} from "react-admin";

import { CustomToolbar } from "./CustomToolbar";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  borderRadius: "7px",
  boxShadow: 24,
  p: 4,
};

export default function ResetPasswordModal({ open, handleClose, modalType }) {
  const classes = useStyles();
  const [dataForm, setDataForm] = React.useState([]);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography variant="h6" gutterBottom component="div">
              Input Email to Send Reset Password Url
            </Typography>
            <SimpleForm
              toolbar={
                <CustomToolbar modalType={modalType} dataForm={dataForm} />
              }
            >
              <div className={classes.root}>
                <div className={classes.form}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextInput
                        className={classes.input}
                        fullWidth
                        source="email"
                        type="email"
                        //   validate={validateEmail}
                      />
                    </Grid>

                    <FormDataConsumer style={{ width: "100%" }}>
                      {({ formData, ...rest }) => {
                        console.log(9997, "formData", formData);
                        setDataForm(formData);
                      }}
                    </FormDataConsumer>
                  </Grid>
                </div>
              </div>
            </SimpleForm>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
  },
  form: {
    flexGrow: 1,
  },
  input: {
    marginBottom: "-20px ",
  },
  text: {
    color: "#0000008A",
  },
}));
