import * as React from "react";
import {
  Edit,
  TextInput,
  TabbedForm,
  FormTab,
  required,
  ReferenceInput,
  usePermissions,
  BooleanInput,
  useRecordContext,
  SelectInput,
  SimpleForm,
} from "react-admin";
import { Grid, Paper } from "@mui/material";
import SupplyItemList from "./SupplyItems/SupplyItemList";
import { ToolbarEdit } from "./ToolbarEdit";

const Title = () => {
  const record = useRecordContext();
  // console.log(737, "record", record);
  return (
    <span data-test-id="edit-title">Edit {record ? record.name : ""} </span>
  );
};

const WrapToolbarEdit = () => {
  const record = useRecordContext();
  return <ToolbarEdit supplierId={record.id as string} />;
};

const EditPage = (props: any) => {
  const { isLoading, permissions } = usePermissions();

  console.log(999, "permissions", permissions);

  return (
    <Edit title={<Title />} mutationMode="pessimistic">
      <TabbedForm syncWithLocation={false} toolbar={false}>
        <FormTab label="Supplier Data">
          <Paper
            style={{
              width: "100%",
            }}
          >
            <SimpleForm toolbar={<WrapToolbarEdit />}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <ReferenceInput
                    source="user_id"
                    reference="users"
                    label="User"
                  >
                    <SelectInput
                      disabled={
                        !isLoading && ["staff"].includes(permissions)
                          ? true
                          : false
                      }
                      data-test-id="select-user"
                      source="name"
                      label="User"
                      fullWidth
                    />
                  </ReferenceInput>
                </Grid>

                <Grid item xs={6}>
                  <TextInput
                    disabled={
                      !isLoading && ["staff"].includes(permissions)
                        ? true
                        : false
                    }
                    data-test-id="input-name"
                    fullWidth
                    label="Name"
                    source="name"
                    validate={[required()]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    disabled={
                      !isLoading && ["staff"].includes(permissions)
                        ? true
                        : false
                    }
                    data-test-id="input-contact"
                    fullWidth
                    label="Contact"
                    source="contact"
                    validate={[required()]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    disabled={
                      !isLoading && ["staff"].includes(permissions)
                        ? true
                        : false
                    }
                    data-test-id="input-desc"
                    fullWidth
                    label="Description"
                    source="description"
                    validate={[required()]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <BooleanInput
                    disabled={
                      !isLoading && ["staff"].includes(permissions)
                        ? true
                        : false
                    }
                    fullWidth
                    source="is_available"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    disabled={
                      !isLoading && ["staff"].includes(permissions)
                        ? true
                        : false
                    }
                    data-test-id="input-totp"
                    fullWidth
                    label="Totp"
                    source="totp"
                    validate={[required()]}
                  />
                </Grid>
              </Grid>
            </SimpleForm>
          </Paper>
        </FormTab>
        <FormTab label="Supply Items List">
          <SupplyItemList />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default EditPage;
