// @ts-nocheck
import React, { useCallback, useState, useEffect } from "react";
import {
  List,
  TextField,
  Datagrid,
  BooleanField,
  useRecordContext,
  Pagination,
  TopToolbar,
  Button,
  CreateButton,
  useListContext,
  useCreate,
  useRefresh,
  SearchInput,
  FilterButton,
  Confirm,
} from "react-admin";
import SupplyItemCreate from "./SupplyItemCreate";
import SupplyItemEdit from "./SupplyItemEdit";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { endOfToday, format } from "date-fns";
import { Box, Drawer, Paper, Typography } from "@mui/material";
//
import GetAppIcon from "@mui/icons-material/GetApp";
import mbaas from "../../../provider/mbaas";
//

const SupplyItemListActions = () => {
  const currentSupplier = useRecordContext();
  const navigate = useNavigate();
  const [userData, setUserData] = React.useState({});
  const [open, setOpen] = React.useState(false);

  const getUserData = async () => {
    const res = await mbaas.client.user.get();
    if (res.data) {
      setUserData(res.data);
    }
    if (res.error) {
      // @ts-ignore
      notify(res.error);
    }
  };

  React.useEffect(() => {
    getUserData();
  }, []);

  const ProductItemsExportButton = () => {
    const { filterValues } = useListContext();
    //
    const type_name = "supply_items";
    const gen_file_name = `${type_name}_${format(
      new Date(endOfToday()),
      "yyyy-MM-dd"
    )}`;
    //
    const [exportData, { data, isLoading, error }] = useCreate("reports", {
      data: {
        type: type_name,
        author_id: userData?.id,
        metadata: {
          begin: "2022-06-02",
          end: format(new Date(endOfToday()), "yyyy-MM-dd"),
          generated_filename: gen_file_name,
          label: filterValues.label,
          supplier_id: currentSupplier.id,
        },
      },
    });

    if (data && !error) {
      //
      console.log(767, "haiii");
    }

    const handleOnClick = () => {
      exportData();
      setOpen(true);
    };

    const navigateToReportPage = () => {
      navigate(encodeURI(`/reports?filter={"type":"supply_items"}`));
    };
    const ConfirmDialogContent = () => {
      return (
        <Paper elevation={0}>
          <Typography variant="body1" gutterBottom>
            Your file is exported as "{gen_file_name}.csv", to view the progress
            please visit report page below
          </Typography>
        </Paper>
      );
    };

    return (
      <Button label="Export Suppliers Data" onClick={() => handleOnClick()}>
        <GetAppIcon />
        <Confirm
          isOpen={open}
          title="Dear User,"
          content={<ConfirmDialogContent />}
          onClose={() => setOpen(false)}
          confirm="Go To Report"
          onConfirm={navigateToReportPage}
        />
      </Button>
    );
  };

  return (
    <TopToolbar>
      <FilterButton filters={SupplyItemFilter} />
      <CreateButton
        label="Add New Item"
        onClick={(event) => {
          event.preventDefault();
          navigate(`/suppliers/${currentSupplier.id}/supply_items_create`);
        }}
      />
      <ProductItemsExportButton />
    </TopToolbar>
  );
};

const SupplyItemFilter = [
  <SearchInput source="label" data-test-id="filter-label" />,
];

const SupplyItemPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
);

const SupplyItemList = () => {
  const [currentSupplier, setCurrentSupplier] = useState("");
  const [isUpserted, setIsUpserted] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const refresh = useRefresh();

  useEffect(() => {
    setCurrentSupplier(location.pathname.split("/")[2]);
    if (location.pathname.split("/")[3] === "supply_items_upserted")
      setIsUpserted(true);
  }, [location.pathname]);

  const handleCancelDrawer = useCallback(() => {
    navigate(`/suppliers/${currentSupplier}`);
  }, [navigate, currentSupplier]);

  const matchForCreateDrawer = matchPath(
    "/suppliers/:id/supply_items_create",
    location.pathname
  );

  const matchForEditDrawer = matchPath(
    "/suppliers/:id/supply_items/:id",
    location.pathname
  );

  if (isUpserted) {
    refresh();
    setIsUpserted(false);
  }

  return (
    <Box display="flex" sx={{ minWidth: "100%" }}>
      <List
        resource="supply_items"
        filter={{ supplier_id: currentSupplier }}
        filters={SupplyItemFilter}
        pagination={<SupplyItemPagination />}
        perPage={50}
        sort={{ field: "label", order: "ASC" }}
        actions={<SupplyItemListActions />}
        empty={false}
        sx={{
          flexGrow: 1,
          transition: (theme: any) =>
            theme.transitions.create(["all"], {
              duration: theme.transitions.duration.enteringScreen,
            }),
          marginRight:
            !!matchForCreateDrawer || !!matchForEditDrawer ? "600px" : 0,
        }}
      >
        <Datagrid
          cellSpacing={10}
          rowClick={(id) => {
            navigate(`/suppliers/${currentSupplier}/supply_items/${id}`);
          }}
          bulkActionButtons={false}
        >
          <TextField data-test-id="field-label" source="label" fullWidth />
          <TextField data-test-id="field-desc" source="description" fullWidth />
          <TextField
            data-test-id="field-org-curr"
            source="origin_currency"
            fullWidth
          />
          <TextField
            data-test-id="field-org-cost"
            source="origin_cost"
            fullWidth
          />
          <TextField
            data-test-id="field-hkd-cost"
            source="hkd_cost"
            fullWidth
          />
          <BooleanField source="is_available" label="Available" />
        </Datagrid>
      </List>
      <Drawer
        variant="persistent"
        open={!!matchForCreateDrawer}
        anchor="right"
        onClose={handleCancelDrawer}
        sx={{ zIndex: 100 }}
      >
        {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
        {!!matchForCreateDrawer && (
          <SupplyItemCreate
            supplierId={currentSupplier}
            onCancel={handleCancelDrawer}
          />
        )}
      </Drawer>
      <Drawer
        variant="persistent"
        open={!!matchForEditDrawer}
        anchor="right"
        onClose={handleCancelDrawer}
        sx={{ zIndex: 100 }}
      >
        {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
        {!!matchForEditDrawer && (
          <SupplyItemEdit
            id={(matchForEditDrawer as any).params.id}
            supplierId={currentSupplier}
            onCancel={handleCancelDrawer}
          />
        )}
      </Drawer>
    </Box>
  );
};

export default SupplyItemList;
