import { GetManyParams, GetManyResult } from "react-admin";
import gql from "graphql-tag";
import mbaas from "../../provider/mbaas";
import { composeFilter } from "@mocobaas/client-js";

const GetManyFunction = async (resource: string, params: GetManyParams) => {
  console.log("getMany", { resource, params });
  const initTable = mbaas.client.getTable(resource);
  if (resource === "users") {
    const table = await initTable.read();
    const result = await initTable.limit(table.total).read();
    return { data: result.data };
  }

  if (["users_without_account", "users_ext_admin"].includes(resource)) {
    const { allUsersList } = await mbaas.client.gql.query({
      query: gql`
        query Users($ids: [UUID]) {
          allUsersList(where: { id: { _in: $ids } }) {
            data {
              id
              name
              email
            }
          }
        }
      `,
      variables: {
        ids: params.ids,
      },
    });
    return { data: allUsersList.data } as GetManyResult;
  }
  const query = initTable
    .filter(composeFilter().in("id", params.ids).toString())
    .limit(25);
  const { data } = await query.read();

  console.log("getMany", { data });

  return { data: data } as GetManyResult;
};

export default GetManyFunction;
